<template>
  <pinch-zoom
    backgroundColor="white"
    autoHeight="true"
    id="pinch"
    >
	<svg  version="1.1" 
		id="UG" 
        width="1384.85"
        height="612.651"
		viewBox="0 0 1649.164 689.891" 
		overflow="visible" 
		enable-background="new 0 0 1649.164 689.891" 
		xml:space="preserve"
	>
		<!-- width="1384.85"
		height="689.891" -->
	<rect x="1022.347" y="404.684" fill="#7D797B" width="13.604" height="71.719"/>
	<rect x="1498.715" y="365" fill="#C0C8D3" width="34.016" height="22.676"/>
	<rect x="1453.361" y="365" fill="#C0C8D3" width="34.016" height="22.676"/>
	<rect x="1022.347" y="538.764" fill="#7D797B" width="13.604" height="93.088"/>
	<rect x="1364.217" y="340.055" fill="#7D797B" width="260.606" height="13.606"/>
	<polygon fill="#C0C8D3" points="1534.285,596.42 1534.285,616.262 1549.5,616.526 1554.92,616.526 1557.359,616.385 1560.287,616 
		1561.519,615.801 1562.181,615.555 1562.638,615.33 1563.006,615.084 1563.342,614.793 1563.631,614.465 1563.881,614.09 
		1564.109,613.639 1564.31,613.084 1564.486,612.418 1564.625,611.6 1564.742,610.385 1564.886,608.233 1564.904,607.127 
		1564.896,606.719 1564.896,605.963 1564.904,605.555 1564.886,604.448 1564.742,602.295 1564.625,601.08 1564.486,600.264 
		1564.31,599.596 1564.109,599.041 1563.881,598.59 1563.631,598.217 1563.342,597.889 1563.006,597.596 1562.638,597.352 
		1562.181,597.127 1561.519,596.879 1560.287,596.682 1557.359,596.295 1554.92,596.155 1549.5,596.155 "/>
	<polygon fill="#C0C8D3" points="1466.82,498.623 1466.73,498.631 1466.644,498.651 1466.562,498.686 1466.488,498.733 
		1466.42,498.789 1466.363,521.535 1466.314,521.61 1466.281,521.694 1466.26,521.78 1466.254,521.867 1466.254,543.41 
		1466.26,543.5 1466.281,543.586 1466.314,543.668 1466.363,543.744 1466.42,543.813 1466.488,543.869 1466.562,543.916 
		1466.644,543.949 1466.73,543.971 1466.82,543.979 1567.734,543.979 1567.822,543.971 1567.91,543.949 1567.992,543.916 
		1568.068,543.869 1568.135,543.813 1568.193,543.744 1568.24,543.668 1568.271,543.586 1568.293,543.5 1568.301,543.41 
		1568.301,499.192 1568.293,499.102 1568.271,499.016 1568.24,498.934 1568.193,498.858 1568.135,498.789 1568.068,498.733 
		1567.992,498.686 1567.91,498.651 1567.822,498.631 1567.734,498.623 "/>
	<rect x="1466.254" y="555.317" fill="#C0C8D3" width="56.69" height="79.369"/>
	<polygon fill="#7D797B" points="1022.347,404.684 1267.271,404.684 1280.879,379.172 1022.347,379.172 "/>
	<polygon fill="#7D797B" points="830.291,464.639 830.291,369.143 815.873,369.143 815.849,464.663 "/>
	<polygon fill="#7D797B" points="815.55,634.688 830.291,634.688 830.291,464.104 815.764,464.096 "/>
	<polygon fill="#7D797B" points="1280.879,477.08 1267.271,487.002 1326.793,487.002 1326.793,477.08 "/>
	<polygon fill="#7D797B" points="1280.879,477.08 1280.879,379.172 1267.271,404.684 1267.271,487.002 "/>
	<rect x="1326.793" y="477.08" fill="#7D797B" width="59.26" height="9.923"/>
	<polygon fill="#7D797B" points="1400.474,483.6 1400.474,477.08 1386.869,477.08 "/>
	<polygon fill="#7D797B" points="1386.869,477.08 1386.869,631.854 1400.474,631.854 1400.474,483.6 "/>
	<rect x="582.466" y="162.124" fill="#7D797B" width="15.308" height="65.276"/>
	<rect x="1045.562" y="62.543" fill="#C4C4FF" width="272.195" height="10.205"/>
	<rect x="607.383" y="22.858" fill="#C4C4FF" width="203.867" height="10.205"/>
	<rect x="1021.213" y="127.06" fill="#7D797B" width="14.739" height="166.224"/>
	<rect x="1442.021" y="353.661" fill="#FF0000" width="102.049" height="11.338"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1022.312,634.688c0-0.922-0.744-1.666-1.666-1.666"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1022.312,539.444c0-0.234-0.049-0.467-0.145-0.682"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1020.646,632.951c0.922,0,1.666-0.746,1.666-1.666"/>
	<rect x="1327.367" y="127.06" fill="#7D797B" width="8.504" height="147.514"/>
	<rect x="1327.367" y="62.543" fill="#7D797B" width="18.736" height="50.911"/>
	<path fill="#7D797B" d="M1109.656,283.078v-8.505h-73.701v8.505H1109.656z M1160.394,283.078h195.318v-8.505h-195.318V283.078z"/>
	<path fill="#7D797B" d="M1291.084,380.874v-8.504h-3.402v8.504H1291.084z M1364.217,372.37h-22.394v8.504h22.394V372.37z"/>
	<path fill="#7D797B" d="M1355.713,286.196h8.504v-11.622h-8.504V286.196z M1355.713,336.938v35.433h8.504v-35.433H1355.713z"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M103.213,579.92c-0.006-0.461-0.385-0.828-0.845-0.822"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M103.188,578.219c-0.006-0.461-0.385-0.828-0.846-0.82"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M103.165,576.518c-0.007-0.459-0.385-0.826-0.846-0.82"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M101.68,472.781c-0.008-0.461-0.386-0.828-0.846-0.821"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M102.367,579.065c0.46-0.008,0.828-0.387,0.821-0.846"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M102.343,577.364c0.461-0.006,0.828-0.385,0.822-0.846"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M102.319,575.662c0.46-0.006,0.828-0.385,0.821-0.844"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M100.834,471.926c0.46-0.008,0.828-0.386,0.821-0.847"/>
	<rect x="1522.947" y="555.317" fill="#7D797B" width="11.338" height="79.369"/>
	<rect x="1460.584" y="543.979" fill="#7D797B" width="113.385" height="11.338"/>
	<path fill="#7D797B" d="M1439.715,127.06v-13.605h-112.349v13.605H1439.715z M1509.449,127.06h113.986l-0.033-13.605h-113.953
		V127.06z"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1346.138,108.352c0-0.114-0.012-0.228-0.035-0.339"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1346.103,113.418c0.023,0.001,0.047,0.001,0.068,0.001"/>
	<path fill="#7D797B" d="M1624.193,414.489l13.605-0.034l-0.351-132.946l-13.606,0.036L1624.193,414.489z M1623.402,113.454
		l0.143,54.705l13.607-0.036l-0.145-54.669H1623.402z M1624.342,471.182l0.053,20.125l13.604-0.033l-0.053-20.127L1624.342,471.182z
		M1624.582,562.174l0.171,72.514h0.021l0.051,19.842h13.605l-0.242-92.393L1624.582,562.174z"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1637.832,414.168c0,0.096,0.009,0.19,0.025,0.285"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1638.213,471.147c-0.152,0.256-0.23,0.551-0.23,0.85"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1639.885,562.166c-0.92,0.003-1.666,0.75-1.662,1.673"/>
	<rect x="1017.217" y="81.28" fill="#7D797B" width="18.735" height="32.175"/>
	<path fill="#7D797B" d="M1317.758,62.543V81.28h9.608V62.543H1317.758z M1017.217,81.28h28.346V62.543h-28.346V81.28z"/>
	<path fill="#7D797B" d="M820.859,152.576h8.503v-25.53l-8.503-0.084V152.576z M820.859,203.316v89.292h8.503v-89.292H820.859
		L820.859,203.316z"/>
	<rect x="820.859" y="22.858" fill="#7D797B" width="18.736" height="90.597"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M841.121,113.42c0.216,0,0.431-0.042,0.631-0.124"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M844.521,113.42c0.402,0,0.789-0.146,1.092-0.407"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M861.531,113.42c0.191,0,0.385-0.034,0.566-0.101"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1014.599,113.42c0.922,0,1.668-0.747,1.668-1.667"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M840.72,113.371c0.131,0.031,0.266,0.049,0.399,0.049"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M843.625,113.158c0.269,0.171,0.58,0.262,0.896,0.262"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1016.336,111.753c0,0.615,0.338,1.181,0.881,1.47"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1016.336,111.753l0.881-1.836"/>
	<path fill="#A0A0A0" d="M322.03,452.582l0.334,10.767l18.136-0.563v-10.776L322.03,452.582z M112.424,469.877l98.896-3.455
		l11.827-0.414l0.003,0.102l41.416-0.963l-0.334-10.768l-151.961,4.727L112.424,469.877z"/>
	<rect x="340.468" y="634.688" fill="#7D797B" width="171.175" height="19.842"/>
	<polygon fill="#A0A0A0" points="333.698,643.862 222.344,645.369 116.175,647.164 116.176,647.172 113.377,647.211 102.529,647.377 
		102.685,658.149 333.698,654.633 "/>
	<polygon fill="#A0A0A0" points="115.027,646.928 112.332,458.821 99.563,459.656 102.257,647.594 "/>
	<path fill="#783C14" d="M223.147,468.721v14.133h11.338v-17.008l-11.335,0.264l0.091,2.607L223.147,468.721z M234.485,645.176
		V533.311h-11.338v112.059L234.485,645.176z"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1155.56,654.563c-0.188,0-0.375,0.031-0.551,0.094"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1022.9,654.563c-0.188,0-0.375,0.031-0.553,0.094"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1098.316,654.668c-0.188-0.068-0.385-0.104-0.582-0.104"/>
	<rect x="1400.474" y="634.688" fill="#7D797B" width="224.539" height="19.842"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M340.468,382.971c0-0.396-0.143-0.779-0.397-1.082"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M338.801,462.873c0.134,0,0.269-0.016,0.397-0.049"/>
	<path fill="#7D797B" d="M811.25,22.858v18.737h9.609V22.858H811.25z M597.773,41.595h9.608V22.858h-9.608V41.595z"/>
	<path fill="#7D797B" d="M582.466,126.938v35.187h15.308v-35.187H582.466z M582.466,227.401v80.965h15.308v-80.965H582.466z
		M582.466,365.625v269.096l3.97-0.033l11.337-0.002V365.625H582.466L582.466,365.625z"/>
	<polygon fill="#7D797B" points="1022.347,634.688 586.436,634.688 582.466,634.723 582.466,634.688 510.411,634.688 510.411,654.53 
		1022.347,654.53 "/>
	<polygon fill="#7D797B" points="597.771,377.647 815.873,377.647 815.873,369.143 756.021,369.143 756.021,377.647 698.762,377.647 
		698.762,369.143 597.771,369.143 "/>
	<polygon fill="#7D797B" points="597.771,301.111 829.365,301.111 829.365,292.607 820.859,292.607 597.771,292.607 "/>
	<path fill="#7D797B" d="M355.808,127.06h34.414v-13.605h-34.414V127.06z M582.466,127.06v-0.122h15.308v0.122l0,0v-13.605H544.48
		v13.605H582.466z M432.175,127.06h70.354v-13.605h-70.354V127.06z"/>
	<path fill="#7D797B" d="M355.808,178.362v-64.907h-15.307v64.907H355.808z M355.808,634.688V381.889h-15.307V654.53h1.7v-19.842
		H355.808L355.808,634.688z"/>
	<rect x="579.036" y="22.858" fill="#7D797B" width="18.737" height="90.597"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M343.733,113.42c0.187,0,0.37-0.031,0.546-0.092"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M347.135,113.42c0.287,0,0.567-0.074,0.817-0.215"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M500.206,113.42c0.922,0,1.667-0.747,1.667-1.667"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M578.442,113.42c0.202,0,0.403-0.037,0.594-0.109"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M343.294,113.36c0.145,0.04,0.29,0.061,0.439,0.061"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M346.416,113.256c0.226,0.107,0.471,0.164,0.72,0.164"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M501.94,111.753c0,0.489,0.215,0.954,0.588,1.271"/>
	<path fill="#7D797B" d="M1022.347,631.771v22.676h75.969v-22.676H1022.347z M1400.474,631.771h-249.781h4.316v22.676h245.465
		V631.771z"/>
	<polygon fill="#EFEFEF" points="190.149,22.363 193.591,22.363 193.333,19 188.832,16.592 187.855,20.069 "/>
	<polygon fill="#EFEFEF" points="117.11,1.714 117.11,5.155 119.787,5.155 122.082,0.184 "/>
	<polygon fill="#EFEFEF" points="146.173,6.303 137.76,4.773 133.936,8.597 131.259,9.362 127.053,8.979 127.817,5.538 
		123.611,6.685 124.376,10.892 124.758,15.098 121.699,12.421 120.552,8.979 116.728,10.509 111.756,8.215 109.08,6.685 
		110.719,4.658 105.369,5.684 106.021,7.832 104.491,9.744 99.787,7.013 97.99,4.008 95.313,3.243 91.872,5.538 92.636,7.067 
		86.135,13.951 82.694,15.863 76.798,15.51 77.34,13.568 76.958,11.656 73.516,13.951 75.428,19.687 77.723,22.746 73.898,23.893 
		71.604,22.363 73.134,20.069 69.045,19.609 65.103,18.922 63.956,26.57 58.94,26.07 55.734,28.419 57.533,33.779 59.76,36.006 
		58.089,38.233 55.306,37.676 51.965,34.335 45.284,33.779 41.943,36.006 35.261,53.266 13.547,76.651 5.751,98.922 6.494,116.031 
		9.092,141.795 4.082,155.715 1.297,155.715 0.184,159.056 3.524,161.839 11.319,176.873 19.671,191.906 25.239,191.906 
		27.433,194.3 24.791,195.179 23.012,197.474 26.91,203.598 41.943,212.507 51.965,220.858 56.976,234.778 70.457,245.305 
		77.34,246.835 83.076,242.628 89.577,249.512 107.933,254.101 121.979,254.271 146.555,247.982 151.144,246.835 162.234,246.835 
		171.029,244.923 182.501,239.952 193.591,234.98 202.245,233.042 215.388,218.537 224.183,205.917 225.331,201.711 226.478,203.24 
		228.772,202.858 228.39,199.417 231.831,195.593 232.596,186.415 236.42,181.826 236.802,171.501 236.802,164.235 241.774,157.734 
		244.451,154.675 242.156,152.381 241.391,155.058 238.332,156.205 237.567,150.086 244.833,142.056 252.099,136.703 
		254.393,129.819 252.099,126.377 247.127,125.23 245.598,123.318 250.187,120.259 247.127,116.435 249.422,114.905 252.481,116.817 
		250.569,111.464 244.833,109.169 246.709,102.751 249.821,101.196 249.199,97.773 246.087,97.463 245.154,94.974 247.954,93.107 
		246.709,88.752 244.843,90.308 241.732,88.752 241.421,85.33 244.221,83.774 242.043,79.73 239.866,80.353 240.488,75.375 
		243.288,75.686 247.332,73.819 247.332,69.464 245.154,68.22 243.641,71.148 244.273,72.394 242.666,73.197 239.866,71.02 
		237.066,71.953 235.821,69.464 237.377,66.975 233.955,62.931 235.821,61.375 238.932,58.264 236.132,52.665 233.404,54.563 
		233.333,58.264 229.288,58.887 228.977,54.22 225.555,52.976 219.594,54.103 216.917,53.338 212.711,52.191 211.564,47.984 
		215.388,46.837 221.124,49.132 223.418,46.455 221.124,43.778 220.359,45.69 216.917,45.308 216.535,42.631 216.917,38.807 
		213.093,38.042 213.476,40.719 211.564,43.013 203.533,42.249 203.151,39.189 206.21,36.895 206.21,33.071 201.239,35.748 
		195.503,36.513 192.444,34.983 194.356,32.688 197.797,32.688 195.503,29.629 190.914,30.012 187.09,31.924 188.237,28.864 
		187.855,25.805 183.266,25.423 184.031,28.482 179.06,28.864 176.765,25.423 176.383,21.599 179.824,20.834 182.119,21.599 
		184.031,19.304 179.442,15.098 171.412,16.245 168.352,10.892 163.763,13.568 158.41,11.656 149.614,12.039 145.025,11.656 
		145.025,9.744 148.085,9.362 "/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M25.239,191.906h-5.568l-8.352-15.032L3.524,161.84l-3.34-2.784l1.112-3.341h2.784l5.011-13.92l-2.598-25.764L5.751,98.922
		l7.795-22.271L35.26,53.266l6.683-17.26l3.341-2.228l6.681,0.558l3.342,3.341l2.783,0.557l1.67-2.227l-2.227-2.228l-1.799-5.358
		l3.206-2.35l5.016,0.5l1.147-7.647l3.941,0.687l4.088,0.46l-1.529,2.294l2.294,1.53l3.824-1.146l-2.295-3.061l-1.911-5.735
		l3.44-2.294l0.383,1.911l-0.542,1.941l5.896,0.354l3.44-1.911l6.501-6.884L91.869,5.54l3.441-2.294l2.677,0.765l1.797,3.004
		l4.704,2.731l1.53-1.912 M56.976,234.778l-5.012-13.92l-10.021-8.352l-15.033-8.909l-3.896-6.125l1.777-2.294 M127.374,127.396
		v-17.008 M127.374,127.396h17.008 M127.374,127.396v17.008 M127.374,127.396h-17.008"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M190.149,22.363h3.44L193.333,19 M133.936,8.597l3.824-3.824l8.412,1.53l1.912,3.059l-3.06,0.383v1.911l4.589,0.383l8.795-0.383
		l5.354,1.912l4.589-2.677l3.06,5.354l8.03-1.147l4.589,4.207l-1.912,2.294l-2.294-0.765l-3.441,0.765l0.383,3.824l2.294,3.44
		l4.971-0.382l-0.765-3.059l4.589,0.382l0.382,3.059l-1.147,3.061l-3.44,1.529l-2.677,1.529l1.528,1.147 M119.787,5.155l2.294-4.971
		l-4.972,1.53v3.44H119.787 M106.021,7.832l-0.652-2.148l5.352-1.024l-1.641,2.026l2.677,1.53l4.972,2.294l3.824-1.529l1.146,3.441
		l3.06,2.677l-0.382-4.206l-0.765-4.207l4.207-1.146l-0.765,3.441l4.207,0.382l0.765,3.06l6.118-0.766l2.295,2.295l-0.766,3.823
		l-3.059,1.53l-3.06-0.765"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M233.361,85.46l6.119,5.735l-0.767,3.441 M205.063,55.25l-3.06-2.678l1.147,6.501L205.063,55.25 M211.564,47.984l3.823-1.146
		l5.735,2.294l2.294-2.677l-2.294-2.678l-0.765,1.912l-3.441-0.383l-0.382-2.677l0.382-3.824l-3.824-0.765l0.384,2.677l-1.911,2.294
		M190.914,30.012l4.589-0.382l2.294,3.059h-3.44l-1.912,2.294l3.059,1.53l5.736-0.765l4.972-2.678v3.824l-3.06,2.294l0.382,3.06
		l2.294,3.441l6.883,6.5l4.207,1.147l2.678,0.765l-1.146,2.677l2.294,3.442l-5.354-0.765l-0.766-2.678l-1.912,1.912l1.147,3.06
		l3.823,0.765l0.766,2.677l3.44-1.146l-1.146,2.677l-1.912,1.147l3.44,1.529l3.824-2.295l3.06,1.53l-1.912,1.912l-3.824,3.059
		l1.912,4.207 M193.333,19l-4.5-2.407l-0.977,3.477l2.294,2.294"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M246.709,102.751l3.111-1.557 M151.144,246.835l-4.589,1.146l-24.576,6.289l-14.046-0.17l-18.355-4.589l-6.501-6.883l-5.736,4.207
		l-6.883-1.53l-13.48-10.527 M182.501,239.952l-11.472,4.971 M171.029,244.923l-8.795,1.912h-11.09 M247.127,125.23l4.972,1.147
		l2.294,3.441l-2.294,6.884l-7.267,5.354l-7.266,8.03l0.765,6.119l3.06-1.147l0.766-2.677l2.294,2.294l-2.677,3.059l-4.974,6.501
		v7.267l-0.382,10.325l-3.824,4.589l-0.765,9.178l-3.44,3.824l0.382,3.441l-2.294,0.383l-1.146-1.53l-1.146,4.207l-8.795,12.62
		l-13.144,14.505l-8.654,1.938l-11.09,4.972 M238.714,94.638l2.294,6.883l0.383,3.061l-2.678,0.765l-1.528-1.912l-0.384,3.824
		l2.678,2.677l5.354-0.765l5.736,2.294l1.912,5.354l-3.061-1.912l-2.294,1.53l3.06,3.824l-4.589,3.059l-4.972-2.294"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M24.791,195.179l2.643-0.879l-2.193-2.395 M240.488,75.375l2.8,0.311l4.044-1.866v-4.354l-2.178-1.244l-1.515,2.929l0.633,1.246
		l-1.607,0.803l-2.8-2.178l-2.8,0.934l-1.245-2.489l1.556-2.489l-3.422-4.044l1.867-1.556l3.11-3.111l-2.801-5.6l-2.729,1.897
		l-0.071,3.701l-4.044,0.623l-0.312-4.667l-3.422-1.245 M245.154,94.974l2.8-1.866l-1.245-4.354l-1.865,1.556l-3.11-1.556
		l-0.313-3.422l2.801-1.557l-2.179-4.044l-2.178,0.623l-0.935,3.111l-2.179,2.8 M196.001,57.02l0.934-3.732l-1.867-4.667l-0.622,3.11
		l-3.111-0.623l-1.556-5.6l-5.289-0.312l0.622,4.354h2.489l1.245,0.311l1.243,3.733l3.111,1.867L196.001,57.02 M249.821,101.196
		l-0.622-3.422l-3.111-0.311l0.936,2.177l-0.936,1.867l0.622,1.245"/>
	<line fill="none" stroke="#A0A0A0" stroke-width="0.5102" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1267.271" y1="405.819" x2="1267.271" y2="630.606"/>
	<path fill="none" stroke="#808080" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M959.986,289.104h58.959 M959.986,275.498h58.959 M959.986,261.891h58.959 M959.986,248.285h58.959 M959.986,234.679h58.959
		M1018.947,134.543v154.561 M989.465,289.104l5.67-5.669 M989.465,289.104l-5.668-5.669 M989.465,289.104v-103.82 M959.986,221.072
		h58.959 M959.986,211.255l58.959-8.662 M959.986,202.593l58.959-20.03 M959.986,195.285l58.959-37.354 M956.76,190.669
		l43.039-56.126 M949.994,190.669l27.338-56.126"/>
	<path fill="none" stroke="#C0C0C0" stroke-width="0.5102" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1151.613,517.647c0,62.072,51.781,112.393,115.658,112.393 M1267.271,405.252c-63.877,0-115.658,50.319-115.658,112.395"/>
	<path fill="none" stroke="#808080" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M989.465,185.283c0-12.524-10.15-22.678-22.677-22.678"/>
	<path fill="#FFFFFF" stroke="#000000" d="M959.986,289.104h-2.834V128.307 M959.986,289.104V128.307"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1501.551,384.842h28.348v-17.008 M1498.715,387.676h34.016V365 M1501.551,367.834v17.008 M1498.715,365v22.676 M1529.896,367.834
		h-28.346"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1515.722,372.329c0.691,0,1.252-0.562,1.252-1.252 M1514.47,371.077c0,0.69,0.563,1.252,1.252,1.252 M1515.722,369.825
		c-0.689,0-1.252,0.561-1.252,1.252 M1516.974,371.077c0-0.691-0.562-1.25-1.252-1.25"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1456.197,384.842h28.346v-17.008 M1453.361,387.676h34.016V365 M1456.197,367.834v17.008 M1453.361,365v22.676 M1484.543,367.834
		h-28.349"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1470.369,372.329c0.691,0,1.252-0.562,1.252-1.252 M1469.119,371.077c0,0.69,0.56,1.252,1.25,1.252 M1470.369,369.825
		c-0.69,0-1.25,0.561-1.25,1.252 M1471.621,371.077c0-0.691-0.561-1.25-1.252-1.25"/>
	<line fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1544.07" y1="379.172" x2="1544.07" y2="353.661"/>
	<path fill="#FFFFFF" stroke="#000000" d="M1050.695,476.401v62.361 M1065.433,476.401v62.361"/>
	<path fill="#FFFFFF" stroke="#000000" d="M1098.316,538.764c0-34.441-27.922-62.361-62.361-62.361"/>
	<line fill="#FFFFFF" stroke="#000000" x1="1035.955" y1="538.764" x2="1098.316" y2="538.764"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M340.502,287.212V178.362 M340.502,287.212v2.268 M354.107,178.362h-13.605v-2.268"/>
	<line fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="340.502" y1="638.948" x2="340.502" y2="654.53"/>
	<path fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1035.955,631.854v-93.09 M1022.347,538.764v93.088 M340.502,654.53h509.08"/>
	<path fill="none" stroke="#C0C0C0" stroke-width="0.5102" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1151.613,518.891c0-62.072-51.781-112.393-115.658-112.393 M1035.955,631.285c63.877,0,115.658-50.319,115.658-112.395"/>
	<path fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1624.824,340.055h-260.604 M1624.824,634.688h-224.242"/>
	<path fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1637.797,413.868l-0.355-135.496 M1631.488,281.49h-7.651l0.354,132.378 M1638.025,496.073l-0.076-24.944 M1637.949,471.127
		h-13.606l0.077,21.827 M1638.429,654.53l-0.25-95.526 M1632.226,562.119h-7.652l0.25,72.566"/>
	<line fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1637.441" y1="278.372" x2="1637.185" y2="181.485"/>
	<path fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1335.871,127.06h104.412 M1443.402,113.454h-97.299 M1637.008,113.454H1506.33 M1509.449,119.406v7.654h113.953 M1637.185,181.485
		l-0.178-68.031"/>
	<line fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1035.955" y1="293.282" x2="1035.955" y2="127.06"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1561.367,615.854l0.349-0.119l0.344-0.129l0.334-0.146l0.319-0.172l0.261-0.176l0.241-0.197l0.226-0.221l0.201-0.24l0.209-0.309
		l0.18-0.328l0.148-0.346l0.125-0.354l0.139-0.502l0.104-0.508l0.078-0.516l0.06-0.519l0.039-0.418l0.03-0.418l0.029-0.42
		l0.027-0.418l0.029-0.408l0.026-0.408l0.021-0.406l0.014-0.407l0.004-0.265v-0.264l-0.004-0.262l-0.006-0.265 M1534.285,596.42
		v19.842l2.578,0.055l2.576,0.055l2.578,0.05l2.578,0.045l2.58,0.036l2.58,0.027l2.584,0.016l0.645,0.003l0.646-0.003l0.645-0.006
		l0.646-0.012l0.645-0.021l0.644-0.031l0.643-0.045l0.676-0.062l0.674-0.073l0.674-0.089l0.674-0.094l0.673-0.104l0.672-0.105
		l0.672-0.108"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1561.367,596.828l0.349,0.119l0.344,0.129l0.334,0.146l0.319,0.176l0.261,0.174l0.241,0.197l0.226,0.221l0.201,0.24l0.209,0.31
		l0.18,0.327l0.148,0.345l0.125,0.354l0.139,0.502l0.104,0.511l0.078,0.514l0.06,0.518l0.039,0.418l0.03,0.42l0.029,0.418
		l0.027,0.418l0.029,0.408l0.026,0.408l0.021,0.406l0.014,0.408l0.004,0.264v0.264l-0.004,0.263l-0.006,0.264v0.758 M1534.285,596.42
		l2.578-0.058l2.576-0.055l2.578-0.049l2.578-0.045l2.58-0.037l2.58-0.025l2.584-0.018l0.645-0.002l0.646,0.002l0.645,0.006
		l0.646,0.014l0.645,0.021l0.644,0.03l0.643,0.045l0.676,0.062l0.674,0.074l0.674,0.086l0.674,0.098l0.673,0.102l0.672,0.107
		l0.672,0.107"/>
	<polyline fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		1544.215,606.428 1544.217,606.672 1544.22,606.916 1544.226,607.16 1544.234,607.403 1544.244,607.647 1544.25,607.826 
		1544.261,608.004 1544.275,608.182 1544.289,608.36 1544.302,608.537 1544.314,608.662 1544.328,608.787 1544.34,608.912 
		1544.353,609.037 1544.369,609.162 1544.385,609.283 1544.4,609.403 1544.418,609.524 1544.435,609.645 1544.455,609.766 
		1544.484,609.934 1544.515,610.102 1544.549,610.27 1544.588,610.436 1544.629,610.602 1544.666,610.733 1544.703,610.864 
		1544.744,610.992 1544.789,611.121 1544.834,611.25 1544.873,611.342 1544.912,611.436 1544.953,611.528 1544.996,611.617 
		1545.041,611.707 1545.086,611.793 1545.135,611.879 1545.185,611.963 1545.24,612.047 1545.295,612.127 1545.334,612.182 
		1545.375,612.235 1545.418,612.287 1545.459,612.34 1545.502,612.389 1545.56,612.453 1545.623,612.516 1545.683,612.574 
		1545.748,612.633 1545.812,612.69 "/>
	<polyline fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		1545.812,612.69 1545.892,612.752 1545.974,612.813 1546.058,612.871 1546.144,612.928 1546.23,612.981 1546.324,613.033 
		1546.416,613.082 1546.51,613.129 1546.603,613.174 1546.701,613.217 1546.834,613.274 1546.97,613.326 1547.109,613.373 
		1547.248,613.418 1547.386,613.461 1547.576,613.514 1547.765,613.561 1547.957,613.604 1548.146,613.643 1548.34,613.678 
		1548.543,613.711 1548.748,613.742 1548.953,613.772 1549.16,613.797 1549.365,613.821 1549.551,613.84 1549.738,613.858 
		1549.924,613.873 1550.111,613.889 1550.297,613.903 1550.484,613.914 1550.67,613.926 1550.855,613.938 1551.043,613.946 
		1551.228,613.955 1551.455,613.963 1551.677,613.971 1551.904,613.979 1552.127,613.983 1552.353,613.987 1552.582,613.989 
		1552.81,613.99 1553.041,613.992 1553.267,613.992 1553.498,613.992 1553.879,613.99 1554.261,613.989 1554.642,613.983 
		1555.025,613.975 1555.408,613.963 "/>
	<polyline fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		1555.408,613.963 1555.783,613.948 1556.156,613.928 1556.531,613.905 1556.904,613.875 1557.277,613.842 1557.443,613.824 
		1557.607,613.807 1557.771,613.787 1557.935,613.766 1558.101,613.742 1558.336,613.705 1558.574,613.666 1558.806,613.621 
		1559.041,613.571 1559.271,613.512 1559.398,613.479 1559.525,613.442 1559.65,613.403 1559.771,613.362 1559.894,613.317 
		1559.986,613.283 1560.076,613.248 1560.164,613.209 1560.25,613.17 1560.338,613.129 1560.402,613.098 1560.465,613.065 
		1560.527,613.03 1560.588,612.994 1560.65,612.959 1560.724,612.912 1560.797,612.865 1560.869,612.815 1560.939,612.762 
		1561.008,612.709 1561.152,612.582 1561.289,612.448 1561.416,612.303 1561.533,612.151 1561.64,611.992 1561.728,611.85 
		1561.806,611.705 1561.881,611.555 1561.949,611.405 1562.01,611.25 1562.056,611.121 1562.101,610.992 1562.142,610.864 
		1562.179,610.733 1562.217,610.602 "/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1544.215,606.428l0.002-0.277l0.002-0.279l0.006-0.278l0.008-0.277l0.012-0.279l0.008-0.178l0.011-0.18l0.014-0.178l0.015-0.179
		l0.016-0.178l0.012-0.125l0.014-0.125l0.013-0.125l0.016-0.125l0.017-0.125l0.016-0.121l0.016-0.121l0.019-0.121l0.018-0.118
		l0.021-0.121l0.029-0.168l0.029-0.168l0.035-0.168l0.037-0.166l0.041-0.166l0.037-0.132l0.037-0.131l0.041-0.131l0.045-0.129
		l0.047-0.127 M1562.217,610.602l0.016-0.066l0.018-0.065l0.017-0.064l0.018-0.067l0.015-0.064l0.059-0.295l0.053-0.299l0.043-0.297
		l0.037-0.299l0.033-0.3l0.012-0.127l0.014-0.127l0.011-0.127l0.012-0.129l0.008-0.127l0.021-0.344l0.014-0.345l0.012-0.342
		l0.009-0.344l0.006-0.346"/>
	<polyline fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		1544.834,601.432 1544.873,601.338 1544.912,601.244 1544.953,601.155 1544.996,601.063 1545.041,600.973 1545.086,600.887 
		1545.135,600.801 1545.185,600.717 1545.24,600.635 1545.295,600.553 1545.334,600.498 1545.375,600.446 1545.418,600.393 
		1545.459,600.342 1545.502,600.291 1545.56,600.229 1545.623,600.166 1545.683,600.106 1545.748,600.047 1545.812,599.992 
		1545.892,599.928 1545.974,599.867 1546.058,599.809 1546.144,599.754 1546.23,599.699 1546.324,599.649 1546.416,599.598 
		1546.51,599.551 1546.603,599.506 1546.701,599.463 1546.834,599.406 1546.97,599.356 1547.109,599.307 1547.248,599.262 
		1547.386,599.219 1547.576,599.168 1547.765,599.121 1547.957,599.078 1548.146,599.039 1548.34,599.002 1548.543,598.969 
		1548.748,598.938 1548.953,598.91 1549.16,598.883 1549.365,598.86 1549.551,598.842 1549.738,598.823 1549.924,598.807 
		1550.111,598.791 1550.297,598.778 "/>
	<polyline fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		1550.297,598.778 1550.484,598.766 1550.67,598.754 1550.855,598.744 1551.043,598.735 1551.228,598.727 1551.455,598.717 
		1551.677,598.709 1551.904,598.703 1552.127,598.699 1552.353,598.696 1552.582,598.692 1552.81,598.69 1553.041,598.688 
		1553.267,598.688 1553.498,598.688 1553.879,598.69 1554.261,598.692 1554.642,598.698 1555.025,598.707 1555.408,598.717 
		1555.783,598.733 1556.156,598.752 1556.531,598.776 1556.904,598.805 1557.277,598.838 1557.443,598.856 1557.607,598.875 
		1557.771,598.895 1557.935,598.916 1558.101,598.938 1558.336,598.975 1558.574,599.014 1558.806,599.061 1559.041,599.112 
		1559.271,599.168 1559.398,599.203 1559.525,599.239 1559.65,599.278 1559.771,599.319 1559.894,599.364 1559.986,599.397 
		1560.076,599.434 1560.164,599.471 1560.25,599.51 1560.338,599.551 1560.402,599.584 1560.465,599.615 1560.527,599.651 
		1560.588,599.686 1560.65,599.721 "/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1544.72,606.428l0.004,0.299l0.006,0.326l0.01,0.34l0.015,0.342l0.016,0.273l0.021,0.268l0.021,0.266l0.027,0.265l0.024,0.231
		l0.029,0.232 M1560.65,599.721l0.074,0.047l0.072,0.049l0.071,0.051l0.068,0.052l0.068,0.055l0.146,0.125l0.135,0.137l0.127,0.146
		l0.119,0.151l0.107,0.158l0.086,0.144l0.08,0.146l0.072,0.148l0.067,0.151l0.063,0.152l0.047,0.127l0.043,0.131l0.041,0.129
		l0.039,0.131l0.035,0.131l0.018,0.066l0.018,0.066l0.017,0.065l0.016,0.066l0.017,0.065l0.059,0.295l0.053,0.297l0.043,0.298
		l0.037,0.299l0.031,0.301l0.014,0.127l0.012,0.127l0.013,0.127l0.01,0.127l0.01,0.127l0.021,0.38l0.016,0.379l0.01,0.377
		l0.006,0.379l0.007,0.379"/>
	<polyline fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		1544.892,609.268 1544.924,609.5 1544.961,609.733 1544.992,609.901 1545.025,610.069 1545.058,610.237 1545.099,610.403 
		1545.133,610.533 1545.17,610.662 1545.209,610.793 1545.252,610.92 1545.291,611.026 1545.33,611.129 1545.373,611.233 
		1545.418,611.334 1545.459,611.418 1545.502,611.5 1545.547,611.582 1545.594,611.664 1545.636,611.735 1545.683,611.803 
		1545.73,611.871 1545.783,611.938 1545.828,611.994 1545.875,612.049 1545.924,612.104 1545.976,612.156 1546.029,612.207 
		1546.084,612.256 1546.138,612.305 1546.197,612.35 1546.261,612.399 1546.33,612.448 1546.398,612.492 1546.469,612.535 
		1546.552,612.584 1546.636,612.629 1546.722,612.674 1546.81,612.715 1546.916,612.762 1547.021,612.805 1547.127,612.846 
		1547.236,612.885 1547.371,612.93 1547.506,612.971 1547.642,613.01 1547.781,613.045 1547.953,613.086 1548.125,613.123 
		1548.301,613.158 1548.474,613.19 "/>
	<polyline fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		1548.474,613.19 1548.707,613.229 1548.937,613.26 1549.17,613.289 1549.402,613.317 1549.713,613.348 1550.025,613.377 
		1550.336,613.401 1550.648,613.42 1551.035,613.442 1551.42,613.457 1551.806,613.469 1552.195,613.479 1552.726,613.487 
		1553.26,613.489 1553.793,613.489 1554.328,613.483 1554.734,613.479 1555.14,613.467 1555.549,613.453 1555.955,613.434 
		1556.275,613.414 1556.594,613.391 1556.916,613.365 1557.236,613.338 1557.486,613.319 1557.734,613.293 1557.98,613.26 
		1558.226,613.213 1558.355,613.182 1558.486,613.147 1558.615,613.11 1558.744,613.073 1558.885,613.033 1559.029,612.996 
		1559.17,612.957 1559.31,612.914 1559.424,612.877 1559.537,612.836 1559.646,612.793 1559.756,612.746 1559.845,612.705 
		1559.935,612.662 1560.025,612.615 1560.111,612.569 1560.183,612.526 1560.256,612.481 1560.326,612.432 1560.394,612.383 
		1560.453,612.34 1560.508,612.293 "/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1544.72,606.432l0.004-0.369l0.006-0.371l0.01-0.371l0.017-0.369l0.014-0.27l0.021-0.269l0.021-0.268l0.027-0.271
		M1560.508,612.293l0.055-0.048l0.055-0.049l0.054-0.055l0.053-0.055l0.051-0.058l0.051-0.059l0.052-0.063l0.047-0.065l0.047-0.064
		l0.045-0.067l0.047-0.076l0.045-0.076l0.043-0.078l0.039-0.08l0.047-0.1l0.043-0.101l0.041-0.1l0.037-0.102l0.043-0.123l0.041-0.123
		l0.037-0.125l0.035-0.125l0.041-0.16l0.037-0.162l0.033-0.162l0.028-0.162l0.037-0.213l0.033-0.213l0.029-0.213l0.022-0.215
		l0.029-0.261l0.025-0.264l0.021-0.262l0.018-0.265l0.019-0.338l0.012-0.338l0.008-0.34l0.006-0.338v-0.09l0.002-0.088l0.002-0.091
		v-0.088"/>
	<polyline fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		1544.838,603.879 1544.865,603.647 1544.892,603.414 1544.926,603.182 1544.963,602.951 1544.994,602.783 1545.025,602.615 
		1545.06,602.448 1545.099,602.281 1545.135,602.151 1545.172,602.022 1545.211,601.891 1545.254,601.762 1545.293,601.658 
		1545.332,601.555 1545.375,601.451 1545.42,601.35 1545.459,601.266 1545.502,601.182 1545.547,601.1 1545.595,601.02 
		1545.638,600.949 1545.685,600.881 1545.734,600.813 1545.783,600.746 1545.83,600.69 1545.877,600.633 1545.926,600.58 
		1545.976,600.528 1546.031,600.477 1546.084,600.426 1546.14,600.379 1546.199,600.334 1546.263,600.283 1546.332,600.237 
		1546.4,600.192 1546.47,600.149 1546.552,600.1 1546.638,600.053 1546.724,600.01 1546.812,599.969 1546.918,599.922 
		1547.021,599.879 1547.129,599.838 1547.238,599.799 1547.373,599.754 1547.508,599.713 1547.644,599.674 1547.781,599.639 
		1547.955,599.598 1548.127,599.559 "/>
	<polyline fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		1548.127,599.559 1548.301,599.526 1548.476,599.494 1548.709,599.457 1548.939,599.422 1549.17,599.393 1549.404,599.365 
		1549.715,599.334 1550.027,599.307 1550.338,599.283 1550.65,599.264 1551.037,599.242 1551.422,599.227 1551.808,599.213 
		1552.197,599.205 1552.728,599.198 1553.261,599.194 1553.795,599.196 1554.328,599.199 1554.736,599.207 1555.142,599.215 
		1555.549,599.229 1555.957,599.25 1556.277,599.27 1556.595,599.293 1556.916,599.319 1557.238,599.344 1557.486,599.365 
		1557.736,599.391 1557.984,599.424 1558.228,599.471 1558.359,599.502 1558.488,599.537 1558.617,599.574 1558.746,599.612 
		1558.886,599.651 1559.031,599.688 1559.172,599.727 1559.312,599.77 1559.426,599.807 1559.539,599.848 1559.648,599.891 
		1559.758,599.938 1559.847,599.979 1559.937,600.022 1560.025,600.067 1560.113,600.115 1560.185,600.158 1560.256,600.203 
		1560.328,600.25 1560.396,600.301 "/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1546.931,606.356v-0.139l0.002-0.138 M1561.918,606.428v0.002l0,0v0.002v0.002 M1560.396,600.301l0.059,0.043l0.056,0.045
		l0.057,0.049l0.053,0.049l0.056,0.053l0.051,0.058l0.053,0.057l0.049,0.06l0.052,0.063l0.049,0.064l0.045,0.065l0.047,0.066
		l0.047,0.076l0.045,0.078l0.041,0.079l0.041,0.08l0.045,0.099l0.046,0.1l0.038,0.101l0.039,0.102l0.043,0.121l0.041,0.125
		l0.037,0.125l0.033,0.125l0.041,0.16l0.037,0.162l0.032,0.161l0.031,0.162l0.037,0.213l0.031,0.212l0.029,0.215l0.024,0.213
		l0.029,0.264l0.022,0.264l0.021,0.263l0.018,0.262l0.019,0.328l0.012,0.33l0.008,0.34l0.006,0.355v0.053l0.002,0.051v0.049v0.041"/>
	<polyline fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		1546.933,606.082 1546.937,605.944 1546.941,605.807 1546.947,605.696 1546.953,605.584 1546.959,605.475 1546.967,605.364 
		1546.974,605.272 1546.984,605.18 1546.994,605.088 1547.004,604.996 1547.019,604.881 1547.039,604.768 1547.058,604.653 
		1547.084,604.541 1547.099,604.479 1547.117,604.414 1547.135,604.352 1547.156,604.289 1547.179,604.225 1547.207,604.164 
		1547.238,604.104 1547.275,604.047 1547.299,604.016 1547.326,603.985 1547.355,603.955 1547.385,603.928 1547.416,603.905 
		1547.447,603.883 1547.476,603.864 1547.51,603.844 1547.556,603.821 1547.603,603.799 1547.654,603.781 1547.705,603.764 
		1547.763,603.748 1547.824,603.737 1547.885,603.729 1547.945,603.725 1547.967,603.725 1547.99,603.725 1548.01,603.725 
		1548.033,603.725 1548.504,603.729 1548.974,603.739 1549.447,603.762 1549.916,603.801 1550.092,603.823 1550.267,603.848 
		1550.445,603.879 1550.619,603.916 "/>
	<polyline fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		1550.619,603.916 1550.724,603.942 1550.83,603.969 1550.933,604 1551.037,604.033 1551.125,604.067 1551.213,604.104 
		1551.299,604.141 1551.383,604.184 1551.478,604.239 1551.572,604.299 1551.662,604.364 1551.748,604.432 1551.816,604.494 
		1551.883,604.561 1551.947,604.629 1552.004,604.701 1552.047,604.756 1552.088,604.813 1552.125,604.871 1552.162,604.93 
		1552.207,605.01 1552.25,605.09 1552.287,605.174 1552.324,605.258 1552.345,605.321 1552.369,605.383 1552.388,605.446 
		1552.408,605.51 1552.429,605.594 1552.451,605.68 1552.467,605.764 1552.48,605.85 1552.494,605.932 1552.502,606.014 
		1552.51,606.096 1552.515,606.178 1552.517,606.221 1552.517,606.264 1552.519,606.307 1552.519,606.35 1552.515,606.471 
		1552.51,606.59 1552.498,606.709 1552.48,606.828 1552.463,606.938 1552.439,607.045 1552.414,607.151 1552.383,607.256 
		1552.345,607.362 1552.304,607.467 "/>
	<polyline fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		1552.304,607.467 1552.258,607.571 1552.209,607.67 1552.162,607.75 1552.113,607.828 1552.06,607.905 1552.006,607.979 
		1551.912,608.09 1551.808,608.194 1551.699,608.289 1551.582,608.375 1551.498,608.432 1551.41,608.483 1551.318,608.53 
		1551.226,608.573 1551.103,608.621 1550.98,608.666 1550.855,608.705 1550.728,608.739 1550.586,608.772 1550.441,608.801 
		1550.297,608.828 1550.152,608.85 1550.002,608.869 1549.853,608.885 1549.705,608.899 1549.552,608.912 1549.422,608.92 
		1549.289,608.928 1549.158,608.936 1549.025,608.94 1548.867,608.944 1548.709,608.946 1548.551,608.949 1548.392,608.953 
		1548.275,608.959 1548.156,608.961 1548.037,608.961 1547.918,608.953 1547.849,608.946 1547.781,608.934 1547.713,608.918 
		1547.644,608.897 1547.568,608.865 1547.492,608.828 1547.42,608.781 1547.357,608.729 1547.314,608.682 1547.275,608.631 
		1547.242,608.576 1547.209,608.522 "/>
	<polyline fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		1547.209,608.522 1547.166,608.422 1547.131,608.321 1547.101,608.217 1547.078,608.112 1547.049,607.965 1547.025,607.819 
		1547.002,607.672 1546.986,607.526 1546.974,607.418 1546.965,607.311 1546.959,607.203 1546.953,607.096 1546.943,606.908 
		1546.937,606.723 1546.933,606.535 1546.931,606.348 "/>
	<path fill="#FFFFFF" stroke="#000000" d="M1065.433,506.731L1065.433,506.731 M1065.433,508.432L1065.433,508.432 M1066.285,507.582
		L1066.285,507.582 M1064.584,507.582L1064.584,507.582 M1065.433,507.582h-29.479 M1065.433,476.401v62.361 M1035.955,538.764
		h29.479 M1035.955,476.401h29.479"/>
	<path fill="#FFFFFF" stroke="#000000" d="M1064.584,507.582L1064.584,507.582 M1065.433,506.731c-0.469,0-0.85,0.381-0.85,0.852
		M1066.285,507.582c0-0.471-0.381-0.852-0.852-0.852 M1065.433,508.432c0.471,0,0.852-0.381,0.852-0.851 M1064.584,507.582
		c0,0.47,0.381,0.851,0.85,0.851"/>
	<path fill="#FFFFFF" stroke="#000000" d="M1035.955,507.582L1035.955,507.582l2.269,1.304v-2.605L1035.955,507.582
		M1035.955,507.582h2.834"/>
	<rect x="1151.357" y="517.387" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 2331.9414 69.3532)" width="0.5" height="0.501"/>
	<rect x="1151.357" y="517.387" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 2331.9414 69.3532)" width="0.5" height="0.501"/>
	<line fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1154.416" y1="654.53" x2="1638.429" y2="654.53"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1443.912,115.551h61.343 M1443.912,117.649h61.343 M1443.912,113.454h61.343 M1509.449,113.454h-4.194v4.194h4.194V113.454
		M1439.715,113.454h4.194v4.194h-4.194V113.454"/>
	<path fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1410.222,340.055"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1364.217,286.196L1364.217,286.196 M1414.959,336.938c0-28.022-22.72-50.74-50.74-50.74"/>
	<line fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1291.084" y1="380.874" x2="1291.084" y2="431.614"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1341.824,380.874L1341.824,380.874 M1291.084,431.614c28.021,0,50.739-22.717,50.739-50.739"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1314.584,74.335h-265.847 M1314.584,75.922h-265.847 M1314.584,72.748h-265.847 M1045.562,72.748h3.176v3.175h-3.176V72.748
		M1317.758,72.748h-3.177v3.175h3.177V72.748"/>
	<rect x="1298.737" y="282.219" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 2417.4883 -436.2172)" fill="#00FF00" width="0.702" height="0.702"/>
	<rect x="1452.429" y="353.158" transform="matrix(-0.7076 0.7066 -0.7066 -0.7076 2730.9175 -422.7623)" fill="#FFFF00" width="1.003" height="1.004"/>
	<rect x="1530.949" y="353.159" transform="matrix(0.3979 0.9174 -0.9174 0.3979 1246.5321 -1192.0555)" fill="#FFFF00" width="1.004" height="1.003"/>
	<rect x="1621.766" y="365.4" transform="matrix(-0.1636 0.9865 -0.9865 -0.1636 2248.6545 -1174.6423)" fill="#FFFF00" width="1.003" height="1.001"/>
	<rect x="1596.822" y="353.195" transform="matrix(0.2087 0.978 -0.978 0.2087 1609.9296 -1282.2701)" fill="#FFFF00" width="1.002" height="1.001"/>
	<rect x="1573.22" y="353.159" transform="matrix(0.4877 0.873 -0.873 0.4877 1114.9447 -1192.6895)" fill="#FFFF00" width="1.001" height="1.002"/>
	<rect x="1555.012" y="353.224" transform="matrix(0.6059 0.7955 -0.7955 0.6059 894.4373 -1098.0748)" fill="#FFFF00" width="1.004" height="1.002"/>
	<rect x="1539.626" y="353.16" transform="matrix(0.6608 0.7505 -0.7505 0.6608 787.7676 -1035.9509)" fill="#FFFF00" width="1.002" height="1.002"/>
	<rect x="1530.949" y="353.159" transform="matrix(0.3979 0.9174 -0.9174 0.3979 1246.5321 -1192.0555)" fill="#FFFF00" width="1.004" height="1.003"/>
	<rect x="1534.853" y="353.236" transform="matrix(0.3958 0.9183 -0.9183 0.3958 1252.5082 -1196.2445)" fill="#FFFF00" width="1.002" height="1"/>
	<line fill="none" stroke="#FFFF00" stroke-width="1.0022" stroke-linejoin="round" stroke-miterlimit="10" x1="1530.976" y1="374.004" x2="1530.976" y2="373.002"/>
	<line fill="none" stroke="#FFFF00" stroke-width="1.0022" stroke-linejoin="round" stroke-miterlimit="10" x1="1530.976" y1="385.342" x2="1530.976" y2="384.34"/>
	<rect x="1452.496" y="353.243" transform="matrix(0.7071 0.7071 -0.7071 0.7071 675.7082 -923.8141)" fill="#FFFF00" width="1" height="1.004"/>
	<rect x="1280.356" y="378.684" transform="matrix(0.7071 0.7071 -0.7071 0.7071 643.2783 -794.6426)" fill="#FFFF00" width="1.003" height="1.001"/>
	<rect x="1280.388" y="378.586" transform="matrix(-0.9947 0.1033 -0.1033 -0.9947 2594.0764 623.8769)" fill="#FFFF00" width="1.002" height="1.001"/>
	<path fill="none" stroke="#FFFF00" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1359.027,346.01"/>
	<path fill="none" stroke="#FFFF00" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1359.027,340.34"/>
	<path fill="none" stroke="#FFFF00" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1359.027,342.889"/>
	<path fill="none" stroke="#FFFF00" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1362.062,340.055"/>
	<path fill="none" stroke="#FFFF00" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1362.062,342.889"/>
	<rect x="1035.452" y="292.782" transform="matrix(0.7076 0.7066 -0.7066 0.7076 510.1586 -646.2664)" fill="#FFFF00" width="1.002" height="1.003"/>
	<rect x="1578.731" y="353.248" transform="matrix(0.7071 0.7071 -0.7071 0.7071 712.6852 -1013.0748)" fill="#FFFF00" width="1.003" height="1.003"/>
	<rect x="959.432" y="190.213" transform="matrix(-0.7076 0.7066 -0.7066 -0.7076 1773.9377 -352.6467)" fill="#FFFF00" width="1.003" height="1.001"/>
	<rect x="952.989" y="196.724" transform="matrix(-0.7061 0.7081 -0.7081 -0.7061 1766.4303 -338.6563)" fill="#FFFF00" width="1.001" height="1.002"/>
	<rect x="954.913" y="134.068" transform="matrix(0.5569 0.8305 -0.8305 0.5569 535.0683 -733.8972)" fill="#FFFF00" width="1.003" height="1.004"/>
	<rect x="966.338" y="162.057" transform="matrix(-0.7066 0.7076 -0.7076 -0.7066 1765.0525 -406.7048)" fill="#FFFF00" width="1.003" height="1.002"/>
	<rect x="952.921" y="219.396" transform="matrix(0.7076 0.7066 -0.7066 0.7076 434.17 -609.4072)" fill="#FFFF00" width="1.002" height="1.002"/>
	<rect x="952.924" y="289.327" transform="matrix(0.7076 0.7066 -0.7066 0.7076 483.5854 -588.9606)" fill="#FFFF00" width="1.002" height="1.003"/>
	<rect x="952.929" y="196.712" transform="matrix(0.7066 0.7076 -0.7076 0.7066 419.265 -616.7827)" fill="#FFFF00" width="1.001" height="1.001"/>
	<rect x="952.986" y="289.424" transform="matrix(-0.7061 0.7081 -0.7081 -0.7061 1832.0637 -180.4952)" fill="#FFFF00" width="1.001" height="1.003"/>
	<rect x="959.551" y="289.361" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1843.8743 -184.0346)" fill="#FFFF00" width="1.003" height="1.002"/>
	<rect x="342.666" y="177.86" transform="matrix(-0.7076 0.7066 -0.7066 -0.7076 712.0228 62.0801)" fill="#FFFF00" width="1.002" height="1.003"/>
	<path fill="none" stroke="#FFFF00" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M341.59,291.748h1.576"/>
	<rect x="342.634" y="291.254" transform="matrix(-0.7076 0.7066 -0.7066 -0.7076 792.0947 255.7327)" fill="#FFFF00" width="1.002" height="1.002"/>
	<path fill="none" stroke="#FFFF00" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1362.812,350.827"/>
	<path fill="none" stroke="#966432" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1492.955,363.014c1.564,0,2.834-1.268,2.834-2.834 M1490.119,360.18c0,1.566,1.271,2.834,2.836,2.834 M1492.955,357.346
		c-1.565,0-2.836,1.271-2.836,2.834 M1495.789,360.18c0-1.563-1.27-2.834-2.834-2.834"/>
	<path fill="none" stroke="#966432" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1529.041,609.174c1.564,0,2.834-1.269,2.834-2.834 M1526.207,606.34c0,1.565,1.271,2.834,2.834,2.834 M1529.041,603.506
		c-1.563,0-2.834,1.27-2.834,2.834 M1531.875,606.34c0-1.564-1.27-2.834-2.834-2.834"/>
	<line fill="none" stroke="#966432" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1529.041" y1="606.34" x2="1503.67" y2="606.34"/>
	<path fill="none" stroke="#966432" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1028.377,201.575c1.563,0,2.834-1.27,2.834-2.836 M1025.543,198.74c0,1.566,1.27,2.836,2.834,2.836 M1028.377,195.905
		c-1.566,0-2.837,1.27-2.837,2.834 M1031.211,198.74c0-1.564-1.271-2.834-2.834-2.834"/>
	<line fill="none" stroke="#575757" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="358.981" y1="133.151" x2="358.981" y2="144.962"/>
	<path fill="none" stroke="#575757" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M358.981,144.962"/>
	<rect x="800.991" y="157.137" transform="matrix(0.7091 0.7052 -0.7052 0.7091 344.1023 -519.2088)" width="0.501" height="0.501"/>
	<rect x="1035.73" y="113.233" transform="matrix(-0.7081 0.7061 -0.7061 -0.7081 1849.6724 -537.7012)" width="0.502" height="0.501"/>
	<rect x="1327.152" y="113.241" transform="matrix(-0.7081 0.7061 -0.7061 -0.7081 2347.449 -743.4715)" width="0.502" height="0.501"/>
	<rect x="1247.765" y="274.312" transform="matrix(0.7071 0.7071 -0.7071 0.7071 559.6799 -802.0624)" width="0.5" height="0.501"/>
	<rect x="1327.149" y="160.978" transform="matrix(-0.7081 0.7061 -0.7061 -0.7081 2381.1528 -661.9304)" width="0.502" height="0.501"/>
	<rect x="1069.736" y="274.312" transform="matrix(0.7061 0.7081 -0.7081 0.7061 508.8485 -676.9522)" width="0.501" height="0.501"/>
	<rect x="1035.693" y="160.924" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1882.4331 -457.3802)" width="0.5" height="0.502"/>
	<rect x="1147.342" y="81.07" transform="matrix(0.709 0.7052 -0.7052 0.709 391.2405 -785.5781)" width="0.503" height="0.502"/>
	<rect x="1215.371" y="81.072" transform="matrix(0.71 0.7042 -0.7042 0.71 409.7739 -832.4379)" width="0.502" height="0.503"/>
	<rect x="1415.009" y="126.793" transform="matrix(0.7061 0.7081 -0.7081 0.7061 505.8583 -964.7844)" width="0.501" height="0.501"/>
	<rect x="1335.637" y="240.218" transform="matrix(-0.7081 0.7061 -0.7061 -0.7081 2451.605 -532.5773)" width="0.502" height="0.501"/>
	<rect x="1510.406" y="339.773" transform="matrix(0.7061 0.7081 -0.7081 0.7061 684.6994 -969.7451)" width="0.501" height="0.5"/>
	<line fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1564.219" y1="502.309" x2="1470.334" y2="502.309"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1564.617,502.705c0-0.219-0.18-0.396-0.397-0.396"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1547.607,507.524c0-0.219-0.178-0.396-0.396-0.396"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1564.219,540.293h-93.885 M1548.185,506.965l16.061-4.267"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1564.219,540.293c0.219,0,0.396-0.179,0.396-0.396"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1547.211,535.475c0.219,0,0.395-0.178,0.395-0.396"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1547.607,507.524v27.553 M1564.617,502.705v37.191 M1548.185,535.535l16.061,4.267"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1517.277,519.174c-1.176,0-2.125,0.953-2.125,2.127 M1519.404,521.301c0-1.174-0.953-2.127-2.127-2.127 M1517.277,523.426
		c1.174,0,2.127-0.951,2.127-2.125 M1515.152,521.301c0,1.174,0.949,2.125,2.125,2.125"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1470.424,502.309c-0.22,0-0.396,0.178-0.396,0.396"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1487.431,507.127c-0.219,0-0.396,0.178-0.396,0.396"/>
	<line fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1486.459" y1="506.965" x2="1470.396" y2="502.699"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1470.027,539.897c0,0.219,0.178,0.395,0.396,0.395"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1487.035,535.076c0,0.222,0.179,0.396,0.396,0.396"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1547.211,535.475h-59.779 M1547.211,507.127h-59.779 M1487.035,507.524v27.553 M1470.027,502.705v37.191 M1486.459,535.535
		l-16.063,4.267"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1514.16,624.076c-1.174,0-2.127,0.951-2.127,2.127 M1516.285,626.203c0-1.176-0.951-2.127-2.125-2.127 M1514.16,628.328
		c1.174,0,2.125-0.95,2.125-2.125 M1512.033,626.203c0,1.175,0.953,2.125,2.127,2.125"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1519.828,632.326c0.313,0,0.566-0.254,0.566-0.565"/>
	<line fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1519.828" y1="632.326" x2="1469.373" y2="632.326"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1468.804,631.76c0,0.313,0.254,0.565,0.565,0.565"/>
	<line fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1520.394" y1="558.739" x2="1520.394" y2="631.76"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1520.394,558.739c0-0.313-0.254-0.565-0.566-0.565"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1468.804,558.739v73.021 M1519.828,558.172h-50.455"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1469.373,558.172c-0.313,0-0.567,0.252-0.567,0.565"/>
	<polyline fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		1466.254,555.317 1522.947,555.317 1522.947,634.688 1466.254,634.688 1466.254,555.317 "/>
	<polyline fill="none" stroke="#966432" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		1517.277,521.301 1500.269,521.301 1491.709,529.862 "/>
	<path fill="none" stroke="#000000" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1346.172,623.442v6.234 M1364.467,623.442v6.234 M1335.476,623.442v6.234 M1355.32,623.442v6.234"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1374.22,623.725c-1.564,0-2.834,1.27-2.834,2.834 M1377.056,626.559c0-1.564-1.271-2.834-2.836-2.834 M1374.22,629.395
		c1.565,0,2.836-1.27,2.836-2.836 M1371.386,626.559c0,1.566,1.27,2.836,2.834,2.836"/>
	<path fill="none" stroke="#494949" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M858.789,516.088v-5.67 M858.789,524.592v-5.67 M858.789,533.096v-5.67 M858.789,541.6v-5.67 M858.789,550.104v-5.67
		M858.789,558.608v-5.67 M858.789,567.112v-5.67 M858.789,575.615v-5.67 M858.789,584.119v-5.67 M858.789,592.623v-5.67
		M858.789,601.127v-5.67 M858.789,609.631v-5.67 M858.789,618.135v-5.67 M858.789,623.795v-2.824"/>
	<rect x="858.529" y="623.466" transform="matrix(0.7052 0.7091 -0.7091 0.7052 695.4526 -425.0206)" fill="#494949" width="0.501" height="0.5"/>
	<rect x="858.516" y="509.301" transform="matrix(0.7091 0.7052 -0.7052 0.7091 609.1704 -457.3111)" fill="#494949" width="0.501" height="0.503"/>
	<path fill="none" stroke="#966432" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M762.881,639.68l-0.136,0.678 M768.348,640.694l0.067-0.338l-0.217-1.084l-0.613-0.922l-0.92-0.613l-1.085-0.215l-0.884,0.176
		M762.746,640.356l0.217,1.086l0.614,0.918l0.919,0.613l1.085,0.217l1.085-0.217"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M902.849,534.231h5.668 M894.345,534.231h5.668 M885.842,534.231h5.668 M877.338,534.231h5.668 M868.834,534.231h5.668
		M860.33,534.231H866 M854.252,534.231h3.243 M854.252,501.35V484.91 M854.252,484.908h56.692v16.439 M854.252,484.908v-16.44
		M854.252,468.467h56.692v16.44 M854.252,468.467v-16.439 M854.252,452.028h56.692v16.439 M854.252,452.028v-16.44 M854.252,435.586
		h56.692v16.44 M854.252,435.586v-16.439 M854.252,419.147h56.692v16.439 M854.252,419.147v-16.44 M854.252,402.704h56.692v16.44"/>
	<rect x="854.078" y="533.978" transform="matrix(-0.7061 0.7081 -0.7081 -0.7061 1835.8726 306.5305)" width="0.501" height="0.501"/>
	<rect x="910.699" y="534" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1932.8608 267.8833)" width="0.503" height="0.5"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M910.945,541.6v-5.67 M910.945,550.104v-5.67"/>
	<rect x="910.675" y="550.427" transform="matrix(0.7071 0.7071 -0.7071 0.7071 656.1915 -482.8324)" width="0.503" height="0.501"/>
	<rect x="910.674" y="533.986" transform="matrix(0.7071 0.7071 -0.7071 0.7071 644.5659 -487.6475)" width="0.503" height="0.501"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M854.252,541.6v-5.67 M854.252,550.104v-5.67"/>
	<rect x="853.984" y="550.426" transform="matrix(0.7071 0.7071 -0.7071 0.7071 639.5865 -442.7453)" width="0.5" height="0.501"/>
	<rect x="853.97" y="533.912" transform="matrix(0.7042 0.71 -0.71 0.7042 631.9691 -448.5008)" width="0.501" height="0.5"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M902.849,550.672h5.668 M894.345,550.672h5.668 M885.842,550.672h5.668 M877.338,550.672h5.668 M868.834,550.672h5.668
		M860.33,550.672H866 M854.252,550.672h3.243"/>
	<rect x="854.079" y="550.423" transform="matrix(-0.7042 0.71 -0.71 -0.7042 1846.918 331.846)" width="0.501" height="0.5"/>
	<rect x="910.777" y="550.427" transform="matrix(-0.7091 0.7052 -0.7052 -0.7091 1945.3065 298.7182)" width="0.501" height="0.5"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M910.945,558.608v-5.67 M910.945,562.34v-0.896"/>
	<rect x="910.674" y="562.093" transform="matrix(0.7061 0.7081 -0.7081 0.7061 665.8702 -479.7526)" width="0.503" height="0.501"/>
	<rect x="910.675" y="550.427" transform="matrix(0.7071 0.7071 -0.7071 0.7071 656.1915 -482.8324)" width="0.503" height="0.501"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M854.252,558.608v-5.67 M854.252,567.112v-5.67 M854.252,571.887v-1.939"/>
	<rect x="854.019" y="571.634" transform="matrix(0.7071 0.7071 -0.7071 0.7071 654.5928 -436.5587)" width="0.5" height="0.5"/>
	<rect x="853.984" y="550.426" transform="matrix(0.7071 0.7071 -0.7071 0.7071 639.5865 -442.7453)" width="0.5" height="0.501"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M907.211,562.969l3.733-0.629 M898.826,564.381l5.59-0.941 M890.439,565.793l5.592-0.941 M882.052,567.205l5.592-0.94
		M873.668,568.617l5.59-0.94 M865.283,570.03l5.59-0.94 M856.896,571.442l5.592-0.94"/>
	<rect x="853.996" y="571.56" transform="matrix(-0.5805 0.8143 -0.8143 -0.5805 1815.7388 208.1676)" width="0.501" height="0.501"/>
	<rect x="910.687" y="562.009" transform="matrix(-0.5792 0.8152 -0.8152 -0.5792 1896.9028 145.3465)" width="0.501" height="0.501"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M910.945,567.112v-4.771 M910.945,571.192v-1.246"/>
	<rect x="910.713" y="570.937" transform="matrix(0.7071 0.7071 -0.7071 0.7071 670.7053 -476.8513)" width="0.5" height="0.501"/>
	<rect x="910.674" y="562.093" transform="matrix(0.7061 0.7081 -0.7081 0.7061 665.8702 -479.7526)" width="0.503" height="0.501"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M854.252,575.615v-3.729 M854.252,584.119v-5.67 M854.252,592.623v-5.67 M854.252,596.496v-1.039"/>
	<rect x="853.972" y="596.172" transform="matrix(0.7052 0.7091 -0.7091 0.7052 674.7572 -429.8364)" width="0.501" height="0.501"/>
	<rect x="854.019" y="571.634" transform="matrix(0.7071 0.7071 -0.7071 0.7071 654.5928 -436.5587)" width="0.5" height="0.5"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M904.39,574.115l5.178-2.311 M896.627,577.582l5.176-2.311 M888.861,581.049l5.176-2.312 M881.095,584.514l5.176-2.312
		M873.33,587.981l5.179-2.313 M865.564,591.448l5.178-2.313 M857.799,594.912l5.176-2.311 M854.252,596.496l0.959-0.428"/>
	<rect x="853.928" y="596.255" transform="matrix(-0.3575 0.9339 -0.9339 -0.3575 1716.6547 12.0632)" width="0.5" height="0.5"/>
	<rect x="910.647" y="570.909" transform="matrix(-0.3592 0.9332 -0.9332 -0.3592 1771.1599 -73.7477)" width="0.5" height="0.501"/>
	<line fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="910.945" y1="575.615" x2="910.945" y2="571.192"/>
	<rect x="910.676" y="576.615" transform="matrix(0.7071 0.7071 -0.7071 0.7071 674.7095 -475.162)" width="0.5" height="0.501"/>
	<rect x="910.713" y="570.937" transform="matrix(0.7071 0.7071 -0.7071 0.7071 670.7053 -476.8513)" width="0.5" height="0.501"/>
	<line fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="911.353" y1="576.86" x2="914.914" y2="576.86"/>
	<rect x="910.698" y="576.629" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1963.0006 340.6568)" width="0.5" height="0.5"/>
	<rect x="914.663" y="576.611" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1969.756 337.8216)" width="0.5" height="0.5"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M854.252,601.127v-4.632 M854.252,609.631v-5.67 M854.252,618.135v-5.67 M854.252,626.639v-5.668 M854.252,630.426v-0.951"/>
	<rect x="853.974" y="630.099" transform="matrix(0.7052 0.7091 -0.7091 0.7052 698.8135 -419.8352)" width="0.501" height="0.5"/>
	<rect x="853.972" y="596.172" transform="matrix(0.7052 0.7091 -0.7091 0.7052 674.7572 -429.8364)" width="0.501" height="0.501"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M909.853,581.328l4.25-3.752 M903.478,586.957l4.25-3.754 M897.105,592.586l4.25-3.755 M890.73,598.215l4.25-3.754 M884.357,603.844
		l4.25-3.754 M877.982,609.473l4.25-3.754 M871.607,615.102l4.25-3.754 M865.232,620.731l4.25-3.754 M858.859,626.358l4.25-3.752
		M854.252,630.426l2.481-2.189"/>
	<rect x="854.002" y="630.175" transform="matrix(-0.0624 0.9981 -0.9981 -0.0624 1536.7368 -182.8392)" width="0.501" height="0.501"/>
	<rect x="914.664" y="576.61" transform="matrix(-0.0624 0.9981 -0.9981 -0.0624 1547.7229 -300.2889)" width="0.501" height="0.501"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M919.857,576.86h0.371 M914.914,576.86h2.109"/>
	<rect x="914.663" y="576.611" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1969.756 337.8216)" width="0.5" height="0.5"/>
	<rect x="920.06" y="576.607" transform="matrix(-0.7052 0.7091 -0.7091 -0.7052 1978.2957 331.084)" width="0.501" height="0.501"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M885.842,633.553h5.53 M877.338,633.553h5.668 M868.834,633.553h5.668 M860.33,633.553H866 M854.252,633.553h3.243"/>
	<rect x="854" y="633.285" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1906.2727 477.4668)" width="0.5" height="0.5"/>
	<rect x="891.195" y="633.256" transform="matrix(-0.7091 0.7052 -0.7052 -0.7091 1970.2473 454.0859)" width="0.501" height="0.5"/>
	<line fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="854.252" y1="633.553" x2="854.252" y2="630.426"/>
	<rect x="853.983" y="633.306" transform="matrix(0.7071 0.7071 -0.7071 0.7071 698.1908 -418.4698)" width="0.5" height="0.5"/>
	<rect x="853.974" y="630.099" transform="matrix(0.7052 0.7091 -0.7091 0.7052 698.8135 -419.8352)" width="0.501" height="0.5"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M917.291,582.633l2.572-5.054 M913.433,590.211l2.57-5.054 M909.576,597.789l2.572-5.053 M905.719,605.369l2.572-5.053
		M901.861,612.948l2.57-5.053 M898.002,620.526l2.571-5.053 M894.144,628.104l2.572-5.053 M891.373,633.553l1.486-2.922"/>
	<rect x="891.069" y="633.297" transform="matrix(0.3116 0.9502 -0.9502 0.3116 1215.6448 -410.7928)" width="0.502" height="0.502"/>
	<rect x="919.926" y="576.609" transform="matrix(0.311 0.9504 -0.9504 0.311 1182.2821 -477.0826)" width="0.502" height="0.501"/>
	<line fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="920.228" y1="576.86" x2="925.527" y2="576.86"/>
	<rect x="920.06" y="576.607" transform="matrix(-0.7052 0.7091 -0.7091 -0.7052 1978.2957 331.084)" width="0.501" height="0.501"/>
	<rect x="926.009" y="576.61" transform="matrix(-0.7061 0.7081 -0.7081 -0.7061 1988.7842 328.3326)" width="0.501" height="0.501"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M911.353,633.553h5.67 M902.849,633.553h5.668 M894.345,633.553h5.668 M891.373,633.553h0.138"/>
	<rect x="891.195" y="633.256" transform="matrix(-0.7091 0.7052 -0.7052 -0.7091 1970.2473 454.0859)" width="0.501" height="0.5"/>
	<rect x="918.375" y="633.347" transform="matrix(-0.71 0.7042 -0.7042 -0.71 2017.0385 436.6088)" width="0.499" height="0.5"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M925.347,583.621l0.758-5.618 M924.211,592.049l0.756-5.619 M923.074,600.477l0.758-5.617 M921.937,608.905l0.758-5.619
		M920.801,617.332l0.758-5.618 M919.664,625.76l0.759-5.619 M918.613,633.553l0.672-4.982"/>
	<rect x="918.305" y="633.317" transform="matrix(0.6059 0.7955 -0.7955 0.6059 865.9957 -481.0573)" width="0.5" height="0.502"/>
	<rect x="925.967" y="576.533" transform="matrix(0.6075 0.7944 -0.7944 0.6075 821.7589 -509.3325)" width="0.502" height="0.5"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M936.865,576.86h2.013 M928.361,576.86h5.67"/>
	<rect x="926.009" y="576.61" transform="matrix(-0.7061 0.7081 -0.7081 -0.7061 1988.7842 328.3326)" width="0.501" height="0.501"/>
	<rect x="938.708" y="576.594" transform="matrix(-0.71 0.7042 -0.7042 -0.71 2011.8427 325.228)" width="0.501" height="0.5"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M938.877,584.119v-5.67 M938.877,592.623v-5.67 M938.877,601.127v-5.67 M938.877,609.631v-5.67 M938.877,618.135v-5.67
		M938.877,626.639v-5.668 M938.877,633.553v-4.078"/>
	<rect x="938.613" y="633.219" transform="matrix(0.7052 0.7091 -0.7091 0.7052 725.981 -478.9285)" width="0.501" height="0.5"/>
	<rect x="938.627" y="576.61" transform="matrix(0.7071 0.7071 -0.7071 0.7071 682.892 -494.9279)" width="0.5" height="0.5"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M936.865,633.553h2.013 M928.361,633.553h5.67 M919.857,633.553h5.67"/>
	<rect x="918.375" y="633.347" transform="matrix(-0.71 0.7042 -0.7042 -0.71 2017.0385 436.6088)" width="0.499" height="0.5"/>
	<rect x="938.618" y="633.264" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 2050.7097 417.596)" width="0.5" height="0.5"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M854.252,524.592v-5.67 M854.252,533.096v-5.67 M910.945,531.28v-13.487h-46.516"/>
	<rect x="853.97" y="533.912" transform="matrix(0.7042 0.71 -0.71 0.7042 631.9691 -448.5008)" width="0.501" height="0.5"/>
	<rect x="853.962" y="517.546" transform="matrix(0.7091 0.7051 -0.7051 0.7091 613.6442 -451.6969)" width="0.499" height="0.501"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M902.849,534.231h5.668 M894.345,534.231h5.668 M885.842,534.231h5.668 M877.338,534.231h5.668 M868.834,534.231h5.668
		M860.33,534.231H866 M854.252,534.231h3.243"/>
	<rect x="854.078" y="533.978" transform="matrix(-0.7061 0.7081 -0.7081 -0.7061 1835.8726 306.5305)" width="0.501" height="0.501"/>
	<rect x="910.699" y="534" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1932.8608 267.8833)" width="0.503" height="0.5"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M910.945,517.791V501.35 M910.945,501.35h-56.692v13.488"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M882.599,576.86c0,15.654,12.69,28.348,28.346,28.348"/>
	<line fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="910.945" y1="605.207" x2="938.877" y2="605.207"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M882.599,405.993c1.815,0,3.287-1.474,3.287-3.289 M879.31,402.704c0,1.815,1.473,3.289,3.289,3.289 M882.599,399.417
		c-1.816,0-3.289,1.471-3.289,3.287 M885.886,402.704c0-1.816-1.472-3.287-3.287-3.287"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M882.599,526.01v50.852 M882.599,402.704v120.354 M854.252,514.838l56.692,16.44 M854.252,517.791l56.692,16.439 M938.877,605.207
		l-6.576-6.577v13.153L938.877,605.207"/>
	<path fill="none" stroke="#494949" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M936.035,628.901l5.668-0.008 M927.531,628.912l5.668-0.008 M919.027,628.926l5.668-0.01 M910.521,628.938l5.67-0.008
		M902.017,628.949l5.67-0.008 M893.513,628.961l5.67-0.009 M885.01,628.973l5.67-0.008 M876.506,628.987l5.67-0.01 M868.002,628.998
		l5.67-0.008 M859.5,629.01l5.669-0.008"/>
	<rect x="858.618" y="628.763" transform="matrix(-0.7042 0.71 -0.71 -0.7042 1910.2786 462.1301)" fill="#494949" width="0.501" height="0.501"/>
	<rect x="942.616" y="628.684" transform="matrix(-0.7052 0.7091 -0.7091 -0.7052 2053.6829 403.8902)" fill="#494949" width="0.501" height="0.501"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M356.773,354.227"/>
	<rect x="1374.123" y="628.047" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 2790.4741 100.7419)" fill="#0000FF" width="0.5" height="0.5"/>
	<rect x="1633.365" y="418.409" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 3084.792 -440.4439)" fill="#FF0000" width="0.5" height="0.5"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1635.713,418.655l0.127,48.301 M1633.615,418.659l0.127,48.303 M1637.81,418.649l0.127,48.301 M1637.949,471.147l-0.012-4.195
		l-4.195,0.013l0.013,4.194L1637.949,471.147 M1637.799,414.454l0.012,4.194l-4.195,0.011l-0.012-4.195L1637.799,414.454
		M1637.875,442.799"/>
	<rect x="1623.336" y="222.949" transform="matrix(-0.7081 0.7061 -0.7061 -0.7081 2930.8223 -765.2267)" width="0.502" height="0.501"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M852.836,402.704h162.709 M1635.916,495.473l0.164,62.477 M1633.818,495.479l0.162,62.477 M1638.011,495.467l0.164,62.477
		M1638.187,562.137l-0.012-4.193l-4.193,0.012l0.01,4.195L1638.187,562.137 M1638,491.272l0.013,4.193l-4.193,0.012l-0.014-4.193
		L1638,491.272"/>
	<line fill="none" stroke="#494949" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="858.789" y1="402.704" x2="858.789" y2="509.623"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1635.209,226.918l0.133,50.399 M1635.064,172.323l0.133,50.4 M1632.967,172.329l0.274,104.994 M1637.162,172.318l0.275,104.995
		M1637.449,281.508l-0.012-4.195l-4.193,0.011l0.013,4.195L1637.449,281.508 M1637.465,222.717l0.01,4.195l-4.535,0.012l-0.01-4.195
		L1637.465,222.717 M1637.152,168.123l0.01,4.194l-4.194,0.012l-0.011-4.195L1637.152,168.123"/>
	<rect x="852.58" y="420.427" transform="matrix(-0.7061 0.7081 -0.7081 -0.7061 1752.9143 113.8604)" fill="#008000" width="0.501" height="0.503"/>
	<rect x="918.698" y="420.403" transform="matrix(-0.7081 0.7061 -0.7061 -0.7081 1866.6753 69.6127)" fill="#008000" width="0.501" height="0.501"/>
	<rect x="918.597" y="568.485" transform="matrix(0.7052 0.7091 -0.7091 0.7052 674.1794 -483.8229)" fill="#008000" width="0.501" height="0.5"/>
	<rect x="918.59" y="420.379" transform="matrix(0.7052 0.7091 -0.7091 0.7052 569.1622 -527.4863)" fill="#008000" width="0.501" height="0.5"/>
	<rect x="918.713" y="568.557" transform="matrix(-0.7052 0.7091 -0.7091 -0.7052 1970.2909 318.313)" fill="#008000" width="0.501" height="0.501"/>
	<rect x="938.646" y="634.346" transform="matrix(0.7091 0.7052 -0.7052 0.7091 720.6604 -477.434)" fill="#008000" width="0.501" height="0.503"/>
	<rect x="578.792" y="509.713" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1349.0857 461.1177)" width="0.5" height="0.501"/>
	<rect x="579.859" y="356.566" transform="matrix(-0.7076 0.7066 -0.7066 -0.7076 1243.3311 199.6299)" fill="#FFFF00" width="1.003" height="1.002"/>
	<rect x="595.09" y="346.36" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1262.0061 170.9821)" fill="#FFFF00" width="1.003" height="1.002"/>
	<path fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1022.347,634.688H830.291 M830.291,633.78V369.143"/>
	<path fill="none" stroke="#966432" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M811.014,631.854c1.564,0,2.834-1.271,2.834-2.836 M808.179,629.018c0,1.564,1.27,2.836,2.835,2.836 M811.014,626.182
		c-1.565,0-2.835,1.271-2.835,2.836 M813.849,629.018c0-1.564-1.27-2.836-2.834-2.836"/>
	<rect x="810.824" y="628.412" transform="matrix(-0.7052 0.7091 -0.7091 -0.7052 1828.7629 496.8734)" fill="#966432" width="0.501" height="0.501"/>
	<line fill="none" stroke="#A0A0A0" stroke-width="0.5102" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="830.291" y1="464.639" x2="830.291" y2="464.639"/>
	<rect x="915.302" y="461.364" transform="matrix(-0.6894 0.7244 -0.7244 -0.6894 1881.707 116.8353)" fill="#FFFF00" width="1.003" height="1.002"/>
	<line fill="none" stroke="#A0A0A0" stroke-width="0.5102" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="830.498" y1="464.082" x2="830.291" y2="464.104"/>
	<rect x="597.291" y="126.597" transform="matrix(0.7076 0.7066 -0.7066 0.7076 264.6085 -385.2467)" fill="#FFFF00" width="1.002" height="1.003"/>
	<rect x="820.329" y="126.507" transform="matrix(0.7066 0.7076 -0.7076 0.7066 330.6854 -543.5526)" fill="#FFFF00" width="1.002" height="1.001"/>
	<rect x="820.352" y="300.617" transform="matrix(0.4739 0.8806 -0.8806 0.4739 697.04 -564.4132)" fill="#FFFF00" width="1.004" height="1.001"/>
	<rect x="820.3" y="300.628" transform="matrix(-0.7066 0.7076 -0.7076 -0.7066 1613.8718 -66.8791)" fill="#FFFF00" width="1.002" height="1.003"/>
	<rect x="820.402" y="126.518" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1491.1858 -363.6298)" fill="#FFFF00" width="1.003" height="1.003"/>
	<rect x="597.523" y="113.204" transform="matrix(-0.7081 0.7061 -0.7061 -0.7081 1101.1594 -228.318)" width="0.501" height="0.501"/>
	<rect x="820.609" y="113.203" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1481.5195 -386.7587)" width="0.501" height="0.501"/>
	<rect x="707.248" y="292.346" transform="matrix(0.7061 0.7081 -0.7081 0.7061 415.0941 -414.9813)" width="0.501" height="0.501"/>
	<rect x="820.616" y="213.001" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1552.0989 -216.398)" width="0.501" height="0.501"/>
	<line fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="820.859" y1="203.316" x2="770.12" y2="203.316"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M770.12,203.316L770.12,203.316 M820.859,152.576c-28.022,0-50.74,22.717-50.74,50.739"/>
	<path fill="none" stroke="#FFFF00" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M820.859,133.411v-5.669 M820.859,141.915v-5.669 M820.859,150.419v-5.669"/>
	<rect x="820.33" y="152.021" transform="matrix(0.7071 0.7071 -0.7071 0.7071 348.2656 -535.743)" fill="#FFFF00" width="1.003" height="1.002"/>
	<rect x="820.329" y="126.507" transform="matrix(0.7066 0.7076 -0.7076 0.7066 330.6854 -543.5526)" fill="#FFFF00" width="1.002" height="1.001"/>
	<rect x="631.084" y="292.353" transform="matrix(0.7071 0.7071 -0.7071 0.7071 391.8153 -360.7192)" width="0.5" height="0.501"/>
	<rect x="597.056" y="178.971" transform="matrix(-0.7081 0.7061 -0.7061 -0.7081 1146.8008 -115.6513)" width="0.501" height="0.501"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M866.293,115.551h39.797 M866.293,117.649h39.797 M866.293,113.454h39.797 M910.287,113.454h-4.194v4.194h4.194V113.454
		M862.097,113.454h4.195v4.194h-4.195V113.454"/>
	<line fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="815.873" y1="369.143" x2="830.291" y2="369.143"/>
	<path fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M815.849,453.663"/>
	<path fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M830.291,453.639"/>
	<path fill="none" stroke="#A0A0A0" stroke-width="0.5102" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M815.849,464.663L815.849,464.663"/>
	<path fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M815.873,369.143l-0.023,95.521"/>
	<path fill="none" stroke="#A0A0A0" stroke-width="0.5102" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M830.498,464.082 M815.972,464.076 M815.765,464.096 M830.291,464.104"/>
	<line fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="815.764" y1="464.096" x2="815.55" y2="634.688"/>
	<rect x="595.128" y="352.834" transform="matrix(-0.7061 0.7081 -0.7081 -0.7061 1266.4102 181.0817)" fill="#FFFF00" width="1.001" height="1.002"/>
	<path fill="#FFFFFF" stroke="#000000" d="M342.599,332.056v45.638 M342.599,282.223v45.638 M342.599,232.39v45.638 M342.599,182.557
		v45.638 M344.696,182.557v195.137 M340.502,182.557v195.137 M340.502,381.889v-4.193h4.194v4.193H340.502 M340.502,327.861v4.194
		h4.194v-4.194H340.502 M340.502,278.028v4.195h4.194v-4.195H340.502 M340.502,228.195v4.195h4.194v-4.195H340.502 M340.502,178.362
		v4.195h4.194v-4.195H340.502"/>
	<line fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="581.582" y1="634.688" x2="355.808" y2="634.688"/>
	<line fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1021.213" y1="127.06" x2="1021.213" y2="293.282"/>
	<rect x="1020.722" y="292.799" transform="matrix(-0.7076 0.7066 -0.7066 -0.7076 1951.0867 -220.7799)" fill="#FFFF00" width="1.003" height="1.003"/>
	<rect x="1035.514" y="292.735" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1975.9385 -231.9874)" fill="#FFFF00" width="1.003" height="1.003"/>
	<rect x="1020.774" y="292.833" transform="matrix(0.7061 0.7081 -0.7081 0.7061 507.826 -636.9436)" fill="#FFFF00" width="1.001" height="1.003"/>
	<rect x="1020.722" y="292.799" transform="matrix(-0.7076 0.7066 -0.7066 -0.7076 1951.0867 -220.7799)" fill="#FFFF00" width="1.003" height="1.003"/>
	<rect x="1035.514" y="292.735" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1975.9385 -231.9874)" fill="#FFFF00" width="1.003" height="1.003"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1150.814,652.432h-48.303 M1150.814,650.334h-48.303 M1150.814,654.53h-48.303 M1098.316,654.53h4.195v-4.194h-4.195V654.53
		M1155.01,654.53h-4.195v-4.194h4.195V654.53"/>
	<rect x="1386.367" y="500.278" transform="matrix(-0.7076 0.7066 -0.7066 -0.7076 2722.0679 -124.861)" fill="#FFFF00" width="1.003" height="1.003"/>
	<path fill="none" stroke="#966432" stroke-width="1.4173" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1381.199,629.018c1.564,0,2.834-1.271,2.834-2.836 M1378.365,626.182c0,1.565,1.269,2.836,2.834,2.836 M1381.199,623.348
		c-1.565,0-2.834,1.27-2.834,2.834 M1384.033,626.182c0-1.564-1.27-2.834-2.834-2.834"/>
	<rect x="1380.864" y="625.845" transform="matrix(0.7042 0.71 -0.71 0.7042 853.1128 -795.413)" fill="#966432" width="0.501" height="0.5"/>
	<path fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1267.271,404.684v82.318 M1326.793,477.08h-45.914v-97.907 M1267.271,487.002h59.521 M1384.051,487.002v-9.923 M1326.793,487.002
		v-9.923"/>
	<line fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1267.271" y1="487.002" x2="1313.76" y2="487.002"/>
	<polyline fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		1386.869,487.002 1386.869,477.08 1384.051,477.08 "/>
	<line fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1400.474" y1="483.6" x2="1400.474" y2="477.08"/>
	<path fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1386.869,477.08v154.771 M1400.474,631.854V483.6 M1400.474,477.08h-13.605"/>
	<rect x="1400.16" y="476.902" transform="matrix(-0.7022 0.712 -0.712 -0.7022 2723.5132 -184.8293)" width="0.501" height="0.501"/>
	<rect x="1624.011" y="475.336" transform="matrix(-0.7022 0.712 -0.712 -0.7022 3103.4412 -346.8705)" width="0.501" height="0.501"/>
	<path fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M502.529,120.653v7.653 M544.481,120.653v6.407"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M506.724,115.551h33.563 M506.724,117.649h33.563 M506.724,113.454h33.563 M544.481,113.454h-4.195v4.194h4.195V113.454
		M502.529,113.454h4.194v4.194h-4.194V113.454"/>
	<path fill="#FFFFFF" stroke="#000000" d="M394.418,115.551h33.562 M394.418,117.649h33.562 M394.418,113.454h33.562
		M432.175,113.454h-4.195v4.194h4.195V113.454 M390.222,113.454h4.195v4.194h-4.195V113.454"/>
	<line fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="815.55" y1="634.688" x2="597.771" y2="634.688"/>
	<line fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="582.466" y1="308.367" x2="525.206" y2="308.367"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M582.466,365.625L582.466,365.625 M525.206,308.367c0,31.624,25.637,57.26,57.261,57.26"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M355.808,389.571h135.327h91.223"/>
	<rect x="581.964" y="365.126" transform="matrix(0.7071 0.7071 -0.7071 0.7071 429.1372 -304.7754)" fill="#FFFF00" width="1.003" height="1.001"/>
	<rect x="581.972" y="307.912" transform="matrix(0.7076 0.7066 -0.7066 0.7076 388.2496 -321.405)" fill="#FFFF00" width="1.002" height="1.003"/>
	<rect x="597.297" y="365.119" transform="matrix(0.7056 0.7086 -0.7086 0.7056 435.03 -315.9575)" fill="#FFFF00" width="1.003" height="1.001"/>
	<rect x="597.28" y="307.911" transform="matrix(0.7076 0.7066 -0.7066 0.7076 392.7253 -332.2218)" fill="#FFFF00" width="1.002" height="1.003"/>
	<line fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="544.481" y1="127.06" x2="582.467" y2="127.06"/>
	<line fill="none" stroke="#3B3B3B" stroke-width="0.9921" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1410.222" y1="353.661" x2="1624.033" y2="353.661"/>
	<path fill="none" stroke="#000000" stroke-width="0.3685" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1371.386,626.559h-43.502 M1487.377,365v18.465 M1453.361,365v18.465 M1487.377,365h-34.017v18.465 M1532.73,365v18.465
		M1498.715,365v18.465 M1532.73,365h-34.016v18.465"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M355.808,381.889V178.362 M862.097,127.06h48.188"/>
	<polyline fill="#7D797B" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		1442.021,365 1544.07,365 1544.07,353.661 1442.021,353.661 1442.021,365 "/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1109.656,274.573v8.505 M1160.394,283.078v-8.505"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1317.758,81.28v-5.356v-3.175V62.543h-272.195 M1045.562,62.543v10.205v3.175v5.356 M1364.217,286.196v-11.622h-8.504
		M1364.217,372.37v-18.707v-13.607v-3.118 M1355.713,336.938v35.433 M1355.713,283.078v3.117 M1341.824,380.874h22.396v-8.504
		M1291.084,372.37h-3.402v8.504h3.402 M1355.713,372.37h-13.89 M1341.824,380.874v-8.504 M1291.084,372.37v8.504 M1109.656,274.573
		h-73.701v8.505 M1327.367,274.573h-166.973 M1355.713,274.573h-19.843 M1160.394,283.078h195.318 M1035.955,283.078h73.701
		M1364.217,286.196h-8.504 M1355.713,336.938h8.504 M1439.715,127.06h69.731 M1327.367,81.28v32.175h18.736v-6.804V62.543h-18.736
		M1335.871,274.573V127.06h-0.282h-8.222 M1327.367,127.06v147.514"/>
	<path fill="none" stroke="#FF0000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M264.231,454.379L264.231,454.379"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1534.285,555.317h39.687v-11.34h-113.387v11.34 M1460.584,555.317h62.36 M1522.947,555.317v79.369h11.338v-79.369"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M223.147,482.854v50.457 M223.147,533.311h11.338v-50.457 M234.485,482.854h-11.338"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1624.193,414.489l0.148,56.691"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1624.394,491.307l0.188,70.867"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M829.365,152.576h-8.504 M820.859,203.316h8.503 M1623.545,168.158l0.297,113.386"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M932.396,127.06h48.188 M829.365,152.576V127.06v-0.014l-8.504-0.084 M820.859,203.316v89.292h8.503v-89.292 M820.859,126.962
		v25.614 M1045.562,62.543h-28.346V81.28 M1327.367,62.543h-9.608 M1317.758,81.28h9.608 M1035.955,81.28h9.607 M1017.217,81.28
		v25.371v6.804h18.735 M1035.955,113.454V81.28"/>
	<line fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="839.582" y1="22.858" x2="607.383" y2="22.858"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M264.231,454.379l-102.667,3.19l-49.296,1.533 M340.502,452.008l-6.804,0.211l-11.667,0.362 M322.365,463.348l11.333-0.353
		l6.804-0.211 M112.424,469.877l98.896-3.455l11.827-0.414l0.003,0.102l11.335-0.264l30.081-0.699"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M597.771,162.124v-49.679 M597.771,308.367v-7.256v-8.504v-65.206 M586.436,634.688h11.336V377.647v-8.504v-3.516 M582.466,365.625
		v50.963v206.76v11.34 M582.466,227.401v80.965 M582.466,127.06v35.064 M582.466,365.625h15.308 M597.771,308.367h-15.307
		M582.466,227.401h15.308 M597.771,162.124h-15.307 M390.222,127.06h41.953 M502.529,127.06h41.953 M607.383,22.858h-9.608
		M811.25,41.595h9.609 M597.773,41.595h9.608"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M597.771,301.111h231.591v-8.504h-8.503H597.771V301.111 M698.762,369.143h-100.99v8.504 M756.021,369.141 M597.771,377.647h100.768
		v-8.506"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M597.773,22.858h-18.737v83.792v6.804h18.737 M597.773,113.454V41.595"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M582.466,162.124v65.276 M597.771,227.401v-65.276"/>
	<path fill="none" stroke="#000000" stroke-width="0.5102" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1309.472,62.543h4.252 M1309.472,62.543h-4.252 M1309.472,72.748h4.252 M1309.472,72.748h-4.252 M1312.025,65.094l-5.104-5.102
		M1312.025,75.299l-5.104-5.104"/>
	<line fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="1309.472" y1="72.748" x2="1309.472" y2="62.543"/>
	<rect x="1549.113" y="578.569" fill="#FFFFFF" width="10.029" height="9.482"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1624.033,395.042h4.252 M1280.879,395.042h-4.252"/>
	<rect x="942.726" y="631.131" fill="#FFFFFF" width="9.242" height="5.864"/>
	<rect x="946.738" y="597.037" fill="#FFFFFF" width="9.482" height="16.226"/>
	<rect x="892.01" y="528.596" fill="#FFFFFF" width="9.604" height="18.031"/>
	<rect x="918.072" y="593.629" fill="#FFFFFF" width="9.482" height="16.239"/>
	<rect x="876.844" y="450.643" fill="#FFFFFF" width="18.031" height="9.848"/>
	<rect x="849.691" y="428.688" fill="#FFFFFF" width="7.705" height="9.604"/>
	<rect x="874.486" y="433.485" fill="#FFFFFF" width="16.228" height="9.482"/>
	<rect x="836.353" y="553.071" fill="#FFFFFF" width="11.836" height="9.604"/>
	<rect x="874.486" y="553.617" fill="#FFFFFF" width="16.228" height="9.48"/>
	<rect x="875.568" y="611.549" fill="#FFFFFF" width="18.029" height="9.848"/>
	<line fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="800.733" y1="33.063" x2="800.733" y2="22.858"/>
	<rect x="837.594" y="497.151" fill="#FFFFFF" width="9.854" height="18.029"/>
	<rect x="1570.502" y="617.612" fill="#FFFFFF" width="18.03" height="9.846"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M1624.75,628.803h4.252 M1400.474,628.803h-4.252"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M636.489,301.111v-4.252"/>
	<text id="Gästezimmer" onclick="showRoomDetails(e)" transform="matrix(1 0 0 1 366.3704 260.7249)" enable-background="new    " font-family="'ArialNarrow'" font-size="24">Gäste</text>
	<text id="HWR" onclick="showRoomDetails(e)" transform="matrix(1 0 0 1 606.3875 433.7219)" enable-background="new    " font-family="'ArialNarrow'" font-size="24">HWR</text>
	<text id="Nele" onclick="showRoomDetails(e)" transform="matrix(1 0 0 1 607.1375 92.4924)" enable-background="new    " font-family="'ArialNarrow'" font-size="24">Nele</text>
	<text id="UG_Flur" onclick="showRoomDetails(e)" transform="matrix(1 0 0 1 846.6267 295.9807)" enable-background="new    " font-family="'ArialNarrow'" font-size="24">Flur</text>
	<text id="Luise" onclick="showRoomDetails(e)" transform="matrix(1 0 0 1 1049.365 113.7395)" enable-background="new    " font-family="'ArialNarrow'" font-size="24">Luise</text>
	<text id="Lounge" onclick="showRoomDetails(e)" transform="matrix(1 0 0 1 870.3748 435.2219)" enable-background="new    " font-family="'ArialNarrow'" font-size="24">Lounge</text>
	<text id="Kinderbad" onclick="showRoomDetails(e)" transform="matrix(1 0 0 1 1429.3513 406.7258)" enable-background="new    " font-family="'ArialNarrow'" font-size="24">Kinderbad</text>
	<text id="Lotte" onclick="showRoomDetails(e)" transform="matrix(1 0 0 1 1348.8494 161.7356)" enable-background="new    " font-family="'ArialNarrow'" font-size="24">Lotte</text>
	<rect x="820.659" y="113.236" fill="#7D797B" stroke="#3B3B3B" stroke-width="0.709" stroke-linejoin="round" width="41.325" height="13.648"/>
	<rect x="910.215" y="113.506" fill="#7D797B" stroke="#3B3B3B" stroke-width="0.709" stroke-linejoin="round" width="22.319" height="13.647"/>
	<path fill="none" stroke="#000000" stroke-width="0.7087" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M936.613,115.571h39.797 M936.613,117.669h39.797 M936.613,113.474h39.797 M980.605,113.474h-4.195v4.195h4.195V113.474
		M932.418,113.474h4.194v4.195h-4.194V113.474"/>
	<rect x="980.611" y="113.373" fill="#7D797B" stroke="#3B3B3B" stroke-width="0.709" stroke-linejoin="round" width="55.537" height="13.647"/>
	</svg>

    <div v-for="room in roomModel" :key="room.id" style="position: absolute" :style="getCoordinates(room.name)">
      <svg  version="1.1" 
        :width="infoCardSize.width" 
        :height="infoCardSize.height" 
        viewBox="0 0 201 102"
        overflow="visible" 
        enable-background="new 0 0 201 102" 
        xml:space="preserve">
        <!-- xmlns="&ns_svg;" 
        xmlns:xlink="&ns_xlink;"  -->
        <g>
            <image opacity="0.51" width="201" height="102" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMkAAABmCAYAAABhjLU+AAAACXBIWXMAAAsSAAALEgHS3X78AAAA
        BGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAI3SURB
        VHja7NhNaxNRGIbhTJv0w01FaUHajUuX6m/wp7sR11oE3fhVkYqKYtPGxGfKOxKnVdyf64KHAw3d
        DNycTLrJVd3ohJasRueVEDayWbZdp1BoLZCLbF7nchzJZrab7WeH2V42FQoNBbLIPmdvs4/Zjz6U
        6doN0gdylN3PHlYoO54dDTnL3mRPsqfZu+x8uClmdYP0gTzKHmQH9XdoxXmF0XufnQ6RTOod5LBu
        kD6Qu9mNumGgFT/rPKrXjcv38vWbZK9COahApp4ZDdqajH64Gm6KIZThQzcILfvjx6qNf30IuDFA
        JCASEAmIBEQCIgGRgEgAkYBIQCQgEhAJiAREAiIBRAIiAZGASEAkIBIQCYgEEAmIBEQCIgGRgEhA
        JCASEAkgEhAJiAREAiIBkYBIQCSASEAkIBIQCYgERAIiAZEAIgGRgEhAJCASEAmIBEQCIgFEAiIB
        kYBIQCQgEhAJiAQQCYgERAIiAZGASEAkIBJAJCASEAmIBEQCIgGRgEhAJMD/R7LySOD6SPo4LrLz
        OsUCa5EMgXzNPtQpFFq0Gu3StM55dpIdZ/ey29lmDVoJpP8m9S37XhfFcoik/3CRfcpeZM+yW9md
        bMuzoxF9FF+yl9XBaXWxGm6SZRX0Kntc/3CU7Xh2NGJerxv9JfE8e52d9R90o/eT3Ww/O8xu1k3T
        eX408FVrUe/jJ3WL/P7K1V3zIj/LtusUCC2FclE3yqK+Xa0mf4mgG53QUijr56VfAgwA9npbs6AM
        6+AAAAAASUVORK5CYII=">
          </image>
          <g>
            <path fill="#FFFFFF" d="M196.857,94.286c0,1.657-1.344,3-3,3h-188c-1.657,0-3-1.343-3-3v-89c0-1.657,1.343-3,3-3h188
              c1.656,0,3,1.343,3,3V94.286z"/>
          </g>
        </g>
        <text transform="matrix(1 0 0 1 9 22.1431)" font-family="Arial" font-weight="bold" font-size="18">{{room.name}}</text>
        <line fill="none" stroke="#000000" stroke-width="0.5" x1="7.715" y1="26.429" x2="191.857" y2="26.429"/>
        <text transform="matrix(1 0 0 1 11 49.6909)" font-family="Helvetica" font-size="16">Temperatur</text>
        <text transform="matrix(1 0 0 1 137.5723 50.521)" fill="#006B33" font-family="Arial" font-weight="bold" font-size="16">{{(room.temperature / 100).toFixed(1)}} °C</text>
        <text transform="matrix(1 0 0 1 109.1016 79.2637)" font-family="Arial" font-size="16">→</text>
        <path @click="changeSetTemp(room)" fill="white" stroke="#8C8C8C" stroke-width="0.5" d="M194.134,83.471
          c0,1.484-1.171,2.688-2.612,2.688h-59.328c-1.441,0-2.612-1.203-2.612-2.688V64.654c0-1.484,1.171-2.688,2.612-2.688h59.328
          c1.441,0,2.612,1.203,2.612,2.688V83.471z"/>
        <text @click="changeSetTemp(room)" style="cursor: pointer" transform="matrix(1 0 0 1 137.2871 79.6914)" font-family="Arial" font-size="16">{{(room.setTemperatureDay / 100).toFixed(1)}} °C</text>
        <text style="cursor: default" @click="openRoomDetails(room)" transform="matrix(1 0 0 1 109.1836 50.5205)" :fill="room.enableTempControl==0 ? '#AAAAAA' : (room.heatingActive>0 ? '#00FF00' : 'black')" font-family="Arial-Bold" font-size="16">▲</text>
        </svg>
    </div>
  </pinch-zoom>
</template>

<script>

import Vue from 'vue'
import PinchZoom from 'vue-pinch-zoom';

export default Vue.extend({
	name: "UG",
  components: {
    PinchZoom
  },
	props:{
    rooms: []
	},
	data() {
		return{
      baseSVGPosition: {},
      sizeRatio: 1,
      roomModel: [],
      roomCoordinates: {}
		}
	},
  mounted() {
    this.baseSVGPosition = document.getElementById("UG").getBoundingClientRect();
    this.sizeRatio = this.baseSVGPosition.width / 1384.85;
  },
  methods:{
    getCoordinates(roomName){
      if (this.roomCoordinates[roomName] == undefined){
        var room = document.getElementById(roomName.replace(" ", "_"));
        if (room){
          var rect = room.getBoundingClientRect();
          var x = rect.left - document.getElementById("pinch").getBoundingClientRect().left - 10 * this.sizeRatio;
          var y = rect.top - this.baseSVGPosition.top ;
          this.roomCoordinates[roomName] = "top: " + y + "px; left: " + x + "px";
        }
        else{
          return "top: 0; left: 0";
        }
      }
      return this.roomCoordinates[roomName];
    },
    changeSetTemp(room){
      this.$emit('editSetTemp', room);
    },
    openRoomDetails(room){
      this.$emit('editRoomDetails', room);
    }
  },
  computed:{
    infoCardSize(){
      return {
        width: 200 * this.sizeRatio,
        height: 100 * this.sizeRatio
      }
    }
  },
  watch: {
    rooms() {
      this.roomModel = this.rooms;
    }
  }
})
</script>