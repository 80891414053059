/* eslint-disable no-mixed-spaces-and-tabs */
<template>
  <div>
    <pinch-zoom
      backgroundColor="white"
      autoHeight="true"
      id="pinch"
      >
      <!-- <div> -->
        <svg
          version="1.1"
          id="EG"
          width="1384.85"
          height="612.651"
          viewBox="0 0 1384.85 612.651"
          overflow="visible"
          enable-background="new 0 0 1384.85 612.651"
          xml:space="preserve"
        >
          <!-- xmlns="&ns_svg;"
          xmlns:xlink="&ns_xlink;" -->
          <path
            fill="none"
            stroke="#FFFF00"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M734.669,16.047l2.057,0.002 M726.166,16.037l5.669,0.007 M717.662,16.027l5.669,0.007 M709.158,16.018l5.669,0.006 M700.654,16.007
        l5.669,0.007 M692.15,15.998l5.669,0.006 M683.646,15.987l5.669,0.007 M675.142,15.978l5.669,0.007 M666.638,15.968l5.669,0.006
        M658.134,15.958l5.669,0.007 M649.63,15.948l5.669,0.006 M641.126,15.938l5.669,0.007 M632.623,15.928l5.669,0.007 M624.119,15.918
        l5.669,0.006 M615.615,15.908l5.669,0.007 M607.111,15.898l5.669,0.006 M598.607,15.888l5.669,0.007 M590.103,15.878l5.669,0.007
        M581.599,15.868l5.669,0.006 M573.095,15.858l5.669,0.007 M564.591,15.848l5.669,0.006 M556.087,15.838l5.669,0.007
        M547.583,15.828l5.669,0.007 M539.079,15.818l5.669,0.006 M530.575,15.808l5.669,0.007 M522.071,15.798l5.669,0.006
        M513.567,15.788l5.669,0.007 M505.063,15.778l5.669,0.007 M496.56,15.769l5.669,0.006 M488.056,15.758l5.669,0.007"
          />
          <rect
            x="736.476"
            y="15.799"
            transform="matrix(-0.7076 0.7066 -0.7066 -0.7076 1269.3701 -493.1783)"
            fill="#FFFF00"
            width="0.501"
            height="0.502"
          />
          <rect
            x="1369.049"
            y="477.46"
            transform="matrix(-0.7668 0.6419 -0.6419 -0.7668 2725.8975 -34.9584)"
            fill="#FFFF00"
            width="0.501"
            height="0.501"
          />
          <rect
            x="990.658"
            y="558.488"
            transform="matrix(0.7052 0.7091 -0.7091 0.7052 688.3379 -537.8652)"
            fill="#FFFF00"
            width="0.501"
            height="0.501"
          />
          <rect
            x="1075.697"
            y="558.669"
            transform="matrix(0.7052 0.7091 -0.7091 0.7052 713.5399 -598.1089)"
            fill="#FFFF00"
            width="0.501"
            height="0.501"
          />
          <rect
            x="1075.698"
            y="558.67"
            transform="matrix(-0.1422 0.9898 -0.9898 -0.1422 1782.2283 -426.5872)"
            fill="#FFFF00"
            width="0.501"
            height="0.501"
          />
          <rect
            x="990.658"
            y="558.488"
            transform="matrix(-0.9906 0.1365 -0.1365 -0.9906 2048.8125 976.9819)"
            fill="#FFFF00"
            width="0.501"
            height="0.501"
          />
          <rect
            x="736.05"
            y="557.944"
            transform="matrix(0.7061 0.7081 -0.7081 0.7061 611.6232 -357.3248)"
            fill="#FFFF00"
            width="0.501"
            height="0.501"
          />
          <rect
            x="736.475"
            y="15.799"
            transform="matrix(0.7066 0.7076 -0.7076 0.7066 227.4974 -516.5937)"
            fill="#FFFF00"
            width="0.502"
            height="0.501"
          />
          <rect
            x="736.05"
            y="557.944"
            transform="matrix(0.7061 0.7081 -0.7081 0.7061 611.6232 -357.3248)"
            fill="#FFFF00"
            width="0.501"
            height="0.501"
          />
          <rect
            x="736.475"
            y="15.799"
            transform="matrix(0.7066 0.7076 -0.7076 0.7066 227.4974 -516.5937)"
            fill="#FFFF00"
            width="0.502"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#00FF00"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M744.652,602.149L744.652,602.149 M1119.941,605.204L1119.941,605.204 M1272.445,605.45L1272.445,605.45"
          />
          <polygon
            fill="#C0C8D3"
            points="796.445,471.537 796.63,472.137 796.82,472.57 797.004,472.849 797.227,473.066 797.516,473.255 
        797.898,473.416 798.417,473.544 799.18,473.635 801.442,473.719 809.533,473.896 817.629,473.722 819.988,473.636 820.76,473.546 
        821.284,473.417 821.668,473.257 821.958,473.067 822.182,472.849 822.368,472.569 822.557,472.134 822.743,471.538 
        822.743,471.537 822.906,464.914 822.892,460.13 822.71,452.989 822.708,452.917 796.479,452.917 796.476,452.989 796.294,460.13 
        796.282,464.914 "
          />
          <polygon
            fill="#C0C8D3"
            points="859.56,452.989 839.718,452.989 839.454,468.205 839.453,473.625 839.594,476.063 839.98,478.991 
        840.178,480.224 840.425,480.886 840.65,481.343 840.895,481.712 841.187,482.047 841.515,482.336 841.889,482.586 842.341,482.813 
        842.895,483.017 843.562,483.189 844.379,483.328 845.595,483.447 847.747,483.592 848.853,483.609 849.261,483.603 
        850.017,483.603 850.425,483.609 851.532,483.592 853.683,483.447 854.899,483.328 855.716,483.189 856.383,483.017 
        856.938,482.813 857.389,482.586 857.763,482.336 858.091,482.047 858.383,481.712 858.628,481.343 858.853,480.886 859.1,480.224 
        859.298,478.991 859.684,476.063 859.826,473.625 859.825,468.205 "
          />
          <rect
            x="302.398"
            y="0.354"
            fill="#C4C4FF"
            width="181.417"
            height="10.205"
          />
          <rect
            x="67.405"
            y="361.146"
            fill="#C4C4FF"
            width="163.56"
            height="10.205"
          />
          <rect
            x="230.965"
            y="74.848"
            fill="#C4C4FF"
            width="10.205"
            height="286.299"
          />
          <rect
            x="576.225"
            y="0.354"
            fill="#C4C4FF"
            width="166.677"
            height="10.205"
          />
          <rect
            x="861.107"
            y="0.354"
            fill="#C4C4FF"
            width="136.063"
            height="10.205"
          />
          <rect
            x="298.712"
            y="549.367"
            fill="#C4C4FF"
            width="185.387"
            height="10.205"
          />
          <rect
            x="1036.004"
            y="0.354"
            fill="#AAAAAA"
            width="202.677"
            height="16.158"
          />
          <polygon
            fill="#AAAAAA"
            points="1298.776,16.512 1366.934,16.512 1368.295,534.911 1342.718,534.911 1342.718,551.068 
        1384.496,551.068 1383.049,0.354 1298.776,0.354 "
          />
          <polygon
            fill="#AAAAAA"
            points="1367.361,177.745 1247.185,177.745 1247.185,193.903 1367.403,193.903 "
          />
          <rect
            x="1036.004"
            y="177.745"
            fill="#AAAAAA"
            width="153.921"
            height="16.158"
          />
          <rect
            x="884.067"
            y="375.179"
            fill="#C0C8D3"
            width="22.678"
            height="34.016"
          />
          <rect
            x="884.067"
            y="420.532"
            fill="#C0C8D3"
            width="22.678"
            height="34.017"
          />
          <polygon
            fill="#C0C8D3"
            points="1005.957,419.257 990.741,418.992 985.321,418.991 982.883,419.133 979.955,419.519 
        978.722,419.717 978.06,419.964 977.603,420.188 977.235,420.434 976.9,420.726 976.61,421.054 976.36,421.428 976.133,421.88 
        975.93,422.434 975.756,423.101 975.618,423.918 975.5,425.134 975.355,427.285 975.337,428.392 975.344,428.8 975.344,429.556 
        975.337,429.964 975.355,431.07 975.5,433.223 975.618,434.438 975.756,435.255 975.93,435.922 976.133,436.477 976.36,436.928 
        976.61,437.302 976.9,437.63 977.235,437.922 977.603,438.167 978.06,438.392 978.722,438.639 979.955,438.837 982.883,439.223 
        985.321,439.364 990.741,439.363 1005.957,439.099 "
          />
          <path
            fill="none"
            stroke="#C89632"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1377.899,549.935l1.417-1.418 M1371.886,549.935l1.417-1.418 M1365.873,549.935l1.418-1.418 M1359.859,549.935l1.418-1.418
        M1353.846,549.935l1.417-1.418 M1347.833,549.935l1.417-1.418 M1342.718,549.035l0.52-0.519 M1380.906,549.935l1.417-1.418
        M1374.892,549.935l1.418-1.418 M1368.879,549.935l1.417-1.418 M1362.866,549.935l1.418-1.418 M1356.853,549.935l1.417-1.418
        M1350.839,549.935l1.418-1.418 M1344.827,549.935l1.417-1.418"
          />
          <path
            fill="#C8C832"
            d="M517.264,279.489l-0.5,1.537l-0.95,1.308l-1.308,0.95l-1.537,0.499h-1.616l-1.537-0.499l-1.308-0.95
        l-0.95-1.308l-0.5-1.537v-1.616l0.5-1.537l0.95-1.308l1.308-0.95l1.537-0.499h1.616l1.537,0.499l1.308,0.95l0.95,1.308l0.5,1.537
        V279.489z M500.823,267.343v22.677H523.5v-22.677H500.823z"
          />
          <path
            fill="#AAAAAA"
            d="M884.067,356.895h30.048v-9.354h-30.048V356.895z M1017.295,347.54h-51.874v9.354h51.874V347.54z"
          />
          <path
            fill="none"
            stroke="#00FF00"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M655.877,318.865l0.284-0.283 M658.914,327.855l4.009-4.009 M655.877,330.892l1.032-1.032 M658.914,339.882l4.009-4.009
        M655.877,342.918l1.032-1.032 M658.914,351.908l4.009-4.009 M655.877,354.944l1.032-1.032 M658.914,363.935l4.009-4.009
        M655.877,366.971l1.032-1.032 M658.914,375.961l4.009-4.009 M655.877,378.997l1.032-1.031 M658.914,387.987l4.009-4.009
        M655.877,391.023l1.032-1.032 M658.914,400.014l4.009-4.009 M655.877,403.05l1.032-1.031 M658.914,412.04l4.009-4.009
        M655.877,415.076l1.032-1.031 M658.914,424.066l4.009-4.009 M655.877,427.103l1.032-1.031 M658.914,436.093l4.009-4.009
        M655.877,439.129l1.032-1.031 M658.914,448.119l4.009-4.009 M655.877,451.155l1.032-1.031 M661.25,457.809l1.672-1.672
        M655.877,324.879l3.7-3.7 M655.877,336.905l7.087-7.087 M655.877,348.932l7.087-7.087 M655.877,360.958l7.087-7.087
        M655.877,372.984l7.087-7.087 M655.877,385.011l7.087-7.087 M655.877,397.037l7.087-7.087 M655.877,409.063l7.087-7.087
        M655.877,421.09l7.087-7.087 M655.877,433.116l7.087-7.087 M655.877,445.143l7.087-7.087 M655.877,457.169l7.087-7.087
        M656.161,318.582l0.749-0.749 M644.35,318.366l0.533-0.533 M632.323,318.366l0.533-0.533 M620.297,318.366l0.533-0.533
        M608.271,318.366l0.533-0.533 M596.244,318.366l0.533-0.533 M659.577,321.179l3.387-3.387 M650.363,318.366l1.701-1.701
        M638.336,318.366l1.701-1.701 M626.31,318.366l1.701-1.701 M614.284,318.366l1.701-1.701 M602.257,318.366l1.701-1.701
        M591.247,317.351l0.685-0.686"
          />
          <rect x="497.988" y="256.288" fill="#FFFF8C" width="93.259" height="8.22" />
          <path
            fill="none"
            stroke="#00FF00"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M670.94,460.146l2.337-2.337 M666.19,464.895l2.745-2.744 M658.914,460.146l2.337-2.337 M655.877,463.182l1.032-1.031
        M672.204,464.895l3.668-3.668 M660.177,464.895l7.087-7.086"
          />
          <path
            fill="#AAAAAA"
            d="M790.807,347.54v-3.401h-8.221v43.37h8.221v-31.748V347.54z M782.586,532.359h8.221v-3.401h-8.221V532.359z
        M790.807,478.784v-41.102h-8.221v41.102H790.807z M782.586,19.063v274.902h8.221V19.063H782.586z"
          />
          <path
            fill="none"
            stroke="#C89632"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1182.298,2.906L1182.298,2.906l1.417-1.417 M1176.285,2.906L1176.285,2.906l1.418-1.417 M1170.272,2.906L1170.272,2.906
        l1.417-1.417 M1164.259,2.906L1164.259,2.906l1.417-1.417 M1158.246,2.906L1158.246,2.906l1.417-1.417 M1152.232,2.906
        L1152.232,2.906l1.418-1.417 M1146.219,2.906L1146.219,2.906l1.417-1.417 M1140.207,2.906L1140.207,2.906l1.417-1.417
        M1134.193,2.906L1134.193,2.906l1.417-1.417 M1128.179,2.906L1128.179,2.906l1.418-1.417 M1122.167,2.906L1122.167,2.906
        l1.417-1.417 M1116.154,2.906L1116.154,2.906l1.417-1.417 M1110.14,2.906L1110.14,2.906l1.417-1.417 M1104.126,2.906L1104.126,2.906
        l1.418-1.417 M1098.114,2.906L1098.114,2.906l1.417-1.417 M1092.1,2.906L1092.1,2.906l1.418-1.417 M1086.087,2.906L1086.087,2.906
        l1.417-1.417 M1080.074,2.906L1080.074,2.906l1.418-1.417 M1074.061,2.906L1074.061,2.906l1.417-1.417 M1068.047,2.906
        L1068.047,2.906l1.418-1.417 M1062.035,2.906L1062.035,2.906l1.417-1.417 M1056.021,2.906L1056.021,2.906l1.418-1.417
        M1050.008,2.906L1050.008,2.906l1.417-1.417 M1043.995,2.906L1043.995,2.906l1.418-1.417 M1037.982,2.906L1037.982,2.906
        l1.417-1.417"
          />
          <path
            fill="none"
            stroke="#C89632"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1047.001,2.906L1047.001,2.906 M1040.989,2.906L1040.989,2.906l1.417-1.417 M1036.004,1.877l0.388-0.389 M1380.734,2.906
        L1380.734,2.906l1.186-1.185 M1374.72,2.906L1374.72,2.906l1.418-1.417 M1368.708,2.906L1368.708,2.906l1.417-1.417 M1362.694,2.906
        L1362.694,2.906l1.417-1.417 M1356.681,2.906L1356.681,2.906l1.417-1.417 M1350.667,2.906L1350.667,2.906l1.418-1.417
        M1344.655,2.906L1344.655,2.906l1.417-1.417 M1338.641,2.906L1338.641,2.906l1.417-1.417 M1332.628,2.906L1332.628,2.906
        l1.417-1.417 M1326.615,2.906L1326.615,2.906l1.418-1.417 M1320.602,2.906L1320.602,2.906l1.417-1.417 M1314.588,2.906
        L1314.588,2.906l1.417-1.417 M1308.576,2.906L1308.576,2.906l1.417-1.417 M1302.562,2.906L1302.562,2.906l1.418-1.417
        M1236.417,2.906L1236.417,2.906l1.417-1.417 M1230.404,2.906L1230.404,2.906l1.417-1.417 M1224.391,2.906L1224.391,2.906
        l1.417-1.417 M1218.377,2.906L1218.377,2.906l1.417-1.417 M1212.365,2.906L1212.365,2.906l1.417-1.417 M1206.351,2.906
        L1206.351,2.906l1.417-1.417 M1200.337,2.906L1200.337,2.906l1.418-1.417 M1194.325,2.906L1194.325,2.906l1.417-1.417
        M1188.312,2.906L1188.312,2.906l1.417-1.417"
          />
          <path
            fill="none"
            stroke="#C89632"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1197.332,2.906L1197.332,2.906 M1191.318,2.906L1191.318,2.906l1.418-1.417 M1185.305,2.906L1185.305,2.906l1.417-1.417
        M1179.292,2.906L1179.292,2.906l1.418-1.417 M1173.279,2.906L1173.279,2.906l1.417-1.417 M1167.265,2.906L1167.265,2.906
        l1.418-1.417 M1161.252,2.906L1161.252,2.906l1.417-1.417 M1155.239,2.906L1155.239,2.906l1.418-1.417 M1149.226,2.906
        L1149.226,2.906l1.417-1.417 M1143.212,2.906L1143.212,2.906l1.418-1.417 M1137.2,2.906L1137.2,2.906l1.417-1.417 M1131.186,2.906
        L1131.186,2.906l1.417-1.417 M1125.173,2.906L1125.173,2.906l1.417-1.417 M1119.16,2.906L1119.16,2.906l1.418-1.417 M1113.147,2.906
        L1113.147,2.906l1.417-1.417 M1107.133,2.906L1107.133,2.906l1.417-1.417 M1101.121,2.906L1101.121,2.906l1.417-1.417
        M1095.107,2.906L1095.107,2.906l1.418-1.417 M1089.094,2.906L1089.094,2.906l1.417-1.417 M1083.081,2.906L1083.081,2.906
        l1.417-1.417 M1077.068,2.906L1077.068,2.906l1.417-1.417 M1071.054,2.906L1071.054,2.906l1.418-1.417 M1065.042,2.906
        L1065.042,2.906l1.417-1.417 M1059.028,2.906L1059.028,2.906l1.417-1.417 M1053.015,2.906L1053.015,2.906l1.417-1.417
        M1047.001,2.906l1.418-1.417"
          />
          <path
            fill="none"
            stroke="#C89632"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1377.727,2.906L1377.727,2.906l1.417-1.417 M1371.714,2.906L1371.714,2.906l1.417-1.417 M1365.701,2.906L1365.701,2.906
        l1.417-1.417 M1359.687,2.906L1359.687,2.906l1.418-1.417 M1353.674,2.906L1353.674,2.906l1.417-1.417 M1347.662,2.906
        L1347.662,2.906l1.417-1.417 M1341.648,2.906L1341.648,2.906l1.417-1.417 M1335.634,2.906L1335.634,2.906l1.418-1.417
        M1329.622,2.906L1329.622,2.906l1.417-1.417 M1323.609,2.906L1323.609,2.906l1.417-1.417 M1317.595,2.906L1317.595,2.906
        l1.417-1.417 M1311.582,2.906L1311.582,2.906L1313,1.488 M1305.569,2.906L1305.569,2.906l1.417-1.417 M1299.556,2.906
        L1299.556,2.906l1.417-1.417 M1233.411,2.906L1233.411,2.906l1.417-1.417 M1227.397,2.906L1227.397,2.906l1.418-1.417
        M1221.384,2.906L1221.384,2.906l1.417-1.417 M1215.371,2.906L1215.371,2.906l1.418-1.417 M1209.358,2.906L1209.358,2.906
        l1.417-1.417 M1203.344,2.906L1203.344,2.906l1.418-1.417 M1197.332,2.906l1.417-1.417"
          />
          <rect
            x="6.176"
            y="379.855"
            fill="#AAAAAA"
            width="18.708"
            height="161.008"
          />
          <path
            fill="none"
            stroke="#C89632"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1049.56,192.769l1.418-1.417 M1043.547,192.769l1.417-1.417 M1037.535,192.769l1.417-1.417 M1377.305,192.743l1.392-1.391
        M1371.277,192.759l1.406-1.407 M1365.253,192.769l1.417-1.417 M1359.24,192.769l1.418-1.417 M1353.227,192.769l1.417-1.417
        M1347.213,192.769l1.417-1.417 M1341.201,192.769l1.417-1.417 M1335.187,192.769l1.418-1.417 M1329.174,192.769l1.417-1.417
        M1323.161,192.769l1.417-1.417 M1317.148,192.769l1.417-1.417 M1311.134,192.769l1.417-1.417 M1305.122,192.769l1.417-1.417
        M1299.108,192.769l1.417-1.417 M1293.095,192.769l1.417-1.417 M1287.082,192.769l1.417-1.417 M1281.068,192.769l1.418-1.417
        M1275.055,192.769l1.417-1.417 M1269.042,192.769l1.417-1.417 M1263.029,192.769l1.417-1.417 M1257.015,192.769l1.418-1.417
        M1251.002,192.769l1.417-1.417 M1184.857,192.769l1.418-1.417 M1178.844,192.769l1.417-1.417 M1172.831,192.769l1.418-1.417
        M1166.818,192.769l1.417-1.417 M1160.804,192.769l1.418-1.417 M1154.792,192.769l1.417-1.417 M1148.778,192.769l1.418-1.417
        M1142.765,192.769l1.417-1.417 M1136.751,192.769l1.418-1.417 M1130.739,192.769l1.417-1.417 M1124.725,192.769l1.418-1.417
        M1118.712,192.769l1.417-1.417 M1112.699,192.769l1.418-1.417 M1106.686,192.769l1.417-1.417 M1100.672,192.769l1.418-1.417
        M1094.66,192.769l1.417-1.417 M1088.646,192.769l1.418-1.417 M1082.633,192.769l1.417-1.417 M1076.62,192.769l1.418-1.417
        M1070.607,192.769l1.417-1.417 M1064.593,192.769l1.418-1.417 M1058.581,192.769l1.417-1.417 M1052.567,192.769l1.418-1.417
        M1046.554,192.769l1.417-1.417 M1040.541,192.769l1.418-1.417"
          />
          <path
            fill="none"
            stroke="#C89632"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1344.207,192.769l1.418-1.417 M1338.194,192.769l1.417-1.417 M1332.18,192.769l1.418-1.417 M1326.167,192.769l1.417-1.417
        M1320.154,192.769l1.418-1.417 M1314.141,192.769l1.417-1.417 M1308.127,192.769l1.418-1.417 M1302.115,192.769l1.417-1.417
        M1296.101,192.769l1.418-1.417 M1290.088,192.769l1.417-1.417 M1284.075,192.769l1.418-1.417 M1278.062,192.769l1.417-1.417
        M1272.048,192.769l1.418-1.417 M1266.036,192.769l1.417-1.417 M1260.022,192.769l1.418-1.417 M1254.009,192.769l1.417-1.417
        M1247.996,192.769l1.418-1.417 M1187.864,192.769l1.417-1.417 M1181.851,192.769l1.417-1.417 M1175.837,192.769l1.418-1.417
        M1169.825,192.769l1.417-1.417 M1163.811,192.769l1.417-1.417 M1157.798,192.769l1.417-1.417 M1151.785,192.769l1.418-1.417
        M1145.772,192.769l1.417-1.417 M1139.758,192.769l1.417-1.417 M1133.746,192.769l1.417-1.417 M1127.732,192.769l1.417-1.417
        M1121.719,192.769l1.417-1.417 M1115.706,192.769l1.417-1.417 M1109.693,192.769l1.417-1.417 M1103.679,192.769l1.417-1.417
        M1097.666,192.769l1.418-1.417 M1091.653,192.769l1.417-1.417 M1085.64,192.769l1.417-1.417 M1079.626,192.769l1.417-1.417
        M1073.613,192.769l1.418-1.417 M1067.6,192.769l1.417-1.417 M1061.587,192.769l1.417-1.417 M1055.574,192.769l1.417-1.417"
          />
          <path
            fill="none"
            stroke="#C89632"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1380.32,192.735l0.685-0.685 M1374.291,192.751l1.399-1.399 M1368.262,192.767l1.415-1.415 M1362.247,192.769l1.417-1.417
        M1356.234,192.769l1.417-1.417 M1350.22,192.769l1.417-1.417 M1345.625,191.352L1345.625,191.352"
          />
          <path
            fill="#AAAAAA"
            d="M77.609,540.863H6.176v18.709h71.433V540.863z M497.988,540.863h-13.89v18.709h13.89V540.863z
        M591.247,540.863h-26.362v18.709h26.362V540.863z M208.003,559.572h90.709v-18.709h-90.709V559.572z"
          />
          <path
            fill="#AAAAAA"
            d="M718.24,551.068v-18.709h-15.308v18.709H718.24z M871.594,551.068h15.308v-18.709h-15.308V551.068z
        M613.924,532.359h-22.677v18.709h22.677V532.359z M797.894,551.068v-18.709h-15.308v18.709H797.894z M666.083,532.359h-15.308
        v18.709h15.308V532.359z M1017.295,551.068h18.709v-18.709h-18.709V551.068z"
          />
          <polygon
            fill="#AAAAAA"
            points="1036.004,532.359 1036.004,19.063 1017.295,19.063 1017.295,460.075 1017.295,532.359 "
          />
          <path
            fill="#AAAAAA"
            d="M742.902,265.642h39.685v-9.354h-39.685V265.642z M591.247,265.642h94.395v-9.354h-94.395V265.642z"
          />
          <path
            fill="#AAAAAA"
            d="M581.893,264.508v8.504h9.354v-8.504H581.893z M591.247,540.863V316.099h-9.354v224.765H591.247z"
          />
          <rect
            x="591.247"
            y="551.068"
            fill="#AAAAAA"
            width="11.339"
            height="61.229"
          />
          <polygon
            fill="#AAAAAA"
            points="875.846,355.761 875.846,532.359 884.067,532.359 884.067,475.667 884.067,473.966 884.067,355.761 
        "
          />
          <rect
            x="790.807"
            y="441.084"
            fill="#AAAAAA"
            width="85.039"
            height="11.905"
          />
          <rect x="790.807" y="347.54" fill="#AAAAAA" width="93.26" height="8.221" />
          <path
            fill="none"
            stroke="#C89632"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1381.169,255.024l1.414-1.414 M1381.185,261.021l1.414-1.414 M1381.201,267.019l1.414-1.414 M1381.216,273.017l1.414-1.414
        M1381.232,279.015l1.414-1.414 M1381.248,285.012l1.414-1.414 M1381.263,291.009l1.414-1.413 M1381.279,297.007l1.414-1.414
        M1381.294,303.004l1.414-1.413 M1381.31,309.002l1.414-1.414 M1381.326,314.999l1.414-1.414 M1381.342,320.997l1.413-1.414
        M1381.358,326.994l1.413-1.414 M1381.374,332.991l1.413-1.413 M1381.389,338.989l1.413-1.414 M1381.405,344.986l1.413-1.413
        M1381.42,350.984l1.414-1.414 M1381.436,356.981l1.414-1.413 M1381.452,362.979l1.414-1.414 M1381.467,368.977l1.414-1.414
        M1381.483,374.974l1.414-1.413 M1381.499,380.972l1.414-1.414 M1381.515,386.969l1.413-1.413 M1381.531,392.967l1.413-1.414
        M1381.546,398.964l1.413-1.413 M1381.562,404.962l1.413-1.414 M1381.578,410.959l1.414-1.414 M1381.593,416.957l1.414-1.414
        M1381.609,422.954l1.414-1.414 M1381.625,428.951l1.414-1.413 M1381.64,434.949l1.414-1.414 M1381.656,440.946l1.414-1.413
        M1381.671,446.944l1.414-1.414 M1381.687,452.941l1.414-1.414 M1381.703,458.939l1.414-1.414 M1381.718,464.937l1.414-1.414
        M1381.734,470.934l1.414-1.413 M1381.75,476.932l1.414-1.414 M1381.765,482.929l1.414-1.413 M1381.782,488.927l1.413-1.414
        M1381.797,494.924l1.413-1.413 M1381.813,500.922l1.413-1.414 M1381.829,506.919l1.413-1.414 M1381.844,512.917l1.414-1.414
        M1381.86,518.914l1.414-1.414 M1381.875,524.911l1.414-1.413 M1381.891,530.909l1.414-1.414 M1381.907,536.906l1.414-1.413
        M1381.923,542.904l1.413-1.414 M1382.323,548.517l1.029-1.029"
          />
          <path
            fill="none"
            stroke="#C89632"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1381.318,312l1.414-1.413 M1381.333,317.998l1.414-1.414 M1381.35,323.995l1.413-1.413 M1381.366,329.993l1.413-1.414
        M1381.381,335.99l1.413-1.413 M1381.397,341.988l1.413-1.414 M1381.413,347.985l1.413-1.414 M1381.428,353.982l1.414-1.413
        M1381.444,359.98l1.414-1.414 M1381.459,365.978l1.414-1.413 M1381.475,371.976l1.414-1.414 M1381.491,377.973l1.414-1.413
        M1381.507,383.971l1.413-1.414 M1381.523,389.968l1.413-1.414 M1381.539,395.966l1.413-1.414 M1381.554,401.963l1.413-1.414
        M1381.57,407.96l1.413-1.413 M1381.585,413.958l1.414-1.414 M1381.601,419.955l1.414-1.413 M1381.617,425.953l1.414-1.414
        M1381.632,431.95l1.414-1.414 M1381.648,437.948l1.414-1.414 M1381.664,443.945l1.414-1.414 M1381.679,449.942l1.414-1.413
        M1381.695,455.94l1.414-1.414 M1381.71,461.938l1.414-1.413 M1381.726,467.936l1.414-1.414 M1381.742,473.933l1.414-1.414
        M1381.757,479.931l1.414-1.414 M1381.774,485.928l1.413-1.414 M1381.79,491.925l1.413-1.413 M1381.805,497.923l1.413-1.414
        M1381.821,503.92l1.413-1.413 M1381.836,509.918l1.414-1.414 M1381.852,515.915l1.414-1.414 M1381.868,521.913l1.414-1.414
        M1381.883,527.91l1.414-1.414 M1381.899,533.907l1.414-1.413 M1381.915,539.905l1.414-1.414 M1381.931,545.902l1.413-1.413
        M1381.012,195.049l1.414-1.414 M1381.028,201.047l1.414-1.414 M1381.043,207.044l1.414-1.414 M1381.059,213.042l1.414-1.414
        M1381.076,219.039l1.413-1.414 M1381.091,225.037l1.413-1.414 M1381.107,231.034l1.413-1.414 M1381.123,237.032l1.413-1.414
        M1381.138,243.029l1.413-1.414 M1381.154,249.027l1.413-1.414"
          />
          <path
            fill="none"
            stroke="#C89632"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1380.521,9.131l1.414-1.414 M1380.537,15.129l1.414-1.414 M1380.552,21.126l1.414-1.414 M1380.569,27.124l1.413-1.414
        M1380.584,33.121l1.414-1.414 M1380.6,39.118l1.414-1.414 M1380.617,45.116l1.413-1.414 M1380.632,51.113l1.413-1.414
        M1380.648,57.11l1.414-1.414 M1380.664,63.107l1.414-1.414 M1380.679,69.105l1.414-1.414 M1380.696,75.103l1.413-1.414
        M1380.711,81.1l1.413-1.414 M1380.727,87.097l1.414-1.413 M1380.743,93.094l1.414-1.414 M1380.759,99.092l1.413-1.414
        M1380.775,105.089l1.413-1.414 M1380.791,111.086l1.414-1.414 M1380.806,117.083l1.414-1.414 M1380.822,123.081l1.414-1.414
        M1380.838,129.078l1.413-1.414 M1380.854,135.076l1.413-1.414 M1380.87,141.073l1.414-1.413 M1380.886,147.07l1.413-1.414
        M1380.902,153.067l1.413-1.413 M1380.917,159.065l1.413-1.414 M1380.933,165.063l1.414-1.414 M1380.949,171.06l1.414-1.414
        M1380.965,177.057l1.413-1.414 M1380.981,183.054l1.413-1.414 M1380.997,189.052l1.413-1.414 M1381.02,198.048l1.414-1.414
        M1381.036,204.045l1.414-1.414 M1381.051,210.043l1.414-1.414 M1381.067,216.041l1.414-1.414 M1381.083,222.038l1.413-1.414
        M1381.099,228.036l1.413-1.414 M1381.115,234.033l1.413-1.414 M1381.13,240.03l1.413-1.414 M1381.146,246.028l1.413-1.414
        M1381.162,252.025l1.414-1.414 M1381.177,258.023l1.414-1.414 M1381.193,264.021l1.414-1.414 M1381.208,270.018l1.414-1.413
        M1381.224,276.016l1.414-1.414 M1381.24,282.013l1.414-1.413 M1381.255,288.011l1.414-1.414 M1381.271,294.008l1.414-1.413
        M1381.287,300.006l1.414-1.414 M1381.302,306.003l1.414-1.414"
          />
          <path
            fill="none"
            stroke="#C89632"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1380.513,6.133l1.414-1.414 M1380.529,12.13l1.414-1.414 M1380.544,18.127l1.414-1.414 M1380.561,24.125l1.413-1.414
        M1380.577,30.122l1.413-1.414 M1380.592,36.12l1.414-1.414 M1380.608,42.117l1.414-1.414 M1380.625,48.114l1.413-1.414
        M1380.64,54.111l1.414-1.414 M1380.656,60.109l1.414-1.414 M1380.671,66.106l1.414-1.414 M1380.687,72.104l1.414-1.414
        M1380.704,78.101l1.413-1.414 M1380.719,84.098l1.413-1.414 M1380.735,90.096l1.414-1.414 M1380.751,96.093l1.413-1.414
        M1380.767,102.09l1.413-1.414 M1380.783,108.087l1.414-1.414 M1380.798,114.085l1.414-1.414 M1380.814,120.082l1.414-1.413
        M1380.831,126.08l1.413-1.414 M1380.846,132.077l1.413-1.414 M1380.862,138.074l1.413-1.414 M1380.878,144.072l1.413-1.414
        M1380.894,150.069l1.413-1.414 M1380.91,156.066l1.413-1.414 M1380.925,162.063l1.414-1.413 M1380.941,168.061l1.414-1.414
        M1380.957,174.058l1.414-1.413 M1380.973,180.056l1.413-1.414 M1380.989,186.053l1.413-1.414 M1381.004,192.05l1.413-1.414
        M1380.505,3.134l0.229-0.228"
          />
          <rect
            x="489.768"
            y="256.288"
            fill="#AAAAAA"
            width="8.22"
            height="284.576"
          />
          <path
            fill="#AAAAAA"
            d="M244.854,379.855v5.669h8.22v-5.669H244.854z M253.075,540.863V435.698h-8.22v105.165H253.075z"
          />
          <path
            fill="#AAAAAA"
            d="M997.169,0.354v18.709h38.835V0.354H997.169z M483.815,19.063h92.41V0.354h-92.41V19.063z M230.965,0.354
        v18.709h71.433V0.354H230.965z M861.107,0.354H742.902v18.709h118.205V0.354z"
          />
          <rect x="249.702" y="19.063" fill="#A55A14" width="3.401" height="55.784" />
          <polygon
            fill="#A55A14"
            points="249.702,361.146 249.702,379.855 253.075,379.855 253.103,361.152 "
          />
          <path
            fill="#AAAAAA"
            d="M249.702,379.855v-18.709h-18.737v18.709H249.702z M230.965,19.063v55.784h18.737V19.063H230.965z"
          />
          <rect x="6.176" y="361.146" fill="#AAAAAA" width="61.229" height="18.709" />
          <polygon
            fill="#008000"
            points="875.846,497.507 884.067,480.498 875.846,480.498 "
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="697.547"
            y1="189.792"
            x2="640.854"
            y2="189.792"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M945.112,277.798c19.927,19.926,43.259,28.899,52.114,20.044 M925.068,225.683c-8.855,8.856,0.118,32.188,20.044,52.115
        M977.182,245.727c-19.926-19.926-43.258-28.9-52.114-20.044 M997.226,297.842c8.856-8.856-0.117-32.188-20.044-52.115"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M941.103,281.807c22.141,22.14,49.063,31.113,60.132,20.044 M921.059,221.674c-11.069,11.07-2.096,37.992,20.044,60.132
        M981.191,241.718c-22.14-22.14-49.062-31.114-60.132-20.044 M1001.235,301.851c11.07-11.07,2.097-37.992-20.044-60.132"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M959.021,261.762c0,1.174,0.952,2.126,2.126,2.126 M961.147,259.636c-1.174,0-2.126,0.952-2.126,2.126 M963.273,261.762
        c0-1.174-0.951-2.126-2.126-2.126 M961.147,263.888c1.175,0,2.126-0.952,2.126-2.126"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1370.016,343.975c0.428,1.177,1.729,1.785,2.905,1.357 M1371.375,341.069c-1.178,0.427-1.785,1.728-1.358,2.905 M1374.28,342.427
        c-0.428-1.177-1.729-1.785-2.905-1.357 M1372.921,345.332c1.178-0.427,1.785-1.728,1.358-2.905"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M809.533,473.896l-2.228-0.04l-2.285-0.045l-2.399-0.059l-2.57-0.077l-0.901-0.043l-0.858-0.112l-0.746-0.249l-0.564-0.454
        l-0.176-0.274l-0.14-0.313l-0.116-0.339l-0.104-0.352 M822.743,471.537l0.057-1.858l0.052-1.857l0.039-1.858l0.022-1.857
        l-0.006-2.779l-0.044-2.779l-0.069-2.778l-0.083-2.779 M809.533,473.896l2.256-0.039l2.313-0.046l2.431-0.058l2.604-0.078
        l0.901-0.043l0.857-0.112l0.746-0.249l0.565-0.454l0.175-0.274l0.14-0.313l0.116-0.339l0.104-0.352"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M820.485,454.36c-0.007-0.224-0.188-0.4-0.412-0.4"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M798.662,470.742l0.034,0.1l0.037,0.098l0.045,0.093l0.056,0.086l0.02,0.023l0.02,0.022l0.021,0.022l0.022,0.021l0.073,0.06
        l0.08,0.052 M804.107,471.611l5.483,0.073 M819.491,471.448l-4.532,0.163l-5.483,0.073 M820.5,470.75l0.031-1.158l0.03-1.037
        l0.026-0.976l0.023-0.973l0.014-0.723l0.012-0.741l0.008-0.751l0.004-0.751l0.001-0.757l-0.002-0.753l-0.006-0.753l-0.009-0.76
        l-0.016-0.989l-0.019-0.99l-0.021-0.972l-0.024-0.934l-0.017-0.55l-0.017-0.559l-0.017-0.598l-0.019-0.667 M820.502,470.742
        l-0.034,0.1l-0.037,0.098l-0.045,0.093l-0.056,0.086l-0.019,0.023l-0.021,0.022l-0.021,0.022l-0.022,0.021l-0.073,0.06l-0.08,0.052
        l-0.086,0.042l-0.089,0.032l-0.099,0.023l-0.101,0.016l-0.102,0.009l-0.103,0.006 M820.073,453.96H799.34"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M804.107,471.611l-4.459-0.165 M798.664,470.75l-0.031-1.158l-0.029-1.037l-0.027-0.976l-0.023-0.973l-0.014-0.723l-0.011-0.741
        l-0.009-0.751l-0.004-0.751l-0.001-0.757l0.003-0.753l0.006-0.753l0.008-0.76l0.016-0.989l0.019-0.99l0.021-0.972l0.024-0.934
        l0.017-0.55l0.017-0.559l0.018-0.598l0.018-0.667 M799.07,471.318l0.086,0.042l0.089,0.032l0.099,0.023l0.101,0.016l0.102,0.009
        l0.103,0.006"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M799.091,453.96c-0.223,0-0.405,0.177-0.412,0.4"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="799.091"
            y1="453.96"
            x2="799.34"
            y2="453.96"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M798.973,458.47c-0.223,0-0.405,0.177-0.412,0.399"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="798.973"
            y1="458.47"
            x2="799.222"
            y2="458.47"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M820.597,458.869c-0.007-0.223-0.189-0.399-0.412-0.399"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="820.185"
            y1="458.47"
            x2="799.222"
            y2="458.47"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M809.61,460.758c-0.691,0-1.252,0.561-1.252,1.252 M810.862,462.01c0-0.691-0.561-1.252-1.252-1.252 M809.61,463.261
        c0.691,0,1.252-0.561,1.252-1.251 M808.358,462.01c0,0.69,0.561,1.251,1.252,1.251"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M840.126,480.071l0.119,0.348l0.129,0.344l0.146,0.334l0.173,0.32l0.175,0.26l0.198,0.242l0.22,0.224l0.24,0.202l0.309,0.209
        l0.329,0.178l0.343,0.149l0.354,0.125l0.503,0.139l0.509,0.105l0.514,0.079l0.518,0.059l0.418,0.038 M859.56,452.989h-19.842
        l-0.056,2.577l-0.054,2.578l-0.05,2.577l-0.044,2.579l-0.036,2.58l-0.027,2.581l-0.017,2.582l-0.001,0.646l0.001,0.645l0.006,0.645
        l0.012,0.645l0.021,0.644l0.031,0.643l0.044,0.642l0.062,0.676l0.074,0.675l0.086,0.673l0.096,0.673l0.103,0.672l0.106,0.672
        l0.109,0.672 M822.71,452.989l-0.003-0.072 M796.476,452.989l0.003-0.072 M822.708,452.917h-26.229 M796.445,471.537l-0.058-1.858
        l-0.051-1.857l-0.04-1.858l-0.022-1.857l0.006-2.779l0.044-2.779l0.07-2.778l0.082-2.779"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M859.152,480.071l-0.119,0.348l-0.129,0.344l-0.146,0.334l-0.173,0.32l-0.175,0.26l-0.198,0.242l-0.22,0.224l-0.24,0.202
        l-0.309,0.209l-0.328,0.178l-0.344,0.149l-0.354,0.125l-0.502,0.139l-0.51,0.105l-0.514,0.079l-0.518,0.059l-0.418,0.038
        l-0.419,0.031 M859.56,452.989l0.056,2.577l0.054,2.578l0.05,2.577l0.044,2.579l0.037,2.58l0.026,2.581l0.017,2.582l0.001,0.646
        l-0.001,0.645l-0.006,0.645l-0.012,0.645l-0.021,0.644l-0.031,0.643l-0.044,0.642l-0.062,0.676l-0.074,0.675l-0.086,0.673
        l-0.096,0.673l-0.102,0.672l-0.107,0.672l-0.108,0.672 M845.322,483.426l0.419,0.031l0.419,0.029l0.419,0.028l0.407,0.029
        l0.408,0.027l0.407,0.023l0.408,0.014l0.263,0.003l0.263-0.001l0.264-0.004l0.263-0.004"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M849.551,462.919l-0.243,0.003l-0.244,0.004l-0.244,0.005l-0.244,0.007l-0.243,0.01l-0.179,0.009l-0.178,0.01l-0.178,0.013
        l-0.179,0.014l-0.178,0.015l-0.125,0.012l-0.125,0.013l-0.125,0.014l-0.124,0.014l-0.125,0.015l-0.121,0.016l-0.121,0.016
        l-0.12,0.018l-0.121,0.019l-0.12,0.019l-0.168,0.029l-0.168,0.031l-0.168,0.035l-0.167,0.037l-0.165,0.042l-0.132,0.035
        l-0.131,0.038l-0.13,0.041l-0.128,0.044l-0.128,0.048l-0.094,0.036l-0.092,0.039l-0.092,0.041l-0.091,0.043l-0.09,0.045
        l-0.087,0.047l-0.085,0.049l-0.084,0.051l-0.083,0.054 M853.538,483.457l-0.419,0.029l-0.419,0.028l-0.407,0.029l-0.407,0.027
        l-0.408,0.023l-0.408,0.014l-0.263,0.003l-0.263-0.001l-0.264-0.004l-0.263-0.004h-0.756"
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        843.933,463.944 843.852,463.999 843.798,464.039 843.745,464.079 843.692,464.121 843.64,464.164 843.589,464.208 843.526,464.266 
        843.464,464.326 843.405,464.389 843.347,464.452 843.291,464.518 843.227,464.598 843.167,464.68 843.108,464.763 843.052,464.849 
        843,464.936 842.947,465.027 842.897,465.12 842.85,465.214 842.804,465.309 842.761,465.405 842.706,465.54 842.654,465.676 
        842.605,465.813 842.56,465.952 842.518,466.092 842.466,466.28 842.419,466.47 842.376,466.66 842.337,466.852 842.302,467.044 
        842.267,467.248 842.237,467.453 842.208,467.658 842.183,467.863 842.16,468.068 842.14,468.255 842.123,468.441 842.106,468.629 
        842.09,468.815 842.077,469.002 842.064,469.188 842.053,469.375 842.042,469.561 842.033,469.747 842.025,469.934 842.016,470.158 
        842.009,470.383 842.002,470.608 841.998,470.833 "
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        841.998,470.833 841.994,471.058 841.991,471.286 841.989,471.516 841.988,471.744 841.987,471.973 841.987,472.202 
        841.988,472.584 841.992,472.966 841.997,473.348 842.005,473.729 842.017,474.111 842.032,474.486 842.051,474.86 842.075,475.234 
        842.104,475.608 842.138,475.981 842.155,476.146 842.173,476.312 842.193,476.477 842.214,476.641 842.237,476.806 
        842.273,477.042 842.314,477.277 842.359,477.512 842.41,477.745 842.467,477.977 842.501,478.103 842.538,478.229 842.577,478.354 
        842.618,478.478 842.662,478.601 842.696,478.69 842.732,478.779 842.77,478.868 842.809,478.956 842.85,479.042 842.882,479.106 
        842.916,479.169 842.949,479.231 842.984,479.294 843.021,479.354 843.067,479.429 843.115,479.502 843.165,479.573 
        843.216,479.644 843.271,479.713 843.397,479.856 843.533,479.993 843.677,480.12 843.829,480.237 "
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M849.551,462.919l0.279,0.002l0.279,0.004l0.278,0.004l0.279,0.008l0.278,0.011l0.179,0.009l0.178,0.01l0.179,0.013l0.178,0.014
        l0.178,0.015l0.125,0.012l0.125,0.013l0.125,0.014l0.124,0.014l0.125,0.015l0.121,0.016l0.121,0.016l0.12,0.018l0.121,0.019
        M843.829,480.237l0.158,0.108l0.143,0.087l0.146,0.079l0.149,0.073l0.151,0.068l0.154,0.062l0.128,0.047l0.128,0.045l0.13,0.041
        l0.131,0.038l0.132,0.035l0.065,0.018l0.067,0.016l0.066,0.016l0.066,0.016l0.067,0.015l0.295,0.06l0.297,0.051l0.298,0.044
        l0.299,0.037l0.299,0.032l0.127,0.013l0.127,0.012l0.127,0.012l0.127,0.01l0.127,0.009l0.344,0.021l0.343,0.015l0.344,0.01
        l0.344,0.007l0.344,0.006"
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        852.944,463.141 853.064,463.159 853.233,463.188 853.401,463.22 853.568,463.255 853.735,463.292 853.901,463.334 854.032,463.369 
        854.163,463.407 854.292,463.448 854.421,463.492 854.548,463.54 854.642,463.576 854.734,463.615 854.826,463.656 854.917,463.699 
        855.006,463.744 855.093,463.791 855.178,463.84 855.262,463.891 855.345,463.944 855.426,463.999 855.481,464.039 855.534,464.079 
        855.586,464.121 855.638,464.164 855.689,464.208 855.752,464.266 855.814,464.326 855.874,464.389 855.932,464.452 
        855.988,464.518 856.051,464.598 856.112,464.68 856.17,464.763 856.226,464.849 856.279,464.936 856.332,465.027 856.381,465.12 
        856.429,465.214 856.474,465.309 856.517,465.405 856.573,465.54 856.625,465.676 856.673,465.813 856.718,465.952 856.76,466.092 
        856.812,466.28 856.859,466.47 856.902,466.66 856.941,466.852 "
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        856.941,466.852 856.976,467.044 857.011,467.248 857.042,467.453 857.07,467.658 857.095,467.863 857.119,468.068 857.138,468.255 
        857.156,468.441 857.172,468.629 857.188,468.815 857.202,469.002 857.214,469.188 857.226,469.375 857.236,469.561 
        857.246,469.747 857.253,469.934 857.262,470.158 857.269,470.383 857.276,470.608 857.281,470.833 857.285,471.058 
        857.288,471.286 857.29,471.516 857.292,471.744 857.292,471.973 857.292,472.202 857.291,472.584 857.287,472.966 857.282,473.348 
        857.273,473.729 857.261,474.111 857.247,474.486 857.227,474.86 857.204,475.234 857.174,475.608 857.14,475.981 857.124,476.146 
        857.105,476.312 857.085,476.477 857.064,476.641 857.042,476.806 857.005,477.042 856.964,477.277 856.919,477.512 
        856.869,477.745 856.811,477.977 856.777,478.103 856.741,478.229 856.702,478.354 856.661,478.478 "
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        856.661,478.478 856.617,478.601 856.583,478.69 856.546,478.779 856.508,478.868 856.469,478.956 856.428,479.042 856.396,479.106 
        856.363,479.169 856.33,479.231 856.294,479.294 856.257,479.354 856.211,479.429 856.164,479.502 856.114,479.573 856.062,479.644 
        856.007,479.713 855.881,479.856 855.746,479.993 855.602,480.12 855.45,480.237 855.292,480.346 855.149,480.433 855.003,480.512 
        854.854,480.585 854.703,480.653 854.548,480.715 854.42,480.762 854.292,480.807 854.163,480.848 854.032,480.886 853.901,480.921 
        853.834,480.938 853.767,480.954 853.701,480.97 853.634,480.985 853.567,481 853.272,481.06 852.976,481.11 852.677,481.154 
        852.378,481.191 852.08,481.224 851.953,481.236 851.826,481.248 851.699,481.26 851.572,481.27 851.445,481.278 851.066,481.301 
        850.688,481.315 850.309,481.325 849.93,481.331 "
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M849.551,463.426l-0.326,0.004l-0.326,0.005l-0.327,0.009l-0.326,0.014l-0.268,0.016l-0.269,0.02l-0.268,0.022l-0.267,0.026
        l-0.233,0.026l-0.231,0.029l-0.232,0.032l-0.231,0.037l-0.168,0.03l-0.168,0.032l-0.167,0.036l-0.166,0.039l-0.131,0.034
        l-0.131,0.036l-0.129,0.04l-0.128,0.043l-0.104,0.037l-0.104,0.04l-0.103,0.043l-0.102,0.046l-0.084,0.04l-0.084,0.043l-0.082,0.045
        l-0.08,0.047l-0.07,0.044l-0.069,0.046l-0.067,0.049l-0.066,0.05l-0.058,0.046l-0.056,0.048l-0.054,0.05l-0.053,0.051l-0.051,0.053
        l-0.049,0.055l-0.048,0.056l-0.046,0.057l-0.049,0.066l-0.048,0.067l-0.045,0.069l-0.044,0.069l-0.048,0.084l-0.047,0.085
        l-0.043,0.087l-0.042,0.087l-0.046,0.104 M849.93,481.331l-0.379,0.005"
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        843.218,465.62 843.174,465.726 843.133,465.832 843.095,465.94 843.05,466.074 843.009,466.21 842.97,466.347 842.935,466.484 
        842.893,466.657 842.856,466.831 842.822,467.005 842.791,467.18 842.752,467.41 842.719,467.642 842.689,467.874 842.663,468.106 
        842.63,468.418 842.603,468.729 842.58,469.041 842.559,469.353 842.539,469.739 842.522,470.126 842.509,470.513 842.5,470.899 
        842.493,471.432 842.49,471.965 842.492,472.498 842.497,473.031 842.502,473.438 842.512,473.846 842.526,474.253 842.545,474.659 
        842.566,474.979 842.589,475.299 842.615,475.619 842.641,475.939 842.662,476.189 842.686,476.438 842.719,476.687 
        842.766,476.931 842.798,477.061 842.833,477.19 842.871,477.319 842.908,477.448 842.946,477.591 842.984,477.733 843.023,477.875 
        843.065,478.016 843.103,478.129 843.143,478.241 843.187,478.352 "
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        843.187,478.352 843.234,478.461 843.275,478.552 843.318,478.641 843.364,478.729 843.412,478.814 843.455,478.888 843.5,478.96 
        843.546,479.03 843.596,479.1 843.64,479.157 843.685,479.213 843.733,479.268 843.782,479.32 843.835,479.374 843.89,479.427 
        843.947,479.478 844.005,479.526 844.069,479.577 844.133,479.626 844.2,479.672 844.267,479.717 844.344,479.764 844.421,479.808 
        844.5,479.851 844.581,479.891 844.678,479.937 844.777,479.979 844.877,480.021 844.978,480.059 845.101,480.102 845.224,480.142 
        845.349,480.18 845.474,480.214 845.635,480.255 845.797,480.292 845.959,480.326 846.123,480.356 846.334,480.394 846.546,480.426 
        846.76,480.455 846.973,480.481 847.235,480.51 847.497,480.535 847.759,480.557 848.021,480.574 848.36,480.591 848.699,480.603 
        849.038,480.61 849.376,480.615 849.464,480.617 "
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M849.547,463.426l0.371,0.004l0.37,0.005l0.371,0.01l0.37,0.015l0.269,0.015l0.268,0.02l0.268,0.023l0.268,0.026l0.232,0.026
        l0.232,0.028l0.231,0.033l0.231,0.037l0.168,0.029l0.168,0.033l0.167,0.035l0.167,0.04l0.131,0.033l0.13,0.037l0.129,0.04
        l0.128,0.042l0.105,0.038l0.104,0.04l0.103,0.043l0.102,0.045l0.085,0.041l0.083,0.042l0.082,0.045l0.08,0.048l0.071,0.044
        l0.068,0.046l0.068,0.048l0.065,0.051l0.058,0.046l0.056,0.047l0.054,0.05l0.053,0.051l0.051,0.053l0.049,0.055l0.048,0.056
        l0.046,0.058l0.049,0.065l0.048,0.068l0.045,0.068l0.044,0.07l0.049,0.084l0.046,0.085l0.044,0.086 M849.464,480.617l0.089,0.001
        l0.088,0.001l0.089,0.001"
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        855.97,465.43 856.011,465.518 856.058,465.622 856.101,465.728 856.142,465.834 856.181,465.941 856.225,466.076 856.267,466.212 
        856.305,466.349 856.341,466.486 856.382,466.658 856.419,466.832 856.455,467.006 856.486,467.181 856.523,467.412 
        856.556,467.644 856.586,467.876 856.613,468.108 856.645,468.419 856.672,468.73 856.696,469.042 856.716,469.354 856.737,469.741 
        856.753,470.127 856.766,470.514 856.775,470.9 856.783,471.434 856.786,471.967 856.784,472.499 856.779,473.032 856.773,473.44 
        856.764,473.848 856.75,474.254 856.73,474.661 856.709,474.98 856.686,475.301 856.661,475.621 856.635,475.941 856.615,476.19 
        856.589,476.44 856.556,476.688 856.509,476.933 856.477,477.063 856.442,477.191 856.405,477.32 856.369,477.449 856.33,477.592 
        856.292,477.734 856.252,477.876 856.21,478.018 856.173,478.131 "
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        856.173,478.131 856.132,478.242 856.088,478.354 856.042,478.463 856,478.553 855.958,478.642 855.912,478.729 855.865,478.816 
        855.821,478.89 855.776,478.962 855.729,479.032 855.679,479.102 855.635,479.158 855.59,479.214 855.542,479.269 855.494,479.321 
        855.441,479.376 855.385,479.429 855.329,479.479 855.27,479.528 855.207,479.579 855.142,479.627 855.076,479.674 855.008,479.718 
        854.931,479.765 854.854,479.81 854.775,479.852 854.695,479.893 854.597,479.938 854.499,479.981 854.398,480.022 854.297,480.061 
        854.174,480.104 854.051,480.144 853.926,480.181 853.801,480.216 853.64,480.256 853.478,480.294 853.316,480.327 853.153,480.358 
        852.941,480.395 852.729,480.427 852.516,480.456 852.302,480.482 852.041,480.512 851.778,480.536 851.516,480.558 
        851.254,480.575 850.917,480.593 850.581,480.604 850.242,480.612 "
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M849.624,465.636l0.137,0.002l0.138,0.002l0.138,0.002l0.137,0.004l0.111,0.005l0.111,0.006l0.11,0.007l0.111,0.008l0.092,0.008
        l0.092,0.01l0.092,0.01l0.092,0.011l0.114,0.016l0.114,0.018l0.113,0.021l0.113,0.024l0.063,0.016l0.063,0.018l0.063,0.019
        l0.062,0.021l0.063,0.024l0.063,0.026l0.06,0.031l0.057,0.036l0.032,0.025l0.031,0.026l0.028,0.029l0.027,0.03l0.023,0.03
        l0.021,0.03l0.02,0.031l0.019,0.032l0.024,0.047l0.021,0.049l0.019,0.049l0.016,0.051l0.016,0.059l0.012,0.061l0.009,0.061
        l0.004,0.061l0.001,0.022 M849.551,480.621L849.551,480.621h-0.003h-0.001h-0.001 M850.242,480.612l-0.343,0.005l-0.049,0.001
        h-0.049l-0.049,0.001h-0.047"
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        852.255,466.672 852.255,466.693 852.255,466.716 852.255,466.737 852.25,467.209 852.241,467.68 852.218,468.15 852.177,468.62 
        852.157,468.797 852.13,468.974 852.1,469.149 852.064,469.324 852.039,469.43 852.01,469.534 851.979,469.639 851.945,469.741 
        851.913,469.83 851.876,469.917 851.837,470.004 851.795,470.088 851.741,470.184 851.681,470.276 851.617,470.366 851.547,470.451 
        851.485,470.521 851.418,470.587 851.35,470.65 851.278,470.71 851.223,470.752 851.167,470.792 851.109,470.83 851.049,470.866 
        850.97,470.911 850.889,470.953 850.806,470.992 850.722,471.027 850.66,471.051 850.597,471.073 850.534,471.094 850.469,471.112 
        850.385,471.135 850.3,471.154 850.215,471.172 850.129,471.187 850.047,471.198 849.965,471.207 849.883,471.215 849.801,471.22 
        849.758,471.222 849.714,471.223 849.671,471.224 "
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        849.671,471.224 849.628,471.224 849.509,471.221 849.389,471.214 849.269,471.202 849.15,471.187 849.042,471.168 848.935,471.145 
        848.829,471.118 848.724,471.087 848.617,471.051 848.512,471.009 848.41,470.963 848.309,470.912 848.229,470.866 848.151,470.817 
        848.075,470.766 848.001,470.71 847.89,470.615 847.787,470.513 847.691,470.403 847.604,470.286 847.548,470.201 847.498,470.113 
        847.451,470.022 847.408,469.931 847.358,469.81 847.314,469.686 847.275,469.56 847.241,469.433 847.207,469.29 847.177,469.146 
        847.152,469.002 847.13,468.856 847.111,468.707 847.094,468.558 847.08,468.408 847.068,468.258 847.059,468.126 847.05,467.993 
        847.044,467.861 847.04,467.729 847.036,467.571 847.033,467.414 847.03,467.256 847.026,467.099 847.021,466.979 847.017,466.86 
        847.018,466.741 847.025,466.623 847.034,466.554 "
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        847.034,466.554 847.046,466.485 847.062,466.417 847.083,466.351 847.114,466.271 847.152,466.196 847.198,466.125 
        847.251,466.062 847.298,466.019 847.349,465.979 847.402,465.945 847.458,465.915 847.556,465.871 847.659,465.835 
        847.763,465.806 847.869,465.782 848.014,465.753 848.161,465.729 848.307,465.708 848.455,465.69 848.562,465.68 848.668,465.671 
        848.776,465.663 848.883,465.656 849.07,465.647 849.257,465.643 849.445,465.639 849.631,465.636 "
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="1013.894"
            y1="347.54"
            x2="1013.894"
            y2="356.895"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="790.807"
            y1="437.683"
            x2="840.98"
            y2="437.683"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M790.807,387.509L790.807,387.509 M840.98,437.683c0-27.71-22.463-50.174-50.173-50.174"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="497.988"
            y1="264.508"
            x2="526.334"
            y2="264.508"
          />
          <rect
            x="679.525"
            y="347.764"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 445.1853 -378.743)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="679.525"
            y="192.549"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 335.4315 -424.2045)"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M591.247,565.131v-5.559 M591.247,573.635v-5.669 M591.247,582.139v-5.669 M591.247,590.643v-5.669 M591.247,599.146v-5.669
        M591.247,607.65v-5.669 M591.247,612.297v-1.812"
          />
          <rect
            x="590.997"
            y="612.046"
            transform="matrix(0.7076 0.7066 -0.7066 0.7076 605.5438 -238.7474)"
            width="0.5"
            height="0.501"
          />
          <rect
            x="590.997"
            y="559.322"
            transform="matrix(0.7066 0.7076 -0.7076 0.7066 569.4104 -254.1951)"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1342.712,565.131v-5.669 M1342.712,573.635v-5.669 M1342.712,582.139v-5.669 M1342.712,590.643v-5.669 M1342.712,599.146v-5.669
        M1342.712,607.65v-5.669 M1342.712,612.297v-1.812"
          />
          <rect
            x="1342.462"
            y="612.046"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 826.2303 -770.1035)"
            width="0.5"
            height="0.501"
          />
          <rect
            x="1342.462"
            y="557.054"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 787.345 -786.2104)"
            width="0.5"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1005.513,612.297h5.67 M997.009,612.297h5.67 M988.505,612.297h5.67 M980.001,612.297h5.67 M971.498,612.297h5.67 M962.994,612.297
        h5.67 M954.49,612.297h5.67 M945.986,612.297h5.67 M937.482,612.297h5.67 M928.978,612.297h5.669 M920.474,612.297h5.669
        M911.97,612.297h5.669 M903.466,612.297h5.669 M894.962,612.297h5.669 M886.458,612.297h5.669 M877.955,612.297h5.669
        M869.451,612.297h5.669 M860.947,612.297h5.669 M852.443,612.297h5.669 M843.939,612.297h5.669 M835.435,612.297h5.669
        M826.931,612.297h5.669 M818.427,612.297h5.669 M809.923,612.297h5.669 M801.419,612.297h5.669 M792.916,612.297h5.669
        M784.412,612.297h5.669 M775.908,612.297h5.669 M767.404,612.297h5.669 M758.9,612.297h5.669 M750.395,612.297h5.67
        M741.891,612.297h5.67 M733.388,612.297h5.669 M724.884,612.297h5.669 M716.379,612.297h5.669 M707.875,612.297h5.669
        M699.372,612.297h5.669 M690.868,612.297h5.669 M682.364,612.297h5.669 M673.86,612.297h5.669 M665.356,612.297h5.669
        M656.852,612.297h5.669 M648.348,612.297h5.669 M639.844,612.297h5.669 M631.34,612.297h5.669 M622.836,612.297h5.669
        M614.333,612.297h5.669 M605.829,612.297h5.669 M597.325,612.297h5.669 M591.247,612.297h3.243"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1337.167,612.297h5.545 M1328.664,612.297h5.669 M1320.16,612.297h5.669 M1311.656,612.297h5.669 M1303.152,612.297h5.669
        M1294.647,612.297h5.67 M1286.143,612.297h5.67 M1277.639,612.297h5.67 M1269.135,612.297h5.67 M1260.631,612.297h5.67
        M1252.127,612.297h5.67 M1243.624,612.297h5.67 M1235.12,612.297h5.67 M1226.616,612.297h5.669 M1218.112,612.297h5.669
        M1209.608,612.297h5.669 M1201.104,612.297h5.669 M1192.6,612.297h5.669 M1184.096,612.297h5.669 M1175.592,612.297h5.669
        M1167.088,612.297h5.669 M1158.584,612.297h5.669 M1150.081,612.297h5.669 M1141.577,612.297h5.669 M1133.073,612.297h5.669
        M1124.569,612.297h5.669 M1116.065,612.297h5.669 M1107.561,612.297h5.669 M1099.057,612.297h5.669 M1090.553,612.297h5.669
        M1082.049,612.297h5.669 M1073.545,612.297h5.669 M1065.042,612.297h5.669 M1056.537,612.297h5.67 M1048.033,612.297h5.67
        M1039.529,612.297h5.67 M1031.025,612.297h5.67 M1022.521,612.297h5.67 M1014.017,612.297h5.67"
          />
          <rect
            x="590.997"
            y="612.046"
            transform="matrix(-0.7066 0.7076 -0.7076 -0.7066 1442.2916 626.5945)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="1342.463"
            y="612.046"
            transform="matrix(-0.7061 0.7081 -0.7081 -0.7061 2724.3999 93.9086)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="959.12"
            y="202.501"
            transform="matrix(0.7076 0.7066 -0.7066 0.7076 423.7941 -618.6239)"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#00FF00"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1379.687,2.622h1.101 M1374.017,2.622h1.418 M1368.348,2.622h1.417 M1362.679,2.622h1.417 M1357.009,2.622h1.418 M1351.34,2.622
        h1.417 M1345.671,2.622h1.417 M1340.001,2.622h1.418 M1334.333,2.622h1.417 M1328.664,2.622h1.417 M1322.994,2.622h1.418
        M1317.325,2.622h1.417 M1311.656,2.622h1.417 M1305.986,2.622h1.418 M1300.317,2.622h1.417 M1294.647,2.622h1.418 M1288.978,2.622
        h1.417 M1283.309,2.622h1.417 M1277.639,2.622h1.418 M1271.97,2.622h1.417 M1266.301,2.622h1.417 M1260.631,2.622h1.418
        M1254.962,2.622h1.417 M1249.293,2.622h1.417 M1243.624,2.622h1.418 M1237.955,2.622h1.417 M1232.285,2.622h1.418 M1226.616,2.622
        h1.417 M1220.947,2.622h1.417 M1215.277,2.622h1.418 M1209.608,2.622h1.417 M1203.939,2.622h1.417"
          />
          <rect
            x="1380.537"
            y="2.372"
            transform="matrix(-0.7076 0.7066 -0.7066 -0.7076 2359.6772 -971.2136)"
            fill="#00FF00"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#00FF00"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1381.507,276.157l-0.004-1.418 M1381.522,281.826l-0.004-1.417 M1381.537,287.495l-0.004-1.417 M1381.551,293.165l-0.003-1.418
        M1381.566,298.834l-0.003-1.417 M1381.582,304.503l-0.004-1.417 M1381.596,310.173l-0.004-1.418 M1381.611,315.842l-0.004-1.417
        M1381.626,321.511l-0.004-1.417 M1381.641,327.181l-0.004-1.418 M1381.656,332.85l-0.004-1.417 M1381.671,338.519l-0.004-1.417
        M1381.686,344.188l-0.004-1.418 M1381.701,349.857l-0.004-1.417 M1381.715,355.526l-0.003-1.417 M1381.73,361.196l-0.003-1.418
        M1381.746,366.865l-0.004-1.417 M1381.76,372.534l-0.004-1.417 M1381.775,378.204l-0.004-1.418 M1381.791,383.873l-0.004-1.417
        M1381.805,389.542l-0.004-1.417 M1381.82,395.212l-0.004-1.418 M1381.835,400.881l-0.004-1.417 M1381.85,406.55l-0.004-1.417
        M1381.865,412.22l-0.004-1.418 M1381.879,417.889l-0.003-1.417 M1381.894,423.558l-0.003-1.417 M1381.91,429.228l-0.004-1.418
        M1381.924,434.896l-0.004-1.417 M1381.939,440.565l-0.004-1.417 M1381.955,446.235l-0.004-1.418 M1381.969,451.904l-0.004-1.417
        M1381.984,457.573l-0.004-1.417 M1382,463.243l-0.004-1.418 M1382.014,468.912l-0.004-1.417 M1382.029,474.581l-0.004-1.417
        M1382.043,480.251l-0.004-1.418 M1382.058,485.92l-0.003-1.417 M1382.074,491.589l-0.004-1.417 M1382.088,497.259l-0.004-1.418
        M1382.103,502.928l-0.004-1.417 M1382.119,508.597l-0.004-1.417 M1382.133,514.267l-0.004-1.418 M1382.148,519.936l-0.004-1.417
        M1382.163,525.604l-0.003-1.417 M1382.177,531.274l-0.003-1.418 M1382.193,536.943l-0.004-1.417 M1382.208,542.612l-0.004-1.417
        M1382.222,548.282l-0.004-1.418 M1382.238,553.951l-0.004-1.417"
          />
          <path
            fill="none"
            stroke="#00FF00"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1380.792,4.032l-0.004-1.41 M1380.806,9.701l-0.004-1.417 M1380.821,15.37l-0.003-1.417 M1380.835,21.04l-0.003-1.417
        M1380.851,26.709l-0.004-1.417 M1380.866,32.378l-0.004-1.417 M1380.88,38.047l-0.004-1.417 M1380.896,43.717l-0.004-1.417
        M1380.911,49.386l-0.004-1.417 M1380.925,55.055l-0.004-1.417 M1380.941,60.725l-0.004-1.417 M1380.956,66.394l-0.004-1.417
        M1380.97,72.063l-0.004-1.417 M1380.985,77.732l-0.004-1.417 M1381,83.401l-0.003-1.417 M1381.015,89.071l-0.004-1.417
        M1381.03,94.74l-0.004-1.417 M1381.044,100.41l-0.004-1.417 M1381.06,106.079l-0.004-1.417 M1381.075,111.748l-0.004-1.417
        M1381.089,117.417l-0.004-1.417 M1381.104,123.086l-0.003-1.417 M1381.119,128.756l-0.003-1.417 M1381.134,134.425l-0.004-1.417
        M1381.149,140.094l-0.004-1.417 M1381.164,145.764l-0.004-1.417 M1381.179,151.433l-0.004-1.417 M1381.194,157.102l-0.004-1.417
        M1381.208,162.771l-0.004-1.417 M1381.224,168.441l-0.004-1.417 M1381.239,174.11l-0.004-1.417 M1381.253,179.779l-0.004-1.417
        M1381.268,185.449l-0.003-1.417 M1381.283,191.118l-0.003-1.417 M1381.298,196.787l-0.004-1.417 M1381.313,202.457l-0.004-1.417
        M1381.328,208.125l-0.004-1.417 M1381.343,213.795l-0.004-1.417 M1381.358,219.464l-0.004-1.417 M1381.373,225.133l-0.004-1.417
        M1381.388,230.803l-0.004-1.417 M1381.403,236.472l-0.004-1.417 M1381.417,242.141l-0.004-1.417 M1381.432,247.811l-0.003-1.417
        M1381.447,253.48l-0.003-1.417 M1381.462,259.149l-0.004-1.417 M1381.477,264.818l-0.004-1.417 M1381.492,270.487l-0.004-1.417"
          />
          <rect
            x="1380.537"
            y="2.372"
            transform="matrix(0.7091 0.7052 -0.7052 0.7091 403.5865 -972.9079)"
            fill="#00FF00"
            width="0.501"
            height="0.501"
          />
          <rect
            x="70.443"
            y="557.054"
            transform="matrix(-0.7066 0.7076 -0.7076 -0.7066 514.9913 901.084)"
            fill="#00FF00"
            width="0.501"
            height="0.501"
          />
          <rect
            x="70.443"
            y="557.054"
            transform="matrix(0.7076 0.7066 -0.7066 0.7076 414.4725 113.0058)"
            fill="#00FF00"
            width="0.5"
            height="0.501"
          />
          <rect
            x="70.443"
            y="553.757"
            transform="matrix(-0.6963 0.7178 -0.7178 -0.6963 517.5569 889.0214)"
            fill="#00FF00"
            width="0.501"
            height="0.501"
          />
          <rect
            x="772.698"
            y="527.574"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 599.6195 -391.9611)"
            fill="#00FF00"
            width="0.501"
            height="0.501"
          />
          <rect
            x="1004.016"
            y="300.406"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1926.9873 -196.8717)"
            fill="#00FF00"
            width="0.501"
            height="0.501"
          />
          <rect
            x="1004.016"
            y="300.406"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 506.7391 -622.0639)"
            fill="#00FF00"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M692.565,129.232v14.293 M668.328,129.232l0.058-0.571l0.166-0.532l0.261-0.481l0.346-0.419l0.419-0.346l0.481-0.261l0.532-0.166
        l0.571-0.058 M668.328,129.232v14.293 M671.163,146.361l-0.571-0.058l-0.532-0.165l-0.481-0.262l-0.419-0.346l-0.346-0.419
        l-0.261-0.481l-0.166-0.532l-0.058-0.571 M671.163,146.361h18.567 M692.565,143.526l-0.058,0.571l-0.166,0.532l-0.261,0.481
        l-0.346,0.419l-0.419,0.346l-0.481,0.262l-0.532,0.165l-0.571,0.058 M689.73,148.901l0.571,0.058l0.532,0.165l0.481,0.261
        l0.419,0.346l0.346,0.419l0.261,0.482l0.166,0.532l0.058,0.571 M689.73,148.901h-18.567 M692.565,151.736v5.669 M668.328,151.736
        l0.058-0.571l0.166-0.532l0.261-0.482l0.346-0.419l0.419-0.346l0.481-0.261l0.532-0.165l0.571-0.058 M668.328,151.736v5.669
        M669.158,159.41l-0.346-0.419l-0.261-0.481l-0.166-0.532l-0.058-0.571"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M688.68,151.374l0.344,0.035l0.32,0.099l0.29,0.157l0.252,0.208l0.208,0.252l0.157,0.29l0.1,0.32l0.035,0.344l-0.035,0.343
        l-0.1,0.32l-0.157,0.29l-0.208,0.252l-0.252,0.208l-0.29,0.157l-0.32,0.099l-0.344,0.035l-0.343-0.035l-0.32-0.099l-0.29-0.157
        l-0.252-0.208l-0.208-0.252l-0.157-0.29l-0.1-0.32l-0.034-0.343l0.034-0.344l0.1-0.32l0.157-0.29l0.208-0.252l0.252-0.208
        l0.29-0.157l0.32-0.099L688.68,151.374 M668.328,162.097v24.234 M671.33,162.097v24.234 M674.331,162.097v24.234 M677.333,162.097
        v24.234 M680.334,162.097v24.234 M683.336,162.097v24.234 M686.337,162.097v24.234 M689.339,162.097v24.234 M692.34,162.097v24.234
        M689.73,126.397l0.571,0.058l0.532,0.166l0.481,0.261l0.419,0.346l0.346,0.419l0.261,0.481l0.166,0.532l0.058,0.571
        M689.73,126.397h-18.567"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M663.532,110.422V59.398 M695.847,61.099h-30.614 M640.854,189.792V48.06h56.693v141.732 M748.571,256.288V19.063 M748.571,251.061
        h34.016 M748.571,217.045h34.016 M748.571,183.03h34.016 M748.571,154.683h34.016 M748.571,120.667h34.016 M748.571,92.321h34.016
        M748.571,58.305h34.016 M748.571,24.29h34.016 M663.532,189.792h34.016V121.76h-34.016V189.792 M688.68,143.886l-0.343-0.035
        l-0.32-0.099l-0.29-0.157l-0.252-0.208l-0.208-0.252l-0.157-0.29l-0.1-0.32l-0.034-0.343l0.034-0.344l0.1-0.32l0.157-0.29
        l0.208-0.252l0.252-0.208l0.29-0.157l0.32-0.099l0.343-0.035l0.344,0.035l0.32,0.099l0.29,0.157l0.252,0.208l0.208,0.252l0.157,0.29
        l0.1,0.32l0.035,0.344l-0.035,0.343l-0.1,0.32l-0.157,0.29l-0.208,0.252l-0.252,0.208l-0.29,0.157l-0.32,0.099L688.68,143.886"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M665.232,61.099v47.622 M665.232,108.721h30.614 M695.847,61.099v47.622 M663.532,59.398h34.016v51.023h-34.016"
          />
          <path
            fill="none"
            stroke="#C0C0C0"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M748.571,58.305l34.016,34.016 M748.571,92.321l34.016-34.016 M748.571,24.29l34.016,34.016 M748.571,58.305l34.016-34.016
        M748.571,183.03l34.016,34.016 M748.571,217.045l34.016-34.016 M748.571,217.045l34.016,34.016 M748.571,251.061l34.016-34.016"
          />
          <rect
            x="753.402"
            y="505.262"
            transform="matrix(0.7061 0.7081 -0.7081 0.7061 579.4192 -385.0923)"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M6.176,554.996"
          />
          <rect
            x="77.108"
            y="549.717"
            transform="matrix(-0.7069 0.7074 -0.7074 -0.7069 521.6653 884.2494)"
            fill="#821EB4"
            width="1.002"
            height="1.002"
          />
          <rect
            x="15.029"
            y="360.646"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 259.9183 94.7956)"
            fill="#821EB4"
            width="1.003"
            height="1.002"
          />
          <rect
            x="207.502"
            y="549.716"
            transform="matrix(-0.7069 0.7074 -0.7074 -0.7069 744.2297 792.0153)"
            fill="#821EB4"
            width="1.002"
            height="1.003"
          />
          <rect
            x="298.21"
            y="549.717"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 898.9954 728.0594)"
            fill="#821EB4"
            width="1.003"
            height="1.002"
          />
          <rect
            x="483.597"
            y="549.717"
            transform="matrix(-0.7069 0.7074 -0.7074 -0.7069 1215.4868 596.7191)"
            fill="#821EB4"
            width="1.002"
            height="1.002"
          />
          <rect
            x="586.069"
            y="549.716"
            transform="matrix(-0.7069 0.7074 -0.7074 -0.7069 1390.3916 524.2358)"
            fill="#821EB4"
            width="1.002"
            height="1.003"
          />
          <rect
            x="489.266"
            y="259.897"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1020.2151 98.209)"
            fill="#821EB4"
            width="1.003"
            height="1.002"
          />
          <rect
            x="685.141"
            y="259.897"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1354.593 -40.2949)"
            fill="#821EB4"
            width="1.003"
            height="1.002"
          />
          <rect
            x="230.463"
            y="9.208"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 401.1465 -146.7424)"
            fill="#821EB4"
            width="1.003"
            height="1.002"
          />
          <rect
            x="301.896"
            y="9.208"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 523.0905 -197.2532)"
            fill="#821EB4"
            width="1.003"
            height="1.002"
          />
          <rect
            x="483.314"
            y="9.208"
            transform="matrix(-0.7069 0.7074 -0.7074 -0.7069 832.674 -325.6549)"
            fill="#821EB4"
            width="1.002"
            height="1.003"
          />
          <rect
            x="575.723"
            y="9.208"
            transform="matrix(-0.7069 0.7074 -0.7074 -0.7069 990.4042 -391.0208)"
            fill="#821EB4"
            width="1.002"
            height="1.002"
          />
          <rect
            x="742.401"
            y="9.208"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1275.0781 -508.7366)"
            fill="#821EB4"
            width="1.003"
            height="1.003"
          />
          <rect
            x="860.605"
            y="9.208"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1476.866 -592.3199)"
            fill="#821EB4"
            width="1.003"
            height="1.002"
          />
          <rect
            x="1026.149"
            y="550.567"
            transform="matrix(0.7076 0.7066 -0.7066 0.7076 689.5939 -564.3149)"
            fill="#821EB4"
            width="1.002"
            height="1.003"
          />
          <rect
            x="1026.149"
            y="267.171"
            transform="matrix(0.7074 0.7069 -0.7069 0.7074 489.6562 -647.3669)"
            fill="#821EB4"
            width="1.002"
            height="1.002"
          />
          <rect
            x="489.518"
            y="256.037"
            transform="matrix(0.7076 0.7066 -0.7066 0.7076 324.3087 -271.1396)"
            fill="#FF8000"
            width="0.501"
            height="0.501"
          />
          <rect
            x="489.518"
            y="18.813"
            transform="matrix(0.7076 0.7066 -0.7066 0.7076 156.6816 -340.5053)"
            fill="#FF8000"
            width="0.501"
            height="0.501"
          />
          <rect
            x="503.124"
            y="256.037"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 328.6573 -280.8743)"
            fill="#FF8000"
            width="0.501"
            height="0.501"
          />
          <rect
            x="503.124"
            y="18.813"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 160.9146 -350.3557)"
            fill="#FF8000"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1005.513,559.572h5.67 M997.009,559.572h5.67 M988.505,559.572h5.67 M980.001,559.572h5.67 M971.498,559.572h5.67 M962.994,559.572
        h5.67 M954.49,559.572h5.67 M945.986,559.572h5.67 M937.482,559.572h5.67 M928.978,559.572h5.669 M920.474,559.572h5.669
        M911.97,559.572h5.669 M903.466,559.572h5.669 M894.962,559.572h5.669 M886.458,559.572h5.669 M877.955,559.572h5.669
        M869.451,559.572h5.669 M860.947,559.572h5.669 M852.443,559.572h5.669 M843.939,559.572h5.669 M835.435,559.572h5.669
        M826.931,559.572h5.669 M818.427,559.572h5.669 M809.923,559.572h5.669 M801.419,559.572h5.669 M792.916,559.572h5.669
        M784.412,559.572h5.669 M775.908,559.572h5.669 M767.404,559.572h5.669 M758.9,559.572h5.669 M750.395,559.572h5.67
        M741.891,559.572h5.67 M733.388,559.572h5.669 M724.884,559.572h5.669 M716.379,559.572h5.669 M707.875,559.572h5.669
        M699.372,559.572h5.669 M690.868,559.572h5.669 M682.364,559.572h5.669 M673.86,559.572h5.669 M665.356,559.572h5.669
        M656.852,559.572h5.669 M648.348,559.572h5.669 M639.844,559.572h5.669 M631.34,559.572h5.669 M622.836,559.572h5.669
        M614.333,559.572h5.669 M605.829,559.572h5.669 M597.325,559.572h5.669 M591.247,559.572h3.243"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1337.167,559.572h5.551 M1328.664,559.572h5.669 M1320.16,559.572h5.669 M1311.656,559.572h5.669 M1303.152,559.572h5.669
        M1294.647,559.572h5.67 M1286.143,559.572h5.67 M1277.639,559.572h5.67 M1269.135,559.572h5.67 M1260.631,559.572h5.67
        M1252.127,559.572h5.67 M1243.624,559.572h5.67 M1235.12,559.572h5.67 M1226.616,559.572h5.669 M1218.112,559.572h5.669
        M1209.608,559.572h5.669 M1201.104,559.572h5.669 M1192.6,559.572h5.669 M1184.096,559.572h5.669 M1175.592,559.572h5.669
        M1167.088,559.572h5.669 M1158.584,559.572h5.669 M1150.081,559.572h5.669 M1141.577,559.572h5.669 M1133.073,559.572h5.669
        M1124.569,559.572h5.669 M1116.065,559.572h5.669 M1107.561,559.572h5.669 M1099.057,559.572h5.669 M1090.553,559.572h5.669
        M1082.049,559.572h5.669 M1073.545,559.572h5.669 M1065.042,559.572h5.669 M1056.537,559.572h5.67 M1048.033,559.572h5.67
        M1039.529,559.572h5.67 M1031.025,559.572h5.67 M1022.521,559.572h5.67 M1014.017,559.572h5.67"
          />
          <rect
            x="590.997"
            y="559.322"
            transform="matrix(-0.7066 0.7076 -0.7076 -0.7066 1404.984 536.6137)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="1342.468"
            y="559.322"
            transform="matrix(-0.7061 0.7081 -0.7081 -0.7061 2687.0767 3.9494)"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M241.169,95.745h4.252 M230.965,95.745h-4.252"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="230.965"
            y1="95.745"
            x2="241.169"
            y2="95.745"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.5102"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M222.003,361.146h4.252 M222.003,361.146h-4.252 M222.003,371.352h4.252 M222.003,371.352h-4.252 M224.554,363.698l-5.102-5.103
        M224.554,373.902l-5.102-5.102"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M222.003,361.146v-4.252 M222.003,371.352v4.252"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="222.003"
            y1="371.352"
            x2="222.003"
            y2="361.146"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.5102"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M698.549,540.863h4.252 M698.549,540.863h-4.252 M698.549,551.068h4.252 M698.549,551.068h-4.252 M701.1,543.415l-5.102-5.103
        M701.1,553.619l-5.102-5.103"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M698.549,540.863v-4.252 M698.549,551.068v4.252"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="698.549"
            y1="551.068"
            x2="698.549"
            y2="540.863"
          />
          <rect
            x="58.65"
            y="379.605"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 285.85 69.6079)"
            fill="#656565"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#966432"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M961.147,264.597c1.565,0,2.835-1.269,2.835-2.835 M958.313,261.762c0,1.566,1.269,2.835,2.834,2.835 M961.147,258.928
        c-1.565,0-2.834,1.269-2.834,2.834 M963.982,261.762c0-1.565-1.27-2.834-2.835-2.834"
          />
          <path
            fill="none"
            stroke="#966432"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M765.372,97.546c1.565,0,2.835-1.269,2.835-2.835 M762.537,94.711c0,1.566,1.27,2.835,2.835,2.835 M765.372,91.877
        c-1.565,0-2.835,1.269-2.835,2.834 M768.207,94.711c0-1.565-1.27-2.834-2.835-2.834"
          />
          <path
            fill="none"
            stroke="#966432"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M809.61,454.58v-5.67 M809.61,462.01v-4.596"
          />
          <rect
            x="809.359"
            y="461.759"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 563.8195 -437.1611)"
            fill="#966432"
            width="0.501"
            height="0.501"
          />
          <rect
            x="809.359"
            y="446.451"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 552.9954 -441.6446)"
            fill="#966432"
            width="0.501"
            height="0.501"
          />
          <rect
            x="809.359"
            y="446.452"
            transform="matrix(-0.7032 0.711 -0.711 -0.7032 1696.5295 185.1979)"
            fill="#966432"
            width="0.501"
            height="0.502"
          />
          <rect
            x="849.822"
            y="455.909"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 571.5338 -467.4863)"
            fill="#966432"
            width="0.501"
            height="0.501"
          />
          <rect
            x="880.256"
            y="446.271"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1818.8567 139.6479)"
            fill="#966432"
            width="0.501"
            height="0.501"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="628.949"
            y1="256.288"
            x2="628.949"
            y2="265.642"
          />
          <path
            fill="none"
            stroke="#656565"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1014.017,177.803h3.278 M1005.513,177.803h5.67 M997.009,177.803h5.67 M988.505,177.803h5.67 M980.001,177.803h5.67
        M971.498,177.803h5.67 M962.994,177.803h5.67 M954.49,177.803h5.67 M945.986,177.803h5.67 M937.482,177.803h5.67 M928.978,177.803
        h5.669 M920.474,177.803h5.669 M911.97,177.803h5.669 M903.91,177.803h5.226"
          />
          <rect
            x="903.659"
            y="177.553"
            transform="matrix(-0.7076 0.7066 -0.7066 -0.7076 1669.1499 -335.1042)"
            fill="#656565"
            width="0.501"
            height="0.501"
          />
          <rect
            x="1017.045"
            y="177.553"
            transform="matrix(-0.7076 0.7066 -0.7066 -0.7076 1862.7666 -415.2247)"
            fill="#656565"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#656565"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M903.91,71.902v-5.669 M903.91,80.406v-5.669 M903.91,88.91v-5.669 M903.91,97.414v-5.669 M903.91,105.918v-5.669 M903.91,114.422
        v-5.669 M903.91,122.926v-5.669 M903.91,131.43v-5.669 M903.91,139.934v-5.669 M903.91,148.438v-5.669 M903.91,156.942v-5.669
        M903.91,165.446v-5.669 M903.91,173.95v-5.669 M903.91,177.803v-1.019"
          />
          <rect
            x="903.659"
            y="177.553"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 390.475 -587.0832)"
            fill="#656565"
            width="0.501"
            height="0.501"
          />
          <rect
            x="903.659"
            y="64.167"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 310.2993 -620.2932)"
            fill="#656565"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#656565"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1014.017,64.417h3.278 M1005.513,64.417h5.67 M997.009,64.417h5.67 M988.505,64.417h5.67 M980.001,64.417h5.67 M971.498,64.417
        h5.67 M962.994,64.417h5.67 M954.49,64.417h5.67 M945.986,64.417h5.67 M937.482,64.417h5.67 M928.978,64.417h5.669 M920.474,64.417
        h5.669 M911.97,64.417h5.669 M903.91,64.417h5.226"
          />
          <rect
            x="903.659"
            y="64.167"
            transform="matrix(-0.7076 0.7066 -0.7066 -0.7076 1589.0293 -528.721)"
            fill="#656565"
            width="0.501"
            height="0.501"
          />
          <rect
            x="1017.045"
            y="64.167"
            transform="matrix(-0.7076 0.7066 -0.7066 -0.7076 1782.6461 -608.8416)"
            fill="#656565"
            width="0.501"
            height="0.501"
          />
          <rect
            x="824.572"
            y="18.813"
            transform="matrix(0.7076 0.7066 -0.7066 0.7076 254.6536 -577.2614)"
            fill="#656565"
            width="0.501"
            height="0.501"
          />
          <rect
            x="790.557"
            y="217.238"
            transform="matrix(-0.7076 0.7066 -0.7066 -0.7076 1504.0588 -187.4178)"
            fill="#656565"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#966432"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M688.667,145.312c1.566,0,2.835-1.269,2.835-2.834 M685.833,142.477c0,1.565,1.269,2.834,2.834,2.834 M688.667,139.642
        c-1.565,0-2.834,1.269-2.834,2.835 M691.502,142.477c0-1.566-1.269-2.835-2.835-2.835"
          />
          <rect
            x="688.417"
            y="142.226"
            transform="matrix(-0.2271 0.9739 -0.9739 -0.2271 983.8417 -495.8286)"
            fill="#966432"
            width="0.501"
            height="0.501"
          />
          <rect
            x="765.121"
            y="94.461"
            transform="matrix(-0.226 0.9741 -0.9741 -0.226 1030.605 -629.4535)"
            fill="#966432"
            width="0.501"
            height="0.501"
          />
          <rect
            x="595.532"
            y="340.226"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 415.254 -321.5587)"
            width="0.501"
            height="0.501"
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        595.783,340.477 595.783,527.824 675.872,527.824 "
          />
          <rect x="597.11" y="441.411" fill="#FFFFFF" width="13.228" height="38.15" />
          <rect
            x="634.439"
            y="261.918"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1268.6948 -1.3133)"
            width="0.361"
            height="0.361"
          />
          <rect
            x="686.867"
            y="261.918"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1358.1958 -38.3858)"
            width="0.361"
            height="0.361"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.5102"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="634.62"
            y1="262.099"
            x2="634.62"
            y2="261.824"
          />
          <rect
            x="634.439"
            y="261.918"
            transform="matrix(0.7078 0.7064 -0.7064 0.7078 370.6007 -371.7246)"
            width="0.361"
            height="0.361"
          />
          <rect
            x="634.439"
            y="259.65"
            transform="matrix(0.7078 0.7064 -0.7064 0.7078 368.9988 -372.3873)"
            width="0.361"
            height="0.361"
          />
          <rect
            x="634.439"
            y="259.651"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1267.0913 -5.1843)"
            width="0.361"
            height="0.361"
          />
          <rect
            x="686.866"
            y="259.651"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1356.5903 -42.256)"
            width="0.361"
            height="0.361"
          />
          <rect
            x="966.996"
            y="353.171"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1900.9297 -80.6877)"
            width="0.36"
            height="0.361"
          />
          <rect
            x="1016.264"
            y="353.171"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1985.0354 -115.5255)"
            width="0.36"
            height="0.36"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.5102"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="1016.444"
            y1="352.532"
            x2="1016.444"
            y2="351.084"
          />
          <rect
            x="1016.264"
            y="353.171"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 547.5668 -615.2401)"
            width="0.36"
            height="0.36"
          />
          <rect
            x="1016.264"
            y="350.904"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 545.9634 -615.9042)"
            width="0.36"
            height="0.36"
          />
          <rect
            x="966.996"
            y="350.904"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1899.327 -84.5591)"
            width="0.36"
            height="0.36"
          />
          <rect
            x="1016.264"
            y="350.904"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1983.432 -119.3965)"
            width="0.36"
            height="0.36"
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.5102"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        923.635,351.991 928.909,350.898 928.909,353.083 923.635,351.991 960.549,351.991 "
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1243.216,5.344h51.023 M1243.216,6.931h51.023 M1243.216,3.756h51.023 M1298.776,3.756h-4.536v3.175h4.536V3.756 M1238.681,3.756
        h4.535v3.175h-4.535V3.756"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="1367.403"
            y1="193.903"
            x2="1381.009"
            y2="193.903"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M591.247,364.439v-16.44 M591.247,347.999h56.693v16.44 M591.247,347.999v-16.441 M591.247,331.558h56.693v16.441 M890.077,539.276
        h124.044 M890.077,537.688h124.044 M890.077,540.863h124.044 M1017.295,540.863h-3.175v-3.175h3.175V540.863 M886.902,540.863h3.175
        v-3.175h-3.175V540.863 M801.069,539.276h67.351 M801.069,537.688h67.351 M801.069,540.863h67.351 M871.594,540.863h-3.175v-3.175
        h3.175V540.863 M797.894,540.863h3.175v-3.175h-3.175V540.863 M669.257,539.276h30.5 M669.257,537.688h30.5 M669.257,540.863h30.5
        M702.933,540.863h-3.175v-3.175h3.175V540.863 M666.083,540.863h3.175v-3.175h-3.175V540.863 M617.099,539.276H647.6
        M617.099,537.688H647.6 M617.099,540.863H647.6 M650.775,540.863H647.6v-3.175h3.175V540.863 M613.924,540.863h3.175v-3.175h-3.175
        V540.863"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M619.594,412.06v-1.417 M619.594,417.729v-1.418 M619.594,423.398v-1.417 M619.594,429.067v-1.417 M619.594,434.737v-1.418
        M619.594,440.406v-1.417 M619.594,446.076v-1.418 M619.594,451.745v-1.417 M619.594,457.414v-1.417 M619.594,463.084v-1.418
        M619.594,468.753v-1.417 M675.872,501.179l-6.576-6.577v13.153L675.872,501.179 M655.607,529.524l7.646-56.692h12.618v56.692
        H655.607 M655.607,529.524h-27.24 M628.367,529.524l28.856-56.692h6.03 M628.367,529.524h-37.12v-3.127 M591.247,526.397
        l60.662-53.565h5.315 M591.247,526.397v-33.93 M591.247,492.468l56.693-25.305v5.669h3.969 M591.247,492.468v-24.608
        M591.247,467.859l56.693-9.548v8.852 M591.247,467.859v-21.215 M591.247,446.645h56.693v11.667 M591.247,446.645v-16.441
        M591.247,430.203h56.693v16.441 M591.247,430.203v-16.44 M591.247,413.763h56.693v16.44 M591.247,413.763v-16.441 M591.247,397.321
        h56.693v16.441 M591.247,397.321v-16.44 M591.247,380.881h56.693v16.44 M591.247,380.881v-16.441 M591.247,364.439h56.693v16.441"
          />
          <rect
            x="619.343"
            y="472.581"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 515.8176 -299.6296)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="647.689"
            y="314.867"
            transform="matrix(0.7066 0.7076 -0.7076 0.7066 413.0677 -366.0292)"
            width="0.501"
            height="0.5"
          />
          <rect
            x="590.997"
            y="314.867"
            transform="matrix(0.7066 0.7076 -0.7076 0.7066 396.4351 -325.9137)"
            width="0.501"
            height="0.5"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M642.679,315.116h1.417 M637.01,315.116h1.417 M631.34,315.116h1.417 M625.671,315.116h1.417 M620.001,315.116h1.417
        M614.333,315.116h1.417 M608.663,315.116h1.417 M602.994,315.116h1.417 M597.325,315.116h1.417 M591.655,315.116h1.417"
          />
          <rect
            x="590.997"
            y="314.866"
            transform="matrix(-0.7076 0.7066 -0.7066 -0.7076 1232.2777 120.3044)"
            width="0.5"
            height="0.501"
          />
          <rect
            x="647.69"
            y="314.866"
            transform="matrix(-0.7076 0.7066 -0.7066 -0.7076 1329.0862 80.2442)"
            width="0.5"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M647.94,315.682v-0.565 M647.94,321.352v-1.418 M647.94,327.021v-1.417 M647.94,331.558v-0.285"
          />
          <rect
            x="647.689"
            y="331.307"
            transform="matrix(0.7066 0.7076 -0.7076 0.7066 424.7012 -361.2057)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="647.689"
            y="314.867"
            transform="matrix(0.7066 0.7076 -0.7076 0.7066 413.0677 -366.0292)"
            width="0.501"
            height="0.5"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M591.247,315.682v-0.565 M591.247,321.352v-1.418 M591.247,327.021v-1.417 M591.247,331.558v-0.285"
          />
          <rect
            x="590.997"
            y="331.307"
            transform="matrix(0.7066 0.7076 -0.7076 0.7066 408.0686 -321.0902)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="590.997"
            y="314.867"
            transform="matrix(0.7066 0.7076 -0.7076 0.7066 396.4351 -325.9137)"
            width="0.501"
            height="0.5"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M671.025,501.179h1.417 M665.356,501.179h1.417 M659.687,501.179h1.417 M654.018,501.179h1.417 M648.348,501.179h1.417"
          />
          <rect
            x="647.69"
            y="500.928"
            transform="matrix(-0.7066 0.7076 -0.7076 -0.7066 1460.4182 396.8427)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="675.621"
            y="500.928"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1508.1719 377.6522)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="256.793"
            y="432.896"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 745.0814 557.6701)"
            fill="#656565"
            width="0.501"
            height="0.501"
          />
          <rect
            x="256.793"
            y="432.896"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 381.5671 -54.8915)"
            fill="#656565"
            width="0.501"
            height="0.501"
          />
          <rect
            x="286.84"
            y="540.613"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 466.535 -44.5887)"
            fill="#656565"
            width="0.501"
            height="0.501"
          />
          <rect
            x="252.824"
            y="444.235"
            transform="matrix(-0.7066 0.7076 -0.7076 -0.7066 746.4177 579.4929)"
            fill="#656565"
            width="0.501"
            height="0.501"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="244.854"
            y1="385.524"
            x2="194.681"
            y2="385.524"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M244.854,435.698L244.854,435.698 M194.681,385.524c0,27.71,22.463,50.174,50.173,50.174"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M865.642,12.146h126.992 M865.642,13.734h126.992 M865.642,10.56h126.992 M997.169,10.56h-4.535v3.175h4.535V10.56 M861.107,10.56
        h4.535v3.175h-4.535V10.56 M580.76,12.146h157.606 M580.76,13.734h157.606 M580.76,10.56h157.606 M742.902,10.56h-4.535v3.175h4.535
        V10.56 M576.225,10.56h4.536v3.175h-4.536V10.56 M306.933,12.146H479.28 M306.933,13.734H479.28 M306.933,10.56H479.28
        M483.815,10.56h-4.535v3.175h4.535V10.56 M302.398,10.56h4.535v3.175h-4.535V10.56"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M447.248,290.304l34.016-0.021 M481.264,256.288h-34.016 M447.248,256.288v34.016 M481.264,256.288v33.996"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="249.702"
            y1="19.063"
            x2="249.702"
            y2="74.848"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M903.91,378.013v28.347h-17.008 M906.745,375.179v34.016h-22.678 M886.902,378.013h17.008 M884.067,375.179h22.678 M886.902,406.359
        v-28.347"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M888.893,392.187c0,0.691,0.56,1.251,1.251,1.251 M890.144,390.935c-0.691,0-1.251,0.561-1.251,1.252 M891.396,392.187
        c0-0.691-0.561-1.252-1.252-1.252 M890.144,393.438c0.691,0,1.252-0.56,1.252-1.251"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M903.91,423.367v28.347h-17.008 M906.745,420.532v34.017h-22.678 M886.902,423.367h17.008 M884.067,420.532h22.678 M886.902,451.714
        v-28.347"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M888.893,437.54c0,0.691,0.56,1.252,1.251,1.252 M890.144,436.289c-0.691,0-1.251,0.561-1.251,1.251 M891.396,437.54
        c0-0.69-0.561-1.251-1.252-1.251 M890.144,438.792c0.691,0,1.252-0.561,1.252-1.252"
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        884.067,363.84 912.414,363.84 912.414,465.887 884.067,465.887 "
          />
          <rect
            x="889.894"
            y="391.936"
            transform="matrix(-0.7061 0.7081 -0.7081 -0.7061 1796.4031 38.8268)"
            fill="#966432"
            width="0.501"
            height="0.501"
          />
          <rect
            x="889.895"
            y="437.29"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1828.9592 117.5003)"
            fill="#966432"
            width="0.5"
            height="0.501"
          />
          <rect
            x="880.256"
            y="483.9"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 600.2404 -480.8076)"
            fill="#966432"
            width="0.501"
            height="0.501"
          />
          <rect
            x="889.894"
            y="483.9"
            transform="matrix(-0.7061 0.7081 -0.7081 -0.7061 1861.521 195.7292)"
            fill="#966432"
            width="0.501"
            height="0.501"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="1017.295"
            y1="460.269"
            x2="1017.295"
            y2="361.005"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M978.875,438.69l-0.348-0.119l-0.343-0.129l-0.335-0.146l-0.32-0.173l-0.259-0.175l-0.243-0.198l-0.224-0.22l-0.202-0.239
        l-0.209-0.31l-0.178-0.328l-0.149-0.344l-0.124-0.354l-0.14-0.502l-0.105-0.51l-0.079-0.514l-0.059-0.518l-0.037-0.418l-0.032-0.419
        l-0.029-0.419l-0.028-0.419l-0.029-0.407l-0.027-0.407l-0.022-0.408l-0.014-0.408l-0.003-0.263v-0.263l0.004-0.264l0.005-0.263
        M1005.957,419.257v19.842l-2.577,0.056l-2.577,0.054l-2.578,0.05l-2.579,0.044l-2.579,0.037l-2.581,0.026l-2.583,0.017
        l-0.645,0.001l-0.646-0.001l-0.645-0.006l-0.645-0.012l-0.644-0.021l-0.643-0.031l-0.642-0.044l-0.676-0.061l-0.674-0.075
        l-0.674-0.086l-0.673-0.096l-0.672-0.102l-0.672-0.107l-0.672-0.108"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M978.875,419.665l-0.348,0.119l-0.343,0.129l-0.335,0.146l-0.32,0.173l-0.259,0.175l-0.243,0.198l-0.224,0.22l-0.202,0.24
        l-0.209,0.309l-0.178,0.329l-0.149,0.344l-0.124,0.354l-0.14,0.502l-0.105,0.509l-0.079,0.515l-0.059,0.517l-0.037,0.418
        l-0.032,0.419l-0.029,0.419l-0.028,0.419l-0.029,0.407l-0.027,0.408l-0.022,0.407l-0.014,0.408l-0.003,0.263v0.264l0.004,0.263
        l0.005,0.263v0.756 M1005.957,419.257l-2.577-0.056l-2.577-0.054l-2.578-0.05l-2.579-0.044l-2.579-0.036l-2.581-0.027l-2.583-0.017
        l-0.645-0.001l-0.646,0.001l-0.645,0.006l-0.645,0.012l-0.644,0.021l-0.643,0.031l-0.642,0.044l-0.676,0.062l-0.674,0.074
        l-0.674,0.086l-0.673,0.096l-0.672,0.103l-0.672,0.106l-0.672,0.109"
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        996.027,429.266 996.024,429.51 996.02,429.753 996.015,429.997 996.008,430.241 996,430.484 995.991,430.663 995.98,430.841 
        995.968,431.02 995.955,431.197 995.939,431.375 995.927,431.5 995.915,431.625 995.902,431.75 995.887,431.874 995.873,431.999 
        995.857,432.12 995.841,432.241 995.824,432.361 995.806,432.482 995.787,432.603 995.757,432.771 995.726,432.939 995.692,433.106 
        995.654,433.273 995.613,433.439 995.577,433.57 995.539,433.701 995.498,433.831 995.454,433.96 995.407,434.087 995.37,434.181 
        995.331,434.272 995.29,434.364 995.248,434.455 995.202,434.545 995.156,434.632 995.107,434.717 995.056,434.801 995.002,434.884 
        994.947,434.965 994.908,435.02 994.867,435.072 994.826,435.125 994.783,435.177 994.739,435.228 994.68,435.291 994.62,435.353 
        994.558,435.412 994.494,435.471 994.428,435.526 "
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        994.428,435.526 994.349,435.59 994.267,435.65 994.183,435.709 994.097,435.765 994.01,435.817 993.919,435.87 993.827,435.92 
        993.732,435.968 993.637,436.013 993.542,436.056 993.407,436.111 993.27,436.163 993.132,436.212 992.994,436.257 992.854,436.299 
        992.666,436.351 992.476,436.397 992.286,436.44 992.094,436.479 991.902,436.515 991.698,436.55 991.494,436.58 991.289,436.608 
        991.083,436.634 990.877,436.657 990.691,436.677 990.504,436.695 990.318,436.711 990.13,436.727 989.944,436.74 989.757,436.753 
        989.572,436.765 989.385,436.774 989.199,436.784 989.012,436.792 988.788,436.801 988.563,436.809 988.338,436.814 
        988.114,436.819 987.888,436.823 987.66,436.826 987.431,436.828 987.203,436.83 986.973,436.83 986.745,436.83 986.363,436.829 
        985.98,436.825 985.598,436.82 985.216,436.812 984.834,436.8 "
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        984.834,436.8 984.46,436.785 984.085,436.766 983.711,436.742 983.338,436.713 982.964,436.679 982.799,436.662 982.634,436.644 
        982.47,436.624 982.305,436.603 982.14,436.58 981.905,436.544 981.669,436.503 981.435,436.458 981.202,436.407 980.969,436.35 
        980.843,436.315 980.717,436.279 980.593,436.24 980.469,436.199 980.345,436.155 980.255,436.121 980.167,436.085 980.078,436.047 
        979.991,436.008 979.904,435.967 979.84,435.935 979.777,435.901 979.714,435.868 979.653,435.833 979.591,435.796 979.517,435.75 
        979.445,435.702 979.373,435.652 979.302,435.601 979.234,435.546 979.089,435.42 978.954,435.284 978.827,435.141 978.708,434.988 
        978.6,434.83 978.513,434.688 978.434,434.542 978.361,434.393 978.293,434.241 978.231,434.087 978.184,433.96 978.14,433.831 
        978.099,433.701 978.061,433.57 978.025,433.439 "
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M996.027,429.266l-0.002-0.279l-0.003-0.279l-0.005-0.278l-0.008-0.279l-0.01-0.278l-0.009-0.179l-0.011-0.178l-0.012-0.178
        l-0.014-0.179l-0.016-0.178l-0.012-0.125l-0.013-0.125l-0.013-0.125l-0.015-0.124l-0.015-0.125l-0.016-0.121l-0.016-0.121
        l-0.018-0.12l-0.018-0.121l-0.02-0.12l-0.029-0.168l-0.031-0.168l-0.034-0.168l-0.038-0.167l-0.041-0.165l-0.036-0.132l-0.038-0.131
        l-0.041-0.13l-0.044-0.128l-0.047-0.128 M978.025,433.439l-0.017-0.066l-0.017-0.066l-0.016-0.067l-0.016-0.066l-0.015-0.067
        l-0.059-0.295l-0.052-0.296l-0.043-0.299l-0.038-0.298l-0.032-0.3l-0.013-0.127l-0.012-0.127l-0.011-0.127l-0.011-0.127
        l-0.009-0.127l-0.02-0.344l-0.015-0.343l-0.01-0.344l-0.008-0.344l-0.005-0.344"
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        995.407,424.269 995.37,424.175 995.331,424.083 995.29,423.991 995.248,423.9 995.202,423.811 995.156,423.725 995.107,423.639 
        995.056,423.555 995.002,423.472 994.947,423.391 994.908,423.337 994.867,423.283 994.826,423.23 994.783,423.179 994.739,423.128 
        994.68,423.064 994.62,423.003 994.558,422.943 994.494,422.886 994.428,422.829 994.349,422.766 994.267,422.705 994.183,422.646 
        994.097,422.591 994.01,422.538 993.919,422.485 993.827,422.436 993.732,422.389 993.637,422.343 993.542,422.301 993.407,422.244 
        993.27,422.192 993.132,422.145 992.994,422.099 992.854,422.057 992.666,422.005 992.476,421.958 992.286,421.915 992.094,421.876 
        991.902,421.841 991.698,421.807 991.494,421.775 991.289,421.747 991.083,421.722 990.877,421.698 990.691,421.679 
        990.504,421.661 990.318,421.645 990.13,421.629 989.944,421.615 "
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        989.944,421.615 989.757,421.603 989.572,421.592 989.385,421.581 989.199,421.572 989.012,421.563 988.788,421.555 
        988.563,421.548 988.338,421.541 988.114,421.536 987.888,421.532 987.66,421.529 987.431,421.527 987.203,421.526 986.973,421.525 
        986.745,421.525 986.363,421.526 985.98,421.53 985.598,421.535 985.216,421.544 984.834,421.556 984.46,421.57 984.085,421.59 
        983.711,421.613 983.338,421.643 982.964,421.677 982.799,421.693 982.634,421.712 982.47,421.731 982.305,421.753 982.14,421.775 
        981.905,421.812 981.669,421.853 981.435,421.897 981.202,421.948 980.969,422.006 980.843,422.04 980.717,422.076 980.593,422.115 
        980.469,422.156 980.345,422.2 980.255,422.235 980.167,422.271 980.078,422.309 979.991,422.348 979.904,422.389 979.84,422.421 
        979.777,422.454 979.714,422.488 979.653,422.523 979.591,422.56 "
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M995.52,429.266l-0.003,0.409l-0.006,0.328l-0.009,0.286l-0.014,0.282l-0.015,0.252l-0.02,0.268l-0.023,0.275l-0.026,0.275
        l-0.026,0.236l-0.029,0.232 M979.591,422.56l-0.074,0.046l-0.072,0.048l-0.072,0.05l-0.07,0.052l-0.068,0.055l-0.145,0.126
        l-0.136,0.136l-0.127,0.145l-0.118,0.151l-0.108,0.158l-0.087,0.143l-0.079,0.146l-0.073,0.149l-0.067,0.151l-0.063,0.154
        l-0.047,0.128l-0.044,0.129l-0.041,0.129l-0.038,0.131l-0.036,0.132l-0.017,0.066l-0.017,0.066l-0.016,0.066l-0.016,0.066
        l-0.015,0.067l-0.059,0.295l-0.052,0.297l-0.043,0.298l-0.038,0.299l-0.032,0.299l-0.013,0.127l-0.012,0.127l-0.011,0.127
        l-0.011,0.127l-0.009,0.128l-0.021,0.378l-0.016,0.378l-0.009,0.379l-0.007,0.379l-0.004,0.379"
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        995.349,432.11 995.317,432.341 995.28,432.57 995.25,432.738 995.217,432.905 995.182,433.073 995.142,433.239 995.108,433.37 
        995.072,433.501 995.032,433.63 994.99,433.758 994.952,433.863 994.912,433.967 994.869,434.069 994.824,434.171 994.783,434.256 
        994.74,434.339 994.695,434.421 994.648,434.501 994.604,434.571 994.558,434.641 994.51,434.709 994.459,434.774 994.414,434.832 
        994.366,434.888 994.317,434.941 994.265,434.994 994.212,435.045 994.159,435.094 994.103,435.142 994.045,435.188 993.98,435.236 
        993.912,435.284 993.843,435.329 993.773,435.373 993.689,435.421 993.604,435.468 993.518,435.512 993.43,435.553 993.326,435.6 
        993.22,435.643 993.114,435.684 993.006,435.722 992.872,435.767 992.736,435.809 992.599,435.847 992.461,435.883 992.289,435.924 
        992.116,435.961 991.941,435.995 991.767,436.027 "
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        991.767,436.027 991.536,436.064 991.304,436.098 991.072,436.128 990.839,436.154 990.529,436.187 990.217,436.214 
        989.906,436.237 989.593,436.258 989.207,436.278 988.821,436.295 988.434,436.308 988.047,436.316 987.514,436.324 
        986.981,436.327 986.449,436.325 985.916,436.32 985.507,436.314 985.1,436.306 984.693,436.291 984.287,436.271 983.967,436.251 
        983.647,436.228 983.327,436.202 983.006,436.177 982.756,436.155 982.507,436.131 982.26,436.098 982.015,436.051 981.885,436.019 
        981.756,435.983 981.627,435.946 981.498,435.91 981.356,435.871 981.213,435.833 981.071,435.794 980.93,435.752 980.817,435.714 
        980.706,435.674 980.594,435.63 980.485,435.583 980.395,435.542 980.306,435.499 980.218,435.453 980.131,435.405 980.058,435.362 
        979.986,435.317 979.916,435.271 979.846,435.221 979.79,435.177 979.734,435.132 "
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M995.52,429.27l-0.003-0.371l-0.006-0.37l-0.009-0.371l-0.015-0.37l-0.016-0.268l-0.02-0.269l-0.022-0.268l-0.026-0.267
        M979.734,435.132l-0.055-0.048l-0.053-0.049l-0.055-0.054l-0.053-0.055l-0.051-0.057l-0.049-0.059l-0.051-0.063l-0.048-0.064
        l-0.047-0.066l-0.044-0.067l-0.047-0.076l-0.045-0.078l-0.043-0.079l-0.04-0.08l-0.046-0.098l-0.043-0.099l-0.041-0.101
        l-0.038-0.101l-0.043-0.123l-0.04-0.123l-0.037-0.125l-0.035-0.125l-0.041-0.161l-0.037-0.161l-0.033-0.163l-0.031-0.163
        l-0.036-0.212l-0.032-0.212l-0.029-0.214l-0.026-0.213l-0.029-0.262l-0.024-0.262l-0.021-0.263l-0.018-0.262l-0.018-0.339
        l-0.012-0.339l-0.008-0.339l-0.005-0.339l-0.001-0.088l-0.001-0.089l-0.001-0.088l-0.001-0.089"
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        995.404,426.717 995.377,426.483 995.348,426.251 995.315,426.02 995.279,425.788 995.249,425.62 995.216,425.452 995.18,425.285 
        995.141,425.119 995.107,424.988 995.07,424.857 995.031,424.729 994.988,424.601 994.951,424.495 994.91,424.392 994.868,424.289 
        994.822,424.188 994.782,424.103 994.739,424.02 994.694,423.938 994.646,423.857 994.602,423.787 994.556,423.718 994.508,423.65 
        994.458,423.584 994.413,423.526 994.365,423.471 994.315,423.417 994.264,423.364 994.211,423.313 994.157,423.265 
        994.101,423.217 994.044,423.171 993.978,423.122 993.911,423.074 993.841,423.029 993.771,422.985 993.688,422.938 
        993.603,422.891 993.516,422.848 993.429,422.806 993.325,422.759 993.219,422.716 993.113,422.675 993.004,422.637 992.87,422.592 
        992.734,422.55 992.597,422.512 992.46,422.476 992.288,422.435 992.114,422.397 "
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        992.114,422.397 991.94,422.363 991.765,422.331 991.535,422.294 991.302,422.261 991.071,422.23 990.838,422.204 990.527,422.172 
        990.215,422.145 989.904,422.121 989.591,422.101 989.206,422.08 988.819,422.063 988.432,422.051 988.045,422.042 987.513,422.034 
        986.98,422.031 986.447,422.033 985.914,422.038 985.506,422.044 985.099,422.054 984.692,422.067 984.286,422.087 983.965,422.107 
        983.646,422.131 983.326,422.156 983.005,422.183 982.755,422.203 982.506,422.228 982.258,422.261 982.013,422.308 981.884,422.34 
        981.754,422.375 981.625,422.412 981.497,422.449 981.354,422.487 981.211,422.525 981.07,422.564 980.928,422.606 980.816,422.645 
        980.704,422.685 980.593,422.729 980.484,422.775 980.393,422.816 980.304,422.859 980.216,422.905 980.129,422.953 
        980.056,422.996 979.985,423.041 979.914,423.088 979.845,423.138 "
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M993.31,429.194l-0.001-0.138l-0.002-0.138 M978.325,429.266v0.002v0.001v0.002v0.001 M979.845,423.138l-0.058,0.044l-0.056,0.045
        l-0.055,0.048l-0.053,0.049l-0.055,0.054l-0.052,0.055l-0.051,0.057l-0.05,0.059l-0.05,0.063l-0.049,0.064l-0.046,0.066
        l-0.045,0.067l-0.047,0.077l-0.045,0.077l-0.042,0.079l-0.04,0.08l-0.046,0.098l-0.044,0.099l-0.04,0.101l-0.038,0.101l-0.044,0.123
        l-0.04,0.124l-0.037,0.124l-0.034,0.125l-0.041,0.161l-0.037,0.161l-0.034,0.163l-0.031,0.163l-0.036,0.212l-0.032,0.214
        l-0.029,0.213l-0.026,0.212l-0.028,0.259l-0.025,0.259l-0.021,0.262l-0.018,0.269l-0.017,0.356l-0.012,0.355l-0.008,0.339
        l-0.006,0.305v0.04l-0.001,0.044v0.05l-0.001,0.06"
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        993.307,428.919 993.304,428.782 993.3,428.645 993.295,428.533 993.291,428.423 993.283,428.312 993.275,428.201 993.267,428.108 
        993.258,428.017 993.249,427.925 993.237,427.833 993.221,427.719 993.204,427.604 993.182,427.491 993.158,427.379 
        993.142,427.315 993.125,427.252 993.106,427.189 993.085,427.127 993.062,427.063 993.035,427.001 993.003,426.941 
        992.967,426.885 992.943,426.853 992.916,426.821 992.886,426.793 992.856,426.766 992.827,426.743 992.796,426.721 
        992.764,426.701 992.732,426.683 992.685,426.658 992.637,426.637 992.587,426.618 992.538,426.603 992.478,426.587 
        992.418,426.575 992.357,426.566 992.296,426.563 992.275,426.562 992.252,426.562 992.231,426.562 992.208,426.562 
        991.738,426.566 991.266,426.576 990.795,426.599 990.327,426.64 990.149,426.661 989.972,426.687 989.797,426.717 989.623,426.753 
        "
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        989.623,426.753 989.517,426.778 989.412,426.807 989.308,426.838 989.205,426.872 989.117,426.904 989.029,426.94 988.943,426.979 
        988.858,427.021 988.762,427.076 988.669,427.136 988.581,427.2 988.495,427.27 988.425,427.332 988.359,427.398 988.296,427.467 
        988.236,427.539 988.195,427.595 988.154,427.65 988.116,427.708 988.08,427.768 988.035,427.847 987.993,427.928 987.955,428.011 
        987.919,428.095 987.895,428.157 987.873,428.22 987.853,428.283 987.833,428.348 987.811,428.432 987.792,428.517 987.775,428.602 
        987.76,428.688 987.749,428.77 987.739,428.852 987.732,428.934 987.726,429.017 987.724,429.06 987.723,429.103 987.722,429.146 
        987.722,429.188 987.725,429.309 987.732,429.428 987.744,429.548 987.759,429.667 987.779,429.774 987.801,429.882 
        987.828,429.988 987.859,430.093 987.896,430.2 987.937,430.305 "
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        987.937,430.305 987.983,430.407 988.035,430.508 988.08,430.588 988.128,430.666 988.18,430.742 988.236,430.816 988.331,430.927 
        988.433,431.03 988.543,431.126 988.66,431.213 988.746,431.269 988.833,431.319 988.923,431.366 989.015,431.409 989.137,431.459 
        989.261,431.503 989.386,431.542 989.513,431.576 989.656,431.61 989.8,431.64 989.945,431.665 990.089,431.687 990.239,431.706 
        990.388,431.723 990.538,431.737 990.688,431.749 990.82,431.759 990.953,431.767 991.084,431.772 991.217,431.777 991.375,431.781 
        991.533,431.784 991.69,431.787 991.848,431.792 991.967,431.796 992.086,431.8 992.205,431.799 992.324,431.792 992.392,431.783 
        992.46,431.771 992.529,431.755 992.596,431.734 992.674,431.704 992.75,431.665 992.821,431.619 992.884,431.565 992.927,431.519 
        992.966,431.469 993,431.415 993.032,431.359 "
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        993.032,431.359 993.076,431.261 993.111,431.158 993.14,431.055 993.165,430.949 993.193,430.803 993.218,430.656 993.239,430.51 
        993.255,430.362 993.266,430.256 993.276,430.148 993.284,430.041 993.29,429.934 993.298,429.747 993.304,429.56 993.307,429.373 
        993.31,429.186 "
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M983.28,402.249h34.016 M983.28,356.895l34.016,45.354 M983.28,356.895v45.354l34.016-45.354"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="1005.957"
            y1="454.405"
            x2="1017.295"
            y2="454.405"
          />
          <rect
            x="586.069"
            y="315.597"
            transform="matrix(0.7069 0.7074 -0.7074 0.7069 395.5377 -322.2502)"
            fill="#821EB4"
            width="1.002"
            height="1.002"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M892.854,486.276c1.175,0,2.126-0.951,2.126-2.126 M890.728,484.15c0,1.175,0.952,2.126,2.126,2.126 M892.854,482.024
        c-1.174,0-2.126,0.952-2.126,2.126 M894.98,484.15c0-1.174-0.951-2.126-2.126-2.126"
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        886.902,529.524 889.736,529.524 889.736,478.501 886.902,478.501 886.902,529.524 "
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="249.702"
            y1="361.146"
            x2="249.702"
            y2="74.848"
          />
          <polyline
            fill="none"
            stroke="#FF00FF"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        1017.295,532.076 884.35,532.076 884.35,465.887 "
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1.844,366.207l3.687-4.307 M0,368.36"
          />
          <rect
            x="5.926"
            y="360.896"
            transform="matrix(0.0769 0.997 -0.997 0.0769 365.7782 327.2096)"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M978.06,438.392l1.617-3.933 M963.437,473.966l-1.616,3.933"
          />
          <path
            fill="none"
            stroke="#966432"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1013.571,431.446c1.565,0,2.835-1.27,2.835-2.835 M1010.737,428.611c0,1.565,1.269,2.835,2.834,2.835 M1013.571,425.776
        c-1.565,0-2.834,1.27-2.834,2.835 M1016.406,428.611c0-1.565-1.27-2.835-2.835-2.835"
          />
          <rect
            x="796.228"
            y="452.666"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 553.5439 -430.5394)"
            fill="#008000"
            width="0.501"
            height="0.501"
          />
          <rect
            x="883.816"
            y="374.928"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1774.488 15.3404)"
            fill="#008000"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#966432"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M512.162,281.516c1.565,0,2.834-1.269,2.834-2.834 M509.327,278.682c0,1.565,1.27,2.834,2.835,2.834 M512.162,275.847
        c-1.565,0-2.835,1.27-2.835,2.835 M514.996,278.682c0-1.565-1.269-2.835-2.834-2.835"
          />
          <path
            fill="#AAAAAA"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M497.988,290.02l-8.22,3.401 M489.768,290.02l8.22,3.401 M497.988,293.421v-3.401 M497.988,290.02h-8.22 M497.988,293.421h-8.22
        M497.988,263.941l-8.22,3.402 M489.768,263.941l8.22,3.402 M497.988,267.343v-3.402 M497.988,263.941h-8.22 M497.988,267.343h-8.22
        "
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M242.757,219.584v137.027 M242.757,79.383V216.41 M244.344,79.383v277.229 M241.169,79.383v277.229 M241.169,361.146v-4.535h3.175
        v4.535H241.169 M241.169,216.41v3.175h3.175v-3.175H241.169 M241.169,74.848v4.535h3.175v-4.535H241.169"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M226.429,372.938H71.94 M226.429,374.526H71.94 M226.429,371.352H71.94 M67.405,371.352h4.535v3.175h-4.535V371.352
        M230.965,371.352h-4.536v3.175h4.536V371.352"
          />
          <rect
            x="314.581"
            y="540.613"
            transform="matrix(0.7007 0.7134 -0.7134 0.7007 480.0744 -62.748)"
            fill="#FF00FF"
            width="0.501"
            height="0.501"
          />
          <rect
            x="319.246"
            y="18.813"
            transform="matrix(0.7007 0.7134 -0.7134 0.7007 109.2113 -222.2282)"
            fill="#FF00FF"
            width="0.501"
            height="0.501"
          />
          <rect
            x="1004.016"
            y="382.723"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1985.1943 -56.3472)"
            fill="#00FF00"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.3685"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M884.067,454.549h18.467 M884.067,420.532h18.467 M884.067,454.549v-34.017h18.467 M884.067,409.194h18.467 M884.067,375.179h18.467
        M884.067,409.194v-34.016h18.467"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="1189.925"
            y1="177.745"
            x2="1189.925"
            y2="120.485"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1189.925,120.485L1189.925,120.485 M1247.185,177.745c0-31.624-25.636-57.26-57.26-57.26"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M790.807,528.958h50.173 M1298.776,0.354h-60.095"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M790.807,478.784L790.807,478.784 M840.98,528.958c0-27.71-22.463-50.174-50.173-50.174"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="790.807"
            y1="344.139"
            x2="840.98"
            y2="344.139"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M790.807,293.966L790.807,293.966 M840.98,344.139c0-27.71-22.463-50.173-50.173-50.173"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M782.586,540.315h-4.535v-3.402h4.535V540.315 M722.776,536.913h-4.536v3.402h4.536V536.913"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M778.051,481.638c-30.528,0-55.275,24.748-55.275,55.275"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1238.681,2.906v0.85v3.175v9.582 M1189.925,192.769v1.134 M1247.185,193.903v-1.134 M1189.925,177.745v13.606 M1247.185,191.352
        v-13.606 M666.083,532.359v5.329v3.175v10.205 M702.933,551.068v-10.205v-3.175v-5.329 M613.924,532.359v5.329v3.175v10.205
        M650.775,551.068v-10.205v-3.175v-5.329 M797.894,532.359v5.329v3.175v10.205 M871.594,551.068v-10.205v-3.175v-5.329
        M718.24,532.359v4.819v3.401v10.488 M782.586,551.068V540.58v-3.401v-4.819 M298.712,540.863v5.329v3.175v10.205 M484.099,559.572
        v-10.205v-3.175v-5.329 M77.609,540.863v5.329v3.175v10.205 M208.003,559.572v-10.205v-3.175v-5.329 M497.988,540.863v5.329v3.175
        v10.205 M564.885,559.572v-10.205v-3.175v-5.329 M778.051,536.913v-55.275"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1383.359,549.935h-40.641v1.134h41.777l-0.003-1.134 M1368.295,534.911h-25.577v13.605h39.219l-0.035-13.605 M1384.493,549.935
        l-0.936-357.208 M1382.423,192.729l0.936,357.205 M790.807,478.784h-8.221 M782.586,528.958h8.221 M790.807,387.509h-8.221
        M782.586,437.683h8.221 M782.586,344.139h8.221 M790.807,293.966h-8.221 M742.902,265.642v-9.354 M685.642,256.288v9.354
        M965.421,356.895v-9.354 M914.115,347.54v9.354 M1298.776,1.488V0.354 M1238.681,0.354v1.134 M1298.776,16.512V6.931V3.756v-0.85"
          />
          <path
            fill="#AAAAAA"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M963.437,473.966h-14.173h-65.197v1.701h79.37v-0.066 M497.988,559.572h66.897 M77.609,559.572h130.394 M298.712,559.572h185.387
        M797.894,551.068h73.7 M613.924,551.068h36.851 M666.083,551.068h36.85 M517.264,277.873v1.616l-0.5,1.537l-0.95,1.308l-1.308,0.95
        l-1.537,0.499h-1.616l-1.537-0.499l-1.308-0.95l-0.95-1.308l-0.5-1.537v-1.616l0.5-1.537l0.95-1.308l1.308-0.95l1.537-0.499h1.616
        l1.537,0.499l1.308,0.95l0.95,1.308L517.264,277.873 M500.823,267.343v22.677H523.5v-22.677H500.823"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M642.679,318.366h1.417 M637.01,318.366h1.417 M631.34,318.366h1.417 M625.671,318.366h1.417 M620.001,318.366h1.417
        M614.333,318.366h1.417 M608.663,318.366h1.417 M602.994,318.366h1.417 M597.325,318.366h1.417 M591.655,318.366h1.417
        M581.893,316.099h9.354 M591.247,273.012h-9.354 M965.421,356.895h51.874v-9.354 M914.115,347.54h-30.048v8.221v1.134h30.048
        M1017.295,347.54h-51.874 M1005.957,402.249v52.156h11.339v-52.156H1005.957 M886.902,532.359v5.329v3.175v10.205h130.394
        M1017.295,551.068v-10.205v-3.175v-5.329 M963.437,475.601v-1.635"
          />
          <rect
            x="590.997"
            y="318.116"
            transform="matrix(-0.7066 0.7076 -0.7076 -0.7066 1234.3081 124.9669)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="647.69"
            y="318.116"
            transform="matrix(-0.7066 0.7076 -0.7076 -0.7066 1331.0612 84.8513)"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M654.018,318.366h1.417 M648.348,318.366h1.417"
          />
          <rect
            x="647.69"
            y="318.116"
            transform="matrix(-0.7066 0.7076 -0.7076 -0.7066 1331.0612 84.8513)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="655.627"
            y="318.116"
            transform="matrix(-0.7066 0.7076 -0.7076 -0.7066 1344.6067 79.2351)"
            width="0.501"
            height="0.501"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="662.964"
            y1="321.352"
            x2="662.964"
            y2="319.934"
          />
          <rect
            x="662.713"
            y="323.501"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 423.1048 -373.9615)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="662.713"
            y="316.415"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 418.094 -376.037)"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M659.687,316.665h1.417 M654.018,316.665h1.417 M648.348,316.665h1.417 M642.679,316.665h1.417 M637.01,316.665h1.417
        M631.34,316.665h1.417 M625.671,316.665h1.417 M620.001,316.665h1.417 M614.333,316.665h1.417 M608.663,316.665h1.417
        M602.994,316.665h1.417 M597.325,316.665h1.417 M591.655,316.665h1.417"
          />
          <rect
            x="590.997"
            y="316.415"
            transform="matrix(-0.7076 0.7066 -0.7066 -0.7076 1233.3722 122.9492)"
            width="0.5"
            height="0.501"
          />
          <rect
            x="662.713"
            y="316.415"
            transform="matrix(-0.7081 0.7061 -0.7061 -0.7081 1356.0027 72.7506)"
            width="0.501"
            height="0.5"
          />
          <rect
            x="590.997"
            y="318.116"
            transform="matrix(0.7066 0.7076 -0.7076 0.7066 398.7345 -324.9603)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="590.997"
            y="316.415"
            transform="matrix(0.7066 0.7076 -0.7076 0.7066 397.5311 -325.4593)"
            width="0.501"
            height="0.5"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M655.877,321.352v-1.418 M655.877,327.021v-1.417 M655.877,332.689v-1.417 M655.877,338.359v-1.417 M655.877,344.028v-1.417
        M655.877,349.698v-1.418 M655.877,355.367v-1.417 M655.877,361.036v-1.417 M655.877,366.706v-1.418 M655.877,372.375v-1.417
        M655.877,378.044v-1.417 M655.877,383.714v-1.418 M655.877,389.383v-1.417 M655.877,395.052v-1.417 M655.877,400.722v-1.418
        M655.877,406.391v-1.417 M655.877,412.06v-1.417 M655.877,417.729v-1.418 M655.877,423.398v-1.417 M655.877,429.067v-1.417
        M655.877,434.737v-1.418 M655.877,440.406v-1.417 M655.877,446.076v-1.418 M655.877,451.745v-1.417 M655.877,457.414v-1.417"
          />
          <rect
            x="655.626"
            y="457.558"
            transform="matrix(0.7066 0.7076 -0.7076 0.7066 516.3642 -329.7823)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="655.626"
            y="318.116"
            transform="matrix(0.7066 0.7076 -0.7076 0.7066 417.6956 -370.6921)"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M662.964,327.021v-1.417 M662.964,332.689v-1.417 M662.964,338.359v-1.417 M662.964,344.028v-1.417 M662.964,349.698v-1.418
        M662.964,355.367v-1.417 M662.964,361.036v-1.417 M662.964,366.706v-1.418 M662.964,372.375v-1.417 M662.964,378.044v-1.417
        M662.964,383.714v-1.418 M662.964,389.383v-1.417 M662.964,395.052v-1.417 M662.964,400.722v-1.418 M662.964,406.391v-1.417
        M662.964,412.06v-1.417 M662.964,417.729v-1.418 M662.964,423.398v-1.417 M662.964,429.067v-1.417 M662.964,434.737v-1.418
        M662.964,440.406v-1.417 M662.964,446.076v-1.418 M662.964,451.745v-1.417 M662.964,457.414v-1.417"
          />
          <rect
            x="662.713"
            y="457.558"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 517.8972 -334.6972)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="662.713"
            y="323.501"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 423.1048 -373.9615)"
            width="0.501"
            height="0.501"
          />
          <path
            fill="#AAAAAA"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M671.025,464.895h1.417 M665.356,464.895h1.417 M659.687,464.895h1.417 M497.988,264.508h83.905h9.354v-8.22h-93.259"
          />
          <rect
            x="655.627"
            y="464.644"
            transform="matrix(-0.7066 0.7076 -0.7076 -0.7066 1448.2892 329.3032)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="675.621"
            y="464.644"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1482.515 315.7112)"
            width="0.501"
            height="0.501"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="675.872"
            y1="463.084"
            x2="675.872"
            y2="461.666"
          />
          <rect
            x="675.621"
            y="464.644"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 526.6883 -341.7489)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="675.621"
            y="457.558"
            transform="matrix(0.7071 0.7071 -0.7071 0.7071 521.6777 -343.8243)"
            width="0.501"
            height="0.501"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M671.025,457.809h1.417 M665.356,457.809h1.417"
          />
          <rect
            x="662.713"
            y="457.558"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1455.4692 312.7421)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="675.621"
            y="457.558"
            transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1477.5045 303.6148)"
            width="0.501"
            height="0.501"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="655.877"
            y1="463.084"
            x2="655.877"
            y2="461.666"
          />
          <rect
            x="655.626"
            y="464.644"
            transform="matrix(0.7066 0.7076 -0.7076 0.7066 521.3782 -327.7035)"
            width="0.501"
            height="0.501"
          />
          <rect
            x="655.626"
            y="457.558"
            transform="matrix(0.7066 0.7076 -0.7076 0.7066 516.3642 -329.7823)"
            width="0.501"
            height="0.501"
          />
          <path
            fill="#AAAAAA"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1238.681,0.354h-202.677 M1383.052,1.488l-0.003-1.134h-84.273 M1298.776,1.488h83.143 M1036.004,1.488h202.677 M1238.681,2.906
        h-202.677v0.509v7.145v5.953 M1380.541,16.512l-0.035-13.606h-81.729 M1298.776,16.512h68.158 M1036.004,16.512h202.677
        M790.807,293.966v-7.796V19.063h-8.221 M790.807,347.54v-3.401 M790.807,387.509v-31.748 M790.807,441.084v-3.401 M790.807,478.784
        v-25.795 M782.586,528.958v3.401h8.221v-3.401 M782.586,437.683v41.102 M782.586,344.139v43.37 M782.586,19.063v237.224v9.354v8.504
        v12.024v7.796 M253.075,385.524h-8.22 M244.854,435.698h8.22 M997.169,19.063v-5.329V10.56V0.354 M861.107,0.354V10.56v3.175v5.329
        M742.902,19.063v-5.329V10.56V0.354 M576.225,0.354V10.56v3.175v5.329 M483.815,19.063v-5.329V10.56V0.354 M302.398,0.354V10.56
        v3.175v5.329"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1017.295,19.063v441.012 M1036.004,532.359V193.903v-1.134v-1.417v-13.606V19.063 M613.924,532.359h-22.677v8.504 M666.083,532.359
        h-15.308 M718.24,532.359h-15.308 M797.894,532.359h-7.087h-8.221 M886.902,532.359h-2.835h-8.221h-4.252 M1017.295,551.068h18.709
        v-18.709 M871.594,551.068h15.308 M782.586,551.068h15.308 M702.933,551.068h15.308 M650.775,551.068h15.308 M602.586,551.068
        h11.338 M6.176,540.863v18.709 M77.609,540.863H24.885 M298.712,540.863h-45.637h-1.701h-6.52h-36.852 M497.988,540.863h-8.22
        h-5.669 M591.247,540.863h-8.22h-1.134h-17.008 M564.885,559.572h26.362 M484.099,559.572h13.89 M208.003,559.572h90.709
        M6.176,559.572h71.433 M1247.185,191.352h120.218 M1189.925,177.745h-153.921v13.606h153.921 M1367.361,177.745h-120.176
        M1247.185,193.903h120.218 M1189.925,192.769h-153.921v1.134h153.921 M1367.4,192.769h-120.215 M24.885,540.863V379.855
        M6.176,379.855v161.008 M1036.004,0.354v1.134"
          />
          <path
            fill="#AAAAAA"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M253.075,385.524v-3.029 M253.075,540.863V435.698 M483.815,0.354H302.398 M742.902,0.354H576.225 M997.169,0.354H861.107
        M497.988,256.288h-8.22 M489.768,256.288v29.882v254.693h8.22V264.508 M1367.403,191.352h13.6l-0.036-13.606 M1383.557,192.727
        l-0.505-191.238 M1381.918,1.488l0.505,191.241 M1381.902,534.911l-0.896-342.178l-13.606,0.036 M1367.403,193.903l0.893,341.008
        M1380.966,177.745l-0.426-161.233 M1366.934,16.512l0.427,161.233 M884.067,355.761v-8.221 M790.807,355.761h85.039
        M884.067,347.54h-93.26 M875.846,441.084h-85.039 M790.807,452.989h85.039 M875.846,452.989v79.37h8.221v-56.692v-1.701V356.895
        v-1.134 M875.846,355.761v85.323 M591.247,559.572v52.725h11.339v-61.229 M581.893,316.099v224.765h9.354 M591.247,264.508h-9.354
        v8.504 M591.247,273.012v-7.37 M591.247,540.863v-8.504V318.366v-1.701v-0.566 M685.642,256.288h-94.395v8.22 M742.902,265.642
        h39.685v-9.354h-39.685 M591.247,265.642h94.395 M1017.295,460.075v72.284"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M24.885,379.855h42.52 M67.405,361.146H29.138H6.176v18.709 M249.702,74.848V19.063 M230.965,361.146v18.709h13.89h4.847v-18.709
        M230.965,19.063v55.784 M67.405,361.146v10.205v3.175v5.329 M249.702,361.146v18.709h3.373l0.028-18.703L249.702,361.146
        M249.702,74.848h-5.357h-3.175h-10.205 M230.965,361.146h10.205h3.175h5.357 M249.702,19.063v55.784h3.401V19.063H249.702
        M1036.004,19.063v-2.551V2.906V1.488V0.354 M997.169,19.063h20.126 M742.902,19.063h39.685h8.221h70.3 M483.815,19.063h92.41
        M249.702,19.063h3.401h49.295 M302.398,0.354h-71.433V11.41v7.654 M576.225,0.354h-92.41 M861.107,0.354H742.902 M1036.004,0.354
        h-38.835 M244.854,435.698v105.165h8.22 M253.075,382.495v-2.64h-3.373h-4.847v5.669"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M1298.776,2.906V1.488 M1238.681,1.488v1.417 M1189.925,191.352v1.417 M1247.185,192.769v-1.417 M1382.423,192.729l-0.501-189.824
        M1380.505,2.906l0.035,13.606l0.426,161.233l0.036,13.606 M1383.355,548.517l-0.932-355.787 M1381.006,192.733l0.896,342.178
        l0.035,13.605 M1381.002,191.352h-0.036 M1247.185,192.769H1367.4l13.606-0.036 M1189.925,191.352h-153.921v1.417h153.921
        M1380.966,191.352h-13.563h-120.218 M1238.681,1.488h-202.677v1.417 M1381.922,2.906l-0.004-1.417h-83.143 M1298.776,2.906h81.729
        M1036.004,2.906h202.677 M1381.937,548.517h-39.219v1.418h40.641l-0.004-1.418 M230.965,361.146H67.405"
          />
          <rect
            x="823.707"
            y="458.007"
            fill="#FFFFFF"
            width="11.836"
            height="9.845"
          />
          <rect x="718.043" y="68.617" fill="#FFFFFF" width="10.031" height="9.483" />
          <rect x="760.563" y="68.617" fill="#FFFFFF" width="10.031" height="9.483" />
          <rect
            x="646.259"
            y="110.813"
            fill="#FFFFFF"
            width="9.483"
            height="16.226"
          />
          <rect
            x="762.159"
            y="497.953"
            fill="#FFFFFF"
            width="9.845"
            height="11.836"
          />
          <rect x="774.271" y="495.751" fill="#FFFFFF" width="9.483" height="16.24" />
          <rect x="762.159" y="128.66" fill="#FFFFFF" width="9.845" height="18.03" />
          <rect
            x="1210.947"
            y="152.693"
            fill="#FFFFFF"
            width="15.217"
            height="9.483"
          />
          <rect
            x="757.728"
            y="234.323"
            fill="#FFFFFF"
            width="10.032"
            height="9.483"
          />
          <rect x="843.446" y="325.701" fill="#FFFFFF" width="18.03" height="9.603" />
          <rect x="891.536" y="475.935" fill="#FFFFFF" width="9.845" height="18.03" />
          <rect
            x="892.323"
            y="409.848"
            fill="#FFFFFF"
            width="9.483"
            height="10.031"
          />
          <rect
            x="988.914"
            y="472.674"
            fill="#FFFFFF"
            width="9.483"
            height="16.189"
          />
          <rect
            x="988.369"
            y="409.795"
            fill="#FFFFFF"
            width="9.604"
            height="11.837"
          />
          <rect
            x="988.914"
            y="374.557"
            fill="#FFFFFF"
            width="9.483"
            height="10.031"
          />
          <rect
            x="667.133"
            y="425.212"
            fill="#FFFFFF"
            width="62.362"
            height="23.857"
          />
          <rect
            x="861.169"
            y="509.015"
            fill="#FFFFFF"
            width="9.846"
            height="11.836"
          />
          <rect
            x="861.947"
            y="483.986"
            fill="#FFFFFF"
            width="9.493"
            height="10.032"
          />
          <path
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
        M486.417,263.941v-4.252 M486.417,293.421v4.252"
          />
          <polyline
            fill="none"
            stroke="#000000"
            stroke-width="0.7087"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
        486.417,293.421 486.417,290.02 486.417,267.343 486.417,263.941 "
          />
          <text
            id="Büro"
            cursor="pointer"
            transform="matrix(1 0 0 1 38.5908 406.4365)"
            font-family="Arial, Helvetica, sans-serif"
            font-size="24"
          >
            Büro
          </text>
          <text
            id="Wohnzimmer"
            cursor="pointer"
            transform="matrix(1 0 0 1 260.8281 229.1904)"
            font-family="Arial, Helvetica, sans-serif"
            font-size="24"
          >
            Wohnzimmer
          </text>
          <text
            id="Küche"
            cursor="pointer"
            transform="matrix(1 0 0 1 500.3291 49.7271)"
            font-family="Arial, Helvetica, sans-serif"
            font-size="24"
          >
            Küche
          </text>
          <text
            id="Garderobe"
            cursor="pointer"
            transform="matrix(1 0 0 1 499.8228 292.7285)"
            font-family="Arial, Helvetica, sans-serif"
            font-size="24"
          >
            Garderobe
          </text>
          <text
            id="Schlafzimmer"
            cursor="pointer"
            transform="matrix(1 0 0 1 800.9194 47.8154)"
            font-family="Arial, Helvetica, sans-serif"
            font-size="24"
          >
            Schlafzimmer
          </text>
          <text
            id="Elternbad"
            cursor="pointer"
            transform="matrix(1 0 0 1 892.894 385.8145)"
            font-family="Arial, Helvetica, sans-serif"
            font-size="24"
          >
            Bad
          </text>
          <text
            id="Gäste_WC"
            cursor="pointer"
            transform="matrix(1 0 0 1 790.4038 482.9111)"
            font-family="Arial, Helvetica, sans-serif"
            font-size="24"
          >
            WC
          </text>
          <text
            id="Diele"
            cursor="pointer"
            transform="matrix(1 0 0 1 592.3936 400.457)"
            font-family="Arial, Helvetica, sans-serif"
            font-size="24"
          >
            Diele
          </text>
        </svg>
        <div v-for="room in roomModel" :key="room.id" style="position: absolute" :style="getCoordinates(room.name)">
          <svg  version="1.1" 
            :width="infoCardSize.width" 
            :height="infoCardSize.height" 
            viewBox="0 0 201 102"
            overflow="visible" 
            enable-background="new 0 0 201 102" 
            xml:space="preserve">
            <!-- xmlns="&ns_svg;" 
            xmlns:xlink="&ns_xlink;"  -->
            <g>
                <image opacity="0.51" width="201" height="102" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMkAAABmCAYAAABhjLU+AAAACXBIWXMAAAsSAAALEgHS3X78AAAA
            BGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAI3SURB
            VHja7NhNaxNRGIbhTJv0w01FaUHajUuX6m/wp7sR11oE3fhVkYqKYtPGxGfKOxKnVdyf64KHAw3d
            DNycTLrJVd3ohJasRueVEDayWbZdp1BoLZCLbF7nchzJZrab7WeH2V42FQoNBbLIPmdvs4/Zjz6U
            6doN0gdylN3PHlYoO54dDTnL3mRPsqfZu+x8uClmdYP0gTzKHmQH9XdoxXmF0XufnQ6RTOod5LBu
            kD6Qu9mNumGgFT/rPKrXjcv38vWbZK9COahApp4ZDdqajH64Gm6KIZThQzcILfvjx6qNf30IuDFA
            JCASEAmIBEQCIgGRgEgAkYBIQCQgEhAJiAREAiIBRAIiAZGASEAkIBIQCYgEEAmIBEQCIgGRgEhA
            JCASEAkgEhAJiAREAiIBkYBIQCSASEAkIBIQCYgERAIiAZEAIgGRgEhAJCASEAmIBEQCIgFEAiIB
            kYBIQCQgEhAJiAQQCYgERAIiAZGASEAkIBJAJCASEAmIBEQCIgGRgEhAJMD/R7LySOD6SPo4LrLz
            OsUCa5EMgXzNPtQpFFq0Gu3StM55dpIdZ/ey29lmDVoJpP8m9S37XhfFcoik/3CRfcpeZM+yW9md
            bMuzoxF9FF+yl9XBaXWxGm6SZRX0Kntc/3CU7Xh2NGJerxv9JfE8e52d9R90o/eT3Ww/O8xu1k3T
            eX408FVrUe/jJ3WL/P7K1V3zIj/LtusUCC2FclE3yqK+Xa0mf4mgG53QUijr56VfAgwA9npbs6AM
            6+AAAAAASUVORK5CYII=">
              </image>
              <g>
                <path fill="#FFFFFF" d="M196.857,94.286c0,1.657-1.344,3-3,3h-188c-1.657,0-3-1.343-3-3v-89c0-1.657,1.343-3,3-3h188
                  c1.656,0,3,1.343,3,3V94.286z"/>
              </g>
            </g>
            <text transform="matrix(1 0 0 1 9 22.1431)" font-family="Arial" font-weight="bold" font-size="18">{{room.name}}</text>
            <line fill="none" stroke="#000000" stroke-width="0.5" x1="7.715" y1="26.429" x2="191.857" y2="26.429"/>
            <text transform="matrix(1 0 0 1 11 49.6909)" font-family="Helvetica" font-size="16">Temperatur</text>
            <text transform="matrix(1 0 0 1 137.5723 50.521)" fill="#006B33" font-family="Arial" font-weight="bold" font-size="16">{{(room.temperature / 100).toFixed(1)}} °C</text>
            <text transform="matrix(1 0 0 1 109.1016 79.2637)" font-family="Arial" font-size="16">→</text>
            <path @click="changeSetTemp(room)" fill="white" stroke="#8C8C8C" stroke-width="0.5" d="M194.134,83.471
              c0,1.484-1.171,2.688-2.612,2.688h-59.328c-1.441,0-2.612-1.203-2.612-2.688V64.654c0-1.484,1.171-2.688,2.612-2.688h59.328
              c1.441,0,2.612,1.203,2.612,2.688V83.471z"/>
            <text @click="changeSetTemp(room)" style="cursor: pointer" transform="matrix(1 0 0 1 137.2871 79.6914)" font-family="Arial" font-size="16">{{(room.setTemperatureDay / 100).toFixed(1)}} °C</text>
            <text style="cursor: default" @click="openRoomDetails(room)" transform="matrix(1 0 0 1 109.1836 50.5205)" :fill="room.enableTempControl==0 ? '#AAAAAA' : (room.heatingActive>0 ? '#00FF00' : 'black')" font-family="Arial-Bold" font-size="16">▲</text>
            </svg>
        </div>
      <!-- </div> -->
    </pinch-zoom>
  </div>
</template>

<script>

import Vue from 'vue'
import PinchZoom from 'vue-pinch-zoom';

export default Vue.extend({
	name: "EG",
  components: {
    PinchZoom
  },
	props:{
    rooms: []
	},
	data() {
		return{
      baseSVGPosition: {},
      sizeRatio: 1,
      roomModel: [],
      roomCoordinates: {}
		}
	},
  mounted() {
    this.baseSVGPosition = document.getElementById("EG").getBoundingClientRect();
    this.sizeRatio = this.baseSVGPosition.width / 1384.85;
  },
  methods:{
    getCoordinates(roomName){
      if (this.roomCoordinates[roomName] == undefined){
        var room = document.getElementById(roomName.replace(" ", "_"));
        if (room){
          var rect = room.getBoundingClientRect();
          var x = rect.left - document.getElementById("pinch").getBoundingClientRect().left - 10 * this.sizeRatio;
          var y = rect.top - this.baseSVGPosition.top ;
          this.roomCoordinates[roomName] = "top: " + y + "px; left: " + x + "px";
        }
        else{
          return "top: 0; left: 0";
        }
      }
      return this.roomCoordinates[roomName];
    },
    changeSetTemp(room){
      this.$emit('editSetTemp', room);
    },
    openRoomDetails(room){
      this.$emit('editRoomDetails', room);
    }
  },
  computed:{
    infoCardSize(){
      return {
        width: 200 * this.sizeRatio,
        height: 100 * this.sizeRatio
      }
    }
  },
  watch: {
    rooms() {
      this.roomModel = this.rooms;
    }
  }
})
</script>