<template>
  <div class="transparent">
    <v-container class="transparent" >
      <v-card min-height="350" class="pa-0 ma-0">
        <v-card-title>
          <v-row>
            <v-col cols="auto">
              Raumsteuerung
            </v-col>
            <v-col cols="auto">
              <v-select :items="['EG', 'UG']" v-model="selectedLevel">
                
              </v-select>
            </v-col>
          </v-row>
        </v-card-title>

        <v-row justify="center" v-if="selectedLevel=='EG'">
          <v-col  cols="10" md="12" align-self="center">
            <v-card class="pa-3">
              <EG :rooms="roomLevels[0]" @editSetTemp="changeSetTemp($event)" @editRoomDetails="changeRoomDetails($event)"></EG>
            </v-card>
          </v-col>
        </v-row>

        <v-row justify="center" v-if="selectedLevel=='UG'">
          <v-col cols="10" md="12" align-self="center">
            <v-card class="pa-3">
              <UG :rooms="roomLevels[1]" @editSetTemp="changeSetTemp($event)" @editRoomDetails="changeRoomDetails($event)"></UG>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>   

    
    <v-dialog
      v-model="openSetTempDialog"
      width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{roomToChange.name}}
        </v-card-title>
        <v-card-text>Solltemperatur:</v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="">
              <v-text-field
                v-model="newRoomValues.setTemperatureDay"
                reverse
                prefix="°C"
                type="number"
                :rules="[validateSetTemp]"
                @keypress="checkEnter($event, 'temp')"
                >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="">
              <p class="text-h6">Temperaturverlauf:</p>
            </v-col>
            <v-col cols="12" class="">
              <line-chart 
                v-show="openSetTempDialog"
                :data="values" 
                :aspectratio="0.4" 
              >
              </line-chart>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="okSetTempDialog"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="openDetailsDialog"
      max-width="370"
      :scrollable="false"
    >
      <v-card class="pa-3">
        <v-card-title class="text-h4">
          {{roomToChange.name}}
        </v-card-title>
        <v-divider class="mx-2"/>
        <v-card-text>
          <v-row class="mt-0" align-content="center" align="center">
            <v-col cols="8" class="pa-0" >
              Heizkreis aktivieren
            </v-col>
            <v-spacer/>
            <v-col cols="2" class="py-0 ma-0">
              <v-switch
                v-model="newRoomValues.enableTempControl"
                inset
                @keypress="checkEnter($event, 'details')"
                >
              </v-switch>
            </v-col>
          </v-row>

          <v-row class="mt-1" justify="end">
            <v-col cols="12" class="pa-0">
              <v-text-field
                v-model="newRoomValues.setTemperatureDay"
                label="Solltemperatur"
                autofocus
                reverse
                prefix="°C"
                type="number"
                :rules="[validateSetTemp]"
                @keypress="checkEnter($event, 'details')"
                >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row justify="end" class="mt-0">
            <v-col cols="12" class="pa-0">
              <v-text-field
                v-model="newRoomValues.setTemperatureNight"
                label="Nachtabsenkung"
                reverse
                prefix="°C"
                type="number"
                :rules="[validateSetTemp]"
                @keypress="checkEnter($event, 'details')"
                >
              </v-text-field>
            </v-col>
          </v-row>
          
          <v-row class="my-n3">
            <v-col cols="12">
              <TimePicker v-model="newRoomValues.setNightTempAt" :showIcon="true" hint="Nachtabsenkung Start" ></TimePicker>
            </v-col>
          </v-row>
          <v-row class="my-n3">
            <v-col cols="12">
              <TimePicker v-model="newRoomValues.setDayTempAt" :showIcon="true" hint="Nachtabsenkung Ende" ></TimePicker>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mx-2"/>
        <v-row>
          <v-col></v-col>
          <v-col cols="auto" class="ma-4">
            <v-btn
              color="green darken-1"
              text
              @click="okSetDetailsDialog"
            >
              OK
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog> 
  </div>
</template>

<script>
// @ is an alias to /src

import DBTool from '../dbtool/DBTool';
import LineChart from '../components/SasLineChart.vue';
import UG from '../components/UG.vue';
import EG from '../components/EG.vue'; 
import TimePicker from '../components/SasTimePicker.vue';
import funcs from '../components/functions.js';

export default {
  name: 'Home',
  components: {
    LineChart, UG, EG, TimePicker
  },
  props: {
    phpUrl: String,
  },
  data: () => ({
    dbTool: {},
    values: [],
    roomLevels: [],
    openSetTempDialog: false,
    openDetailsDialog: false,
    roomToChange: {},
    newRoomValues: {},
    compareRoomValues: {},
    selectedLevel: "EG"
    
  }),
  mounted: function () {
    var dbURL = this.phpUrl; 
    if (this.phpUrl == undefined){
      dbURL = 'http://'+window.location.hostname + ":" + window.location.port +'/php-scripts/Database.php';
    }
    this.dbTool = new DBTool.DBTool();
    this.dbTool.setUrl(dbURL);

    this.getRoomInfos();
  },
  methods:{
    getRoomInfos(){
      var that = this;
      this.dbTool.query("getRoomInfos", false, function(response){
        if (response.success == true){
          that.roomLevels = [];
          for(var i = 0; i < response.responseObject.length; i++){
            var room ={
              id: response.responseObject[i].ID,
              name: response.responseObject[i].roomName,
              group: response.responseObject[i].groupName,
              temperature: response.responseObject[i].actTemperature,
              humidity: response.responseObject[i].actHumidity,
              heatingActive: response.responseObject[i].actHeatingOutput,
              enableTempControl: response.responseObject[i].enableTempControl=="1",
              setTemperatureDay: response.responseObject[i].setTemperatureDay,
              setTemperatureNight: response.responseObject[i].setTemperatureNight,
              setDayTempAt: response.responseObject[i].setDayTempAt,
              setNightTempAt: response.responseObject[i].setNightTempAt,
            };
            var level = 2;
            switch(room.group){
              case "UG":
                level = 1;
                break;
              case "EG":
                level = 0;
                break;
            }
            if (that.roomLevels[level] == undefined){
              that.roomLevels[level] = [];
            }
            that.roomLevels[level].push(room);
          }
        }
        else{
          console.error(response.errorMessage)
        }
      });
    },
    getRoomTemp(roomName, callback){
      var that = this;
      var startTime = new Date(new Date().getTime() - (30*24*3600000));
      this.dbTool.query("selectRoomTempTrend", [funcs.getUtcDateSqlString(startTime)], function(response){
        if (response.success == true){
          var tempValues = [];
          that.values = [];
          for(var i = 0; i < response.responseObject.length; i++){
            if (response.responseObject[i].RoomName == roomName){
              // let ts = new Date(response.responseObject[i].timestamp);
              tempValues.push({
                date: response.responseObject[i].timestamp,
                value: response.responseObject[i].temperature/100,
                name: response.responseObject[i].RoomName,
                unit: "°C"
              });
            }
          }
          that.values.push(tempValues);
          if (callback){
            callback();
          }
        }
        else{
          console.error(response.errorMessage)
        }
      });
    },
    okSetTempDialog(){
      var newValue = Number(this.newRoomValues.setTemperatureDay.replace(',', '.'));
      if (this.validateSetTemp(newValue) === true){
        this.roomToChange.setTemperatureDay = newValue * 100;
        this.openSetTempDialog = false;
        this.dbTool.query('setRoomInfo', ['setTemperatureDay', this.roomToChange.setTemperatureDay, this.roomToChange.id]);
      }
    },
    okSetDetailsDialog(){
      this.newRoomValues.setTemperatureDay = Number(this.newRoomValues.setTemperatureDay.replace(',', '.')) * 100;
      this.newRoomValues.setTemperatureNight= Number(this.newRoomValues.setTemperatureNight.replace(',', '.')) * 100;
      if (this.validateSetTemp(this.newRoomValues.setTemperatureDay/100) === true && this.validateSetTemp(this.newRoomValues.setTemperatureNight/100) === true){
        // prüfen welche Werte sich geändert haben, und die dann updaten
        var home = this;
        Object.keys(this.newRoomValues).forEach(prop => {
          if (home.newRoomValues[prop] != home.compareRoomValues[prop]){
            home.roomToChange[prop] = home.newRoomValues[prop];
            if(prop == "enableTempControl")
            {
              home.dbTool.query('setRoomInfo', [prop, home.roomToChange[prop] ? 1 : 0, home.roomToChange.id]);
            }
            else{
              home.dbTool.query('setRoomInfo', [prop, home.roomToChange[prop], home.roomToChange.id]);
            }
          }
        });
        this.openDetailsDialog = false;
      }
    },
    changeSetTemp(room){
      this.roomToChange = room;
      var dayAtSplit = room.setDayTempAt.split(':');
      var nightAtSplit = room.setNightTempAt.split(':');
      this.newRoomValues = {
        enableTempControl: room.enableTempControl,
        setTemperatureDay: (room.setTemperatureDay / 100).toFixed(1),
        setTemperatureNight: (room.setTemperatureNight / 100).toFixed(1),
        setDayTempAt: dayAtSplit[0].padStart(2, "0") + ":" + dayAtSplit[1].padStart(2, "0"),
        setNightTempAt: nightAtSplit[0].padStart(2, "0") + ":" + nightAtSplit[1].padStart(2, "0")
      }
      // Temperaturwerte abfragen für Chart
      this.openSetTempDialog = true;
      this.getRoomTemp(room.name);
      
    },
    validateSetTemp(value){
      return (value < 10 || value > 25) ? 'Der Wert ist außerhalb des Wertebereichs [10..25]' : true;
    },
    checkEnter($event, key){
      if ($event.key == "Enter"){
        if (key == "temp") this.okSetTempDialog();
        if (key == "details") this.okSetDetailsDialog();
      }
    },
    changeRoomDetails(room){
      this.roomToChange = room;
      var dayAtSplit = room.setDayTempAt.split(':');
      var nightAtSplit = room.setNightTempAt.split(':');
      this.newRoomValues = {
        enableTempControl: room.enableTempControl,
        setTemperatureDay: (room.setTemperatureDay / 100).toFixed(1),
        setTemperatureNight: (room.setTemperatureNight / 100).toFixed(1),
        setDayTempAt: dayAtSplit[0].padStart(2, "0") + ":" + dayAtSplit[1].padStart(2, "0"),
        setNightTempAt: nightAtSplit[0].padStart(2, "0") + ":" + nightAtSplit[1].padStart(2, "0")
      }
      this.compareRoomValues = {
        enableTempControl: room.enableTempControl,
        setTemperatureDay: room.setTemperatureDay,
        setTemperatureNight: room.setTemperatureNight,
        setDayTempAt: dayAtSplit[0].padStart(2, "0") + ":" + dayAtSplit[1].padStart(2, "0"),
        setNightTempAt: nightAtSplit[0].padStart(2, "0") + ":" + nightAtSplit[1].padStart(2, "0")
      }
      this.openDetailsDialog = true;
    }
  },
  computed: {

  },
  watch:{
    selectedLevel() {
      this.getRoomInfos();
    }
  }
}
</script>

<style>
  
</style>
