<template>
  <div style="position: relative">
      <div >
        <!--h6 style="text:center;">{{title}}</h6-->
        <div id="metrics"></div>
        <div
        id="tooltip"
        style="
            display: none;
            position: absolute;
            border: 1px solid rgba(0, 0, 0, 0.1);
            background: white;
            box-shadow: 1px 1px 2px -0.3px rgba(0, 0, 0, 0.6);
            border-radius: 5px;
            padding: 5px;
        "
        ></div>
    </div>
    <div :style="resetZoomStyle">
        <v-row class="mt-5 ml-5">
            <v-col>
                <v-btn style="position: relative" v-show="isZoomed" icon outlined large @click="resetZoom" elevation="5" class="ma-0">
                    <v-icon style="position: absolute" class="">mdi-magnify-expand</v-icon>
                    <!-- <v-icon style="position: absolute" small class="ml-n2 mt-n2">mdi-close</v-icon> -->
                </v-btn>
            </v-col>
        </v-row>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
    name: "SasLineChart",
    props: {
        data: {
            type: Array,
            required: true,
            default: Array
        },
        title: {
            type: String,
            required: false,
            default: ""
        },
        aspectratio: {
            type: Number,
            required: false,
            default: 0.5
        },
        noDataText: {
            required: false,
            type: String,
            default: "No data"
        }
    },
    data () {
        return {
            msg: "Hi from the Chart Component",
            baseSize: {width: 0, height: 0},
            height: 0,
            width: 0,
            margin: {},
            xOffsetOrigin: 0,
            yOffsetOrigin: 0,
            chartData: [],
            tooltipEvents: [],
            svg: {},
            baseContainer: {},
            mouseMoveActive: false,
            initialized: false,
            drawChartTimeout: {},
            canvasOffsetX: 0,
            canvasOffsetY: 0,
            tooltip: {},
            tooltipLine : {},
            timeScale: {},
            valueline: [],
            linearScales: [],
            valueAxis: [],
            myColor: {},
            idleTimeout: 0,
            zoomFactor: false,
            rescaleZoomX: {},
            timeAxis: {},
            zoom: {},
            isZoomed: false,
        };
    },
    created () {
        //console.log("LineChart Created Hook");
        this.colourScale = d3
            .scaleOrdinal()
            .range(['#5EAFC6', '#FE9922', '#93C464', '#75739F']);
        this.myColor = d3.scaleQuantize().domain([0,3])
            .range(["orange", "purple", "green", "black"]);
    },
    mounted () {
        //console.log("LineChart Mounted Hook");
        this.handleResize();
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy () {
    },
    beforeUnmount () {
    },
    computed: {
        resetZoomStyle(){
            return "position: absolute; left: " + this.margin.left + "px;top: " + 0 + "px;z-index: 1";
        }
    },
    watch: {
        data: function(value){
            // um rückkoppung vorzubeugen: erstmal alle Elemente von Data kopieren
            var tempData = [];
            value.forEach(element => {
                tempData.push(element);
            });
            if (tempData.length > 1){
                /** da unterschiedliche Trends auch unterschiedliche Zeitachsen haben können die TimeLine aber immer dieselbe ist, 
                 * sollte die Zeitachse immer das älteste und neuste Datum aller Datensätze repräsentieren
                 * dazu also diese beiden Daten ermitteln
                */
                var timeRange = [];
                tempData.forEach(function(chart) {
                    // max und min werte des aktuellen charts ermitteln 
                    var currTimeRange = d3.extent(chart, d => d.date);
                    // die max/Min werte dann mit den bisherigen MaxMin werten vergleichen und entsprechend anpassen
                    timeRange = d3.extent(timeRange.concat(currTimeRange));
                });
                // nochmals alle charts durchgehen und für jeden Chart, der nicht das Max- oder Min-Datum enthält, dafür den jeweiligen Wert kopieren
                tempData.forEach(function(chart) {
                    // max und min Datum ermitteln 
                    var currTimeRange = d3.extent(chart, d => d.date);
                    // Min-Date kopieren (wenn erforderlich)
                    if (currTimeRange[0] > timeRange[0]){
                        var newMinVal = {};
                        var minId = d3.minIndex(chart, d => d.date);
                        var minObject = chart[minId];
                        Object.keys(minObject).forEach(prop => {
                            newMinVal[prop] = minObject[prop];
                        });
                        newMinVal.date = timeRange[0];
                        chart.unshift(newMinVal);
                    }

                    // Max-Date kopieren (wenn erforderlich)
                    if (currTimeRange[1] < timeRange[1]){
                        var newMaxVal = {};
                        var maxId = d3.maxIndex(chart, d => d.date);
                        var maxObject = chart[maxId];
                        Object.keys(maxObject).forEach(prop => {
                            newMaxVal[prop] = maxObject[prop];
                        });
                        newMaxVal.date = timeRange[1];
                        chart.push(newMaxVal);
                    }
                });
            }

            tempData.forEach(function(d) {
                // if (d.length < 2){
                //         d = [];
                // }
                d.forEach(function(d_) {
                    var utc = d3.utcParse("%Y-%m-%d %H:%M:%S")(d_.date);
                    if (utc != null){
                        d_.date = utc;
                    }
                });
            });
            this.chartData = tempData;
            this.drawChart();
        }
    },
    methods: {
        calculateMargin(){
            this.margin = {
                top: 10, 
                right: 10 + Math.floor(this.chartData.length/2) * 60, 
                bottom: 30, 
                left: 10 + Math.ceil(this.chartData.length/2) * 60
            }
        },
        drawChart () {
            var that = this;

            if(this.chartData.length < 1) {
                // this.drawChartTimeout = window.setTimeout(this.drawChart, 500);
                return;
            }

            // if(this.mouseMoveActive) {
            //     // this.drawChartTimeout = window.setTimeout(function(){that.drawChart();}, 500);
            //     this.mouseMoveActive = false;
            //     return;
            // }

            if(!this.initialized) {
                // da das Element beim ersten Aufruf teilweise noch nicht die endgültige Größe haben sollte (wenn man das LineChart z.B. in einem Popup plaziert, das mit einer Transition aufgerufen wird) 
                // wird hier eine Schleife aufgerufen die darauf wartet, dass sich die Größe nicht mehr ändert
                setTimeout(function(){
                    if (that.baseSize.width < d3.select("#metrics").node().getBoundingClientRect().width){
                        that.baseSize = d3.select("#metrics").node().getBoundingClientRect();
                        // erneut aufrufen um nochmal zu prüfen
                        that.drawChart();
                    }
                    else{
                        that.width = that.baseSize.width;
                        var newHeight = that.width * that.aspectratio;
                        that.height = newHeight > 150 ? newHeight : 150;
                        that.xOffsetOrigin = that.baseSize.left;
                        that.yOffsetOrigin = that.baseSize.top;
                        that.calculateMargin();
                        that.initialized = true;
                        that.drawChart();
                    }
                }, 20);
                return;
            }

            that.calculateMargin();
            //remove old svg
            var temp = d3.select("#metrics svg")
            if(temp) {
                temp.remove();
            }

            //create new svg
            this.svg = null;
            this.svg = d3.select("#metrics").append("svg")
                .attr("width", this.width)
                .attr("height", this.height)
                // .append("g")
                // .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

            //create time scale depending on chartData content
            var rangeX = this.width - this.margin.left - this.margin.right;
            var rangeY = this.height - this.margin.top - this.margin.bottom;
            var dataRange = d3.extent(this.chartData[0], d => d.date);
            
            this.timeScale = d3.scaleTime()
                        .domain(dataRange)
                        .range([0, rangeX]);

            // Add scales to axis
            this.timeAxis = d3.axisBottom()
                        .scale(this.timeScale)
                        //.ticks(Math.ceil(rangeX/80) + 1);
                        .ticks(Math.ceil(10));

            // Basis-Komponente für Zoom und Einfassung
            this.baseContainer = this.svg.append("g")
                .attr("class", "base")
                .attr("width", rangeX)
                .attr("height", this.height - this.margin.top)
                .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

            // Begrenzungsrahmen einbauen, damit die durch zoom vergrößerte Linie nicht außerhalb der x-Achse dargestellt wird
            this.baseContainer.append("g")
                .append("svg:clipPath")
                .attr("id", "clip")
                .append("svg:rect")
                .attr("id", "clip-rect")
                .attr("overflow", "hidden")
                .attr("width", rangeX)
                .attr("height", this.height - this.margin.top);
                
            // create x-axis and label
            this.baseContainer.append("g")
                .attr("class", "axis axis--x")
                .attr("stroke-width", 2.0)
                .attr("transform", "translate(" + 0 + "," + (this.height - this.margin.bottom) + ")")
                .call(this.timeAxis);


            this.valueAxis = [];
            this.linearScales = [];
            // create y-axis and lines according to number of datasets in chartData
            for(var [key] of Object.entries(this.chartData)) {
                // Prüfen ob auch daten für die aktuelle Kurve vorhanden sind
                if (this.chartData[key].length < 1){
                    // NoData text anzeigen
                    this.svg.append("text")
                        .attr("text-anchor", "middle")
                //     .attr("x", this.width*0.5)
                //     .attr("y", this.height - this.margin.bottom)
                        .attr("y", this.height - this.margin.bottom - Number(key)*30)
                        .attr("x", this.width*0.5)
                        .text(this.noDataText)
                        .attr("fill", function() {return that.myColor(key)});
                    continue;
                }
                this.linearScales[key] = d3.scaleLinear()
                            .domain(d3.extent(this.chartData[key], d => parseFloat(d.value)))
                            // .domain([minVal, maxVal])
                            .range([this.height - this.margin.bottom - this.margin.top, 0]);
                this.valueline[key] = d3.line()
                            .x(d => this.timeScale(d.date))
                            .y(d => this.linearScales[key](parseFloat(d.value)))
                            .curve(d3.curveLinear)
                

                // Die y-Achsen (mehrere möglich) einfügen und beschriften
                var tickCountY = Math.floor(this.height/50) + 1;
                var yAxisPosition = 0;
                var textElement = {};
                if(key % 2 == 0) {
                    this.valueAxis[key] = d3.axisLeft()
                            .scale(this.linearScales[key])
                            .tickFormat(function (d){
                                return d3.format(".1f")(d);
                            })
                            .ticks(5)
                    yAxisPosition = this.margin.left - Math.floor(key/2) * 50 -10;
                    textElement = this.svg.append("text")
                        .attr("transform", "rotate(-90)")
                        .attr("x", 0 - this.height * 0.5)
                        .attr("y", yAxisPosition - 30)
                        .attr("text-anchor", "middle")
                        .attr("dy", "-5px")
                        .text(this.chartData[key][0].name + (this.chartData[key][0].unit ? ' [' + this.chartData[key][0].unit + ']' : ''))
                        .attr("fill", function() {return that.myColor(key)})
                } 
                else {
                    this.valueAxis[key] = d3.axisRight()
                            .scale(this.linearScales[key])
                            .tickFormat(function (d){
                                return d3.format(".1f")(d);
                            })
                            .ticks(5)
                    yAxisPosition = this.width - this.margin.right + Math.floor(key/2) * 50 + 10;
                    textElement = this.svg.append("text")
                        .attr("transform", "rotate(-90)")
                        .attr("x", 0 - this.height * 0.5)
                        .attr("y", yAxisPosition + 45)
                        .attr("text-anchor", "middle")
                        .attr("dy", "-5px")
                        .text(this.chartData[key][0].name + (this.chartData[key][0].unit ? ' [' + this.chartData[key][0].unit + ']' : ''))
                        .attr("fill", function() {return that.myColor(key)})
                }
                // getTextElement size
                var textSize = textElement.node().getBoundingClientRect();
                // remove Text element to 
                //textElement.remove();
                // append A rect under the Element
                this.svg.append("rect")
                    .attr("transform", "rotate(-90)")
                    .attr("x", textSize.left)
                    .attr("y", textSize.top)
                    .attr("width", textSize.width)
                    .attr("height", textSize.height)
                    .attr("stroke", function() {return that.myColor(key)})
                    .attr("stroke-width", 2.0)
                    ;

                //this.svg.append(textElement);

                // y-achse für dieses Daten-Element hinzufügen
                this.svg.append("g")
                    .call(this.valueAxis[key])
                    .attr("stroke-width", 2.0)
                    .attr("transform", "translate(" + yAxisPosition + "," + this.margin.top + ")")
                    .attr("color", function() {return that.myColor(key)})

                // Daten-Pfad für diese Daten hinzufügen (die eigentliche Kurve)
                if(this.chartData[key][0].date != null)
                {
                    this.baseContainer.append("path")
                        .attr("fill", "none")
                        .attr("stroke", function() {return that.myColor(key)})
                        .attr("stroke-width", 1.5)
                        .attr("class", "dataPath")
                        .datum(this.chartData[key])
                        .attr("d", this.valueline[key])
                        .attr("clip-path", "url('#clip')")
                        // .attr("transform", "translate(" + 0 + "," + 0 + ")");
                }
            }

            // ein Rechteck überlagern für die pointer events
            var curveArea = this.baseContainer.append("rect")
                .attr("class", "baserect")
                .attr("width", rangeX)
                .attr("height", rangeY)
                .attr("transform", "translate(" + 0 + "," + 0 + ")");

            curveArea.style("pointer-events", "all")
                .attr("fill", "none")
                .attr("width", rangeX)
                .attr("height", rangeY)
                .attr("x", 0)
                .on('mousemove', this.drawTooltip)
                .on('mouseout', this.removeTooltip);

       
            this.zoom = d3.zoom()
                .scaleExtent([1, 24*7])
                .translateExtent([[0, 0], [rangeX, rangeY]])
                .extent([[0,0], [rangeX,rangeY]])
                .on("zoom", function (event) {
                    var t = event.transform;
                    // isZoomed (zurück)setzen
                    that.isZoomed = (t.k != 1);
                    // console.log(t);
                    that.rescaleZoomX = t.rescaleX(that.timeScale);
                    that.svg.select(".axis--x")
                        .call(that.timeAxis.scale(that.rescaleZoomX));
                    // alle verfügbaren kurven (es können ja mehrere angezeigt werden) selektieren und zoomen
                    var paths = that.svg.selectAll(".dataPath");
                    paths.each(function(d, key){
                        var vL = that.valueline[key];
                        vL.x(function(dx) { return that.rescaleZoomX(dx.date); });
                        vL.y(dy => that.linearScales[key](parseFloat(dy.value)));
                        d3.select(this)
                            .datum(that.chartData[key])
                            .attr("d", vL)
                            // .attr("clip-path", "url('#clip')")
                            ;
                    });
                });

            // console.log(zoom);
            this.baseContainer.call(this.zoom)
                .transition()
                .duration(1500)

            // .call(zoom.transform, d3.zoomIdentity
            //     .scale(that.width / (that.timeScale(d1) - that.timeScale(d0)))
            //     .translate(-that.timeScale(d0), 0));

            // add tooltip
            this.tooltip = d3.select('#tooltip').style("pointer-events", "none");
            this.tooltipLine = this.svg.append('line').style("pointer-events", "none");
            this.isZoomed = false;
            
        },
        removeTooltip() {
                if (this.tooltip) this.tooltip.style('display', 'none');
                if (this.tooltipLine) this.tooltipLine.attr('stroke', 'none');
        },
        drawTooltip(event) {
            var that = this;
            // this.mouseMoveActive = true;
            var xy = d3.pointer(event);
            var x0_ = this.timeScale.invert(xy[0]);
            // wenn gezoomt wurde, den geänderten x-Achsen Abschnitt berücksichtigen
            if (this.rescaleZoomX.invert){
                x0_ = this.rescaleZoomX.invert(xy[0]);
            }
            // var x0_ = this.timeScale.invert(xy[0]);
            
            var tooltipData = [];

            var bisectDate = d3.bisector(function(d) { return d.date; }).left;
            var dateFormatter = d3.timeFormat("%H:%M:%S");

            for(var x = 0; x < this.chartData.length; ++x) {
                var i = bisectDate(this.chartData[x], x0_, 1);
                if(this.chartData[x].length < 2) {
                    continue;
                } 
                var d0 = this.chartData[x][i - 1];
                var d1 = this.chartData[x][i];
                if (d1 == undefined) {
                    return;
                }
                var point = x0_ - d0.date > d1.date - x0_ ? d1 : d0;
                tooltipData.push({
                    name: point.name,
                    date: point.date,
                    value: Number.parseFloat(point.value).toFixed(1),
                    unit: point.unit ? "[" + point.unit + "]" : ""
                });
            }
               
            // var toolTipPositionX = this.timeScale(x0_) + this.margin.left;
            var toolTipPositionX = xy[0] + this.margin.left;
                
            this.tooltipLine.attr('stroke', 'black')
                .attr('x1', toolTipPositionX)
                .attr('x2', toolTipPositionX)
                .attr('y1', 0)
                .attr('y2', this.height + this.margin.top);
            
            // var xOffset = xy[0] + 10 + this.xOffsetOrigin;
            // var yOffset = xy[1] + 10 + this.yOffsetOrigin;
            var xOffset = toolTipPositionX + 20;
            // Wenn man an den rechten Rand fährt, darf man die Width nicht überschreiten
            if (xOffset + this.tooltip.node().getBoundingClientRect().width > this.width){
                xOffset = this.width - this.tooltip.node().getBoundingClientRect().width;
            }
            var yOffset = xy[1] + 20;

            this.tooltip.html(dateFormatter(x0_))
                .style('display', 'block')
                .style('left',xOffset + "px")
                .style('top', yOffset + "px")
                .selectAll()
                .data(tooltipData).enter()
                .append('div')
                .style('color', (d, i) => that.myColor(i))
                .style('font-size', '12px')
                .html(d => d.name + ' ' + d.unit + ': ' + d.value);
                //.html(tooltipString)
        },
        resetZoom(){
            this.baseContainer.transition().duration(750).call(
                this.zoom.transform,
                d3.zoomIdentity,
                d3.zoomTransform(this.svg.node()).invert([this.width - this.margin.left - this.margin.right, this.height - this.margin.top - this.margin.bottom]));
        },
        handleResize () {
            var metricsNode = d3.select("#metrics").node();
            if(metricsNode !== null) {
                if(metricsNode.getBoundingClientRect !== undefined) {
                    this.width = metricsNode.getBoundingClientRect().width;
                    var newHeight = this.aspectratio*this.width;
                    this.height = newHeight > 150 ? newHeight : 150;
                    this.canvasOffsetX = metricsNode.getBoundingClientRect().left;
                    this.canvasOffsetY = metricsNode.getBoundingClientRect().top;
                }
                this.drawChart();
            }
        }
    }
}
</script>

<style scoped>
.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 100%; /* aspect ratio */
  vertical-align: top;
  overflow: hidden;
}
.svg-content-responsive {
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0;
}
.shadow {
  -webkit-filter: drop-shadow( 1px 1px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow( 1px 1px 2px rgba(0, 0, 0, .7));
  /* Similar syntax to box-shadow */
}
</style>